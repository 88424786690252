<style>
tr:nth-child(even) {
  background-color: #fafaf6;
}

.individual-result tr th {
  border: none;
  border-right: 1px solid #fff !important;
}

.points-btn {
  background-color: #f1aa00;
  color: #121113;
  border-color: black;
  border: 1px solid;
}



.weight-tab ul {
  border-radius: 0px !important;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 15px;
  border: none !important;
}

.weight-tab ul li a.active-tab,
.weight-tab ul li a:hover {
  background-color: #121113 !important;
  border-color: #121113;
  color: #f1aa00 !important;
  border-bottom: 3px solid #f1aa00 !important;
}

.tab ul li a {
  display: block;
  font-size: 20px !important;
  padding: 0.59rem 0.5rem;
  border-right: 1px solid #fcb913;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0;
  font-weight: bold !important;
  padding: 13px 37px !important;
  color: #121113 !important;

  background-color: #d9d9da63 !important;
}

@media only screen and (max-width: 575px) {
  .reset-btn {
    width: 100%;
    margin: 4px auto;
  }

  .btn-group {
    width: 100% !important;
  }

  .page-link {
    padding: 0.5rem 0.5rem !important;
  }

  .tab ul li {
    display: block;
    float: none !important;
  }
}

.rankingCard .btn-css button {
  background-color: transparent !important;
  border-bottom: 1px solid transparent;
  border-color: rgba(75, 76, 107, 0.48);
  color: #4B4C6B;
  background-color: transparent;
}
</style>
<template>
  <page-heading> LIST OF EVENTS </page-heading>
  <div class="container btn-wrap">
    <div class="row">
      <div class="col-md-12">
        <div class="white-top-bg">
          <div class="row mb-25">
            <section class="col-md-12">
              <div class="tab tab-left weight-tab">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      :class="isResultPage && 'active-tab'"
                      @click="filterResult('event-result', 'result')"
                      href="#"
                    >
                      EVENT RESULTS
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      :class="!isResultPage && 'active-tab'"
                      @click="filterResult('event-result', 'upcoming')"
                      href="#"
                    >
                      UPCOMING RESULTS
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </div>

          
          <AdminNavigation />

          <div class="row justify-content-md-center">
            <div
              class="col-xs-6 col-sm-6 mb-10 tx-c col-md-4 col-lg-3"
              :class="filter.class"
              v-for="(filter, k) in filters"
              :key="k"
            >
              <div class="btn-group" role="group" aria-label="">
                <button
                  v-for="(data, i) in filter.data"
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="filterResult(data.type, data.id)"
                  :class="data.class"
                  :key="i"
                  v-show="(data.type === 'event_status' && isResultPage) ||
                    data.type !== 'event_status'
                    "
                >
                  {{ data.label }}
                </button>
              </div>
            </div>
            <!-- Search box -->
            <div class="col col-sm-4 mb-10 tx-c col-md-3 col-lg-2">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control col-md-9"
                  placeholder="Search Events..."
                  v-model="searchText"
                  @keyup.enter="searchEvents"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="searchEvents"
                    :disabled="searchText.length < 3"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- Reset filters button-->
            <div class="col col-sm-2 mb-10 tx-c col-md-3 col-lg-1">
              <button
                type="button"
                class="btn btn-outline-primary reset-btn"
                @click="filterResult('reset', 'all')"
              >
                RESET
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="table-wrap">
      <div class="table-responsive individual-result event-list text-center">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" v-if="loggedIn">Actions</th>
              <th scope="col">#</th>
              <th scope="col">DATE</th>
              <th scope="col">EVENT TITLE</th>
              <th scope="col" v-if="loggedIn">TYPE</th>
              <th scope="col">GENDER</th>
              <th scope="col">TIME (GST)</th>
              <th scope="col">RESULTS STATUS</th>
              <th scope="col">CATEGORY</th>
              <th scope="col">ROUTE</th>
              <th scope="col">DISTANCE (KM)</th>
              <th scope="col">ELEVATION (M)</th>
              <th scope="col">LAPS</th>
              <th scope="col">SEGMENTS</th>
              <th scope="col">PARTICIPANTS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="14">
                <page-loader />
              </td>
            </tr>
            <tr
              :class="(isResultPage || isLive) && 'eventRow'"
              v-for="(event, k) in eventList"
              :key="k"
              v-on:click="
                isResultPage ? goToResult(event, $event.target.value) : '#'
                "
            >
            <td scope="row" v-if="loggedIn">
                  <button
                    type="button"
                    class="points-btn btn-sm"
                    :value="'jerseysbtn'"
                    @click="goToJerseysPage(event)"
                  >Reward Jerseys</button>

                  <button v-if="event?.EventFormatType != EventFormatType.TIME_BASED"
                    type="button"
                    class="points-btn btn-sm"
                    :value="'pointsbtn'"
                    @click="goToPointsPage(event)"
                  >Points</button>
              </td>
              <td scope="row">{{ rowNumber(k) }}</td>
              <td scope="row">
                <span class="date">{{ getDate(event.datetime) }}</span>
              </td>
              <td scope="row">{{ event.name }}</td>
              <td scope="row" v-if="loggedIn">
                {{ event?.isSeriesEvent ? "Series Event" : "Normal" }}
              </td>
              <td scope="row">
                {{
                  event.gender === 0
                  ? "Male"
                  : event.gender === 1
                    ? "Female"
                    : "Both"
                }}
              </td>
              <td scope="row">{{ getTime(event.datetime) }}</td>
              <td
                scope="row"
                :style="event.result_publish_status === 'live' && 'color: red;'"
              >
                {{ event.result_publish_status }}
              </td>
              <td scope="row">
                {{ (event.event_category || "All").toUpperCase() }}
              </td>
              <td scope="row">{{ event.route_name }}</td>
              <td scope="row">{{ event.distance }}</td>
              <td scope="row">{{ event.elevation }}</td>
              <td scope="row">{{ event.laps || 0 }}</td>
              <td scope="row">{{ event.total_gates || 0 }}</td>
              <td scope="row">{{ event.totalParticipants || 0 }}</td>
            </tr>
          </tbody>
        </table>
        <p
          class="mt-3"
          v-if="eventList.length === 0"
          style="text-align: center"
        >
          {{ loading ? "Loading.... " : "Event data not found" }}
        </p>
      </div>
    </div>
  </div>

  <div v-if="eventList.length > 0" class="pagination justify-content-center">
    <pagination
      v-model="currentPage"
      :records="totalRecords"
      :per-page="limit"
      @paginate="getEvents"
    />
  </div>
</template>

<script>
import config from "@/config/app.config.ts";
import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import filters from "@/theme/local-db/filter.json";
import Pagination from "v-pagination-3";
import { mapGetters } from "vuex";
import { EventFormatType } from './enums.ts';

export default {
  name: "EventListResult",
  props: ["isResultPageProp"],
  components: { Pagination, AdminNavigation },
  data() {
    return {
      EventFormatType: EventFormatType,
      filterSearchVal: "",
      loading: false,
      eventList: [],
      filters: filters,
      searchText: "",
      totalPage: 1,
      currentPage: 1,
      limit: 10,
      totalRecords: 0,
      isLive: false,
      baseUrl: config.BASE_URL,
      isResultPage: this.isResultPageProp || true,
      queryFilter: {
        status: "all",
        gender: "all",
        category: "all",
        isFeatureEvents: false,
      },
    };
  },

  mounted() {
    this.$route?.params?.eventListPage
      ? (this.currentPage = this.$route?.params?.eventListPage)
      : this.currentPage;

    if (this.$route.query.result) {
      this.isResultPage = this.$route.query.result;
    }
    this.queryFilter.isFeatureEvents = false;

    this.loadEvents();
  },
  computed: {
    ...mapGetters("event", ["events"]),
    ...mapGetters("auth", ["loggedIn"]),
    isResetBtnVisible() {
      const checkQueryFilters = () => {
        return (
          this.queryFilter.status !== "all" ||
          this.queryFilter.gender !== "all" ||
          this.queryFilter.category !== "all" ||
          this.searchText !== ""
        );
      };
      return (this.isResultPage || this.isLive) && checkQueryFilters();
    },
  },

  methods: {
    rowNumber(k) {
      return k + 1 + (this.currentPage - 1) * this.limit;
    },

    searchEvents() {
      this.currentPage = 1;
      this.queryFilter = { ...this.queryFilter, search: this.searchText };
      this.loadEvents();
    },

    loadPageData(page) {
      this.currentPage = parseInt(page.toString());
      this.loadEvents();
    },

    getTotalPage(total) {
      this.totalRecords = total;

      let page = (
        total >= this.limit ? total / this.limit : total > 0 ? 1 : 0
      ).toString();
      let i = parseInt(page) % 1;
      this.totalPage = parseInt(page + "") + (i > 0 ? 1 : 0);
    },

    getDate(dateTime) {
      const dt = new Date(dateTime?.replace(" ", "T"));
      return dt.toDateString();
    },

    getTime(dateTime) {
      return dateTime.split(" ")[1];
    },

    async loadEvents() {
      this.loading = true;

      const res = await this.$store.dispatch("event/getEvents", {
        isLive: this.isLive,
        filters: { page: this.currentPage, ...this.queryFilter },
      });

      const { data, total } = res || { data: [], total: 0 }

      this.eventList = data || [];
      this.getTotalPage(total || 1);

      this.loading = false;
    },

    goToResult(event, clickedBtn) {
      if (clickedBtn == 'jerseysbtn') {
        this.goToJerseysPage(event)
      } else {
        clickedBtn == "pointsbtn"
          ? this.goToPointsPage(event)
          : this.goToEditResultPage(event);
      }

    },
    goToPointsPage(event) {
      return this.$router.push({
        name: "pointsMainPage",
        params: {
          eventId: event.serverEventId,
          //   eventListPage: this.currentPage,
        },
      });
    },

    goToJerseysPage(event) {
      return this.$router.push({
        name: "jerseysListPage",
        params: {
          eventId: event.serverEventId,
          eventName: event.name
        },

      });
    },


    goToEditResultPage(event) {
      return this.$router.push({
        name: "AdminResultEditPanelv2",
        params: {
          eventId: event.serverEventId,
          eventListPage: this.currentPage,
        },
      });
    },
    getEvents(currentPage = 1) {
      this.currentPage = currentPage;
      this.loadEvents();
    },
    searchFilterEvent() {
      if (this.filterSearchVal.length > 2) {
        this.filterResult("search", this.filterSearchVal);
      }
      if (this.filterSearchVal == "") {
        this.filterResult("search", "all");
      }
    },

    filterResult(filterType, key) {
      if (filterType === "event-result" && key === "upcoming") {
        this.queryFilter.isFeatureEvents = true;
      }
      if (filterType === "event-result" && key === "result") {
        this.queryFilter.isFeatureEvents = false;
      }

      this.isResultPage = !this.queryFilter.isFeatureEvents;

      this.filters.forEach((el) => {
        el.data.forEach((fl) => {
          if (filterType === "reset") {
            fl.class = fl.id === key ? "active-btn" : "";
          } else {
            if (fl.type === filterType && fl.id === key) {
              fl.class = "active-btn";
            } else if (fl.type === filterType && fl.id !== key) {
              fl.class = "";
            }
          }
        });
      });

      if (filterType === "event_type" && key !== "all") {
        this.queryFilter = { ...this.queryFilter, category: key };
      } else if (filterType === "event_type" && key === "all") {
        delete this.queryFilter.category;
      }

      if (filterType === "event_gender" && key !== "all") {
        this.queryFilter = {
          ...this.queryFilter,
          gender: parseInt(key.toString()),
        };
      } else if (filterType === "event_gender" && key === "all") {
        delete this.queryFilter.gender;
      }

      if (filterType === "event_status" && key !== "all") {
        this.currentPage = 1;
        this.queryFilter = { ...this.queryFilter, status: key };
      } else if (filterType === "event_status" && key === "all") {
        delete this.queryFilter.status;
      }

      if (filterType === "search" && key !== "all") {
        this.queryFilter = { ...this.queryFilter, search: key };
      } else if (filterType === "search" && key === "all") {
        delete this.queryFilter.search;
      }

      if (filterType === "reset") {
        this.queryFilter = {
          ...this.queryFilter,
          status: key,
          gender: key,
          category: key,
        };
        delete this.queryFilter.search;
        this.searchText = "";
      }
      this.loadEvents();
    },
  }
};
</script>
