// @ts-ignore
import admin from "@/views/admin/routes.ts";
// @ts-ignore
import { createRouter, createWebHistory } from "vue-router";
// @ts-ignore
import PageNotFound from '@/views/PageNotFound.vue';
// @ts-ignore
import EventResultIndex from '@/views/events/EventResultIndex.vue';
// @ts-ignore
import EventResultListPage from '@/views/events/EventList.vue'
// @ts-ignore
import FrontendLayout from '@/theme/layouts/FrontendLayout.vue';

//routes
const routes = [
    {
        path: '/',
        component: FrontendLayout,  //with another theme layout
        children: [
            {
                path: "",
                name: "EventResultListPage",
                component: EventResultListPage
            },
            {
                path: "complete-result",
                name: "EventResultIndex",
                component: EventResultIndex
            },
            {  
                //this route is used for db points persistancy feature
                path: "/pp/complete-result",
                name: "EventResultIndexV2",
                component: EventResultIndex
            },
        ]
    },
    ...admin,
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            requiresAuth: false
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
