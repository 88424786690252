import 'sweetalert2/src/sweetalert2.scss'
import Swal  from 'sweetalert2'

const sweetAlert = {

  alert: (message, icon='error', pg = true) =>  {
    Swal.fire({
      showCloseButton: true,
      html: message,
      icon,
      showConfirmButton: false,
      timer: pg ? 10000 : 0,
      timerProgressBar: pg,
      // onClose: () => {
      //   clearInterval(timerInterval)
      // }
    });
  },

  succesAlert: (message, icon='error', pg = true) =>  {
    Swal.fire({
      showCloseButton: true,
      html: message,
      icon,
      showConfirmButton: false,
      timer: pg ? 10000 : 0,
      timerProgressBar: pg,
      // onClose: () => {
      //   clearInterval(timerInterval)
      // }
    });
  },

  confirm: (title = null, text = null, confirmBtn = null, cancelBtn = null, icon = null, successCallback, cancelCallback) =>  {
    Swal.fire({
      title: title || 'Are you sure?',
      text: text || 'You will not be able to recover this imaginary file!',
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonText: confirmBtn || 'Yes, delete it!',
      cancelButtonText: cancelBtn || 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        successCallback();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cancelCallback();
      }
    })
  }
}

export default sweetAlert;
