/* eslint-disable no-empty */
// @ts-ignore
import Service from "@/services/http.ts";
import axios from "axios";
// @ts-ignore
import config from "@/config/app.config.ts";

export function getCookie(name) {

    // setCookie(name, 'ef42a6e2-326d-11ed-b9da-02ba1aab646d', 2)
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}
export function setCookie(name, value, daysToLive) {
    // Encode value in order to escape semicolons, commas, and whitespace
    let cookie = name + "=" + encodeURIComponent(value);

    if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires
        after the specified number of days */
        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);

        document.cookie = cookie;
    }
}



export default {
    namespaced: true,

    state: {
        players: [],
        playersRideData: [],
        playerFieldsData: {},
        loggedInPlayerData: null,
        comparePlayerData: null,
        overAllPlayersData: []
    },

    getters: {
        players: ({ players }) => players,
        playersRideData: ({ playersRideData }) => playersRideData,
        playerFieldsData: ({ playerFieldsData }) => playerFieldsData,
        loggedInPlayerData: ({ loggedInPlayerData }) => loggedInPlayerData,
        comparePlayerData: ({ comparePlayerData }) => comparePlayerData,
        overAllPlayersData: ({ overAllPlayersData }) => overAllPlayersData
    },

    actions: {
        async getLoggedInPlayerDetails({ commit }, payload) {
            try {
                const userId = getCookie('whoosh_uuid')
                return await Service(true)
                    .get("rider/profile/get-loggedin-user", { params: { userId } })
                    .then((res) => {
                        commit("SET_LOGGEDIN_PLAYER_DATA", res.data);
                    });
            } catch (e) {
                commit("SET_LOGGEDIN_PLAYER_DATA", null);

            }
        },
        async getComparePlayerDetails({ commit }, payload) {
            try {

                return await Service(true)
                    .get("rider/profile/get-compare-user", { params: { ...payload } })
                    .then((res) => {
                        commit("SET_COMPARE_PLAYER_DATA", res.data);
                    });
            } catch (e) {
                commit("SET_COMPARE_PLAYER_DATA", null);
            }
        },
        async getOverallPlayersData({ commit }, payload) {
            try {
                // return await axios
                //     .get(`${config.BASE_JAVA_API_URL}/data/progress/overall`, { params: payload })
                //     .then((res) => {
                //         if (res?.data?.data) {
                //             commit("SET_OVERALL_PLAYERS_DATA", res?.data?.data);
                //         }
                //         commit("SET_OVERALL_PLAYERS_DATA", []);
                //         return res?.data
                //     });
                commit("SET_OVERALL_PLAYERS_DATA", []);
                return { "success": false, "data": null };
            } catch (e) {
                commit("SET_OVERALL_PLAYERS_DATA", []);

            }
        },
        async getPlayersList({ commit }, payload) {
            try {
                return await Service(true)
                    .post(`result/get-list`, payload)
                    .then((res) => {
                        commit("SET_PLAYERS", res.data);
                        return res;
                    });
            } catch (e) {
                commit("SET_PLAYERS", []);
            }
        },
        async excelDownload({ commit }, payload) {
            try {
                return await Service(true)
                    .get("player/download", { params: payload })
                    .then((res) => {
                        commit("UPDATE_PLAYER", []);
                        return res;
                    });
            } catch (e) {
                return e;
            }
        },
        async reloadPlayers({ commit }, payload) {
            commit("UPDATE_PLAYER", []);
            try {
                return await Service(true)
                    .post(`player/reload-player-result-data`, payload)
                    .then((res) => {
                        return res;
                    });
            } catch (e) {
                return e
            }
        },
        //getting input fields data
        async getAddPlayerFieldsData({ commit }, payload) {
            // commit('ADD_PLAYER_FIELDS_DATA', [])
            try {
                return await Service(true)
                    .get(`result/get-event-meta-data`, { params: payload })
                    .then((res) => {
                        commit("SET_PLAYER_FIELDS_DATA", res.data);
                        return res;
                    });
            } catch (e) { }
        },
        async fetchPlayerFitFiles({ commit }, payload) {
            try {
                return await Service(true)
                    .post(`player/fetch-fit-files`, payload)
                    .then((res) => {
                        commit("SET_PLAYER_FILES", res.data);
                        return res;
                    });
            } catch (e) {


                // return e
            }
        },
        async fetchUsersList({ commit }, payload) {
            try {
                return await Service(true)
                    .post(`player/verify-player-id`, payload)
                    .then((res) => {
                        commit("GET_USERS_LIST", res.data);
                        return res;
                    });
            } catch (e) {


                return e
            }
        },
        async downloadPlayerFitFile({ commit }, payload) {
            try {
                return await Service(true)
                    .post(`player/download-fit-file`, payload)
                    .then((res) => {
                        commit("GET_PLAYER_FILES", res.data);
                        return res;
                    });
            } catch (e) {
                return e;
            }
        },
        async getPlayerTeamMetaData({ commit }, payload) {
            try {
                return await Service(true)
                    .post(`player/validate-player-team-id`, payload)
                    .then((res) => {
                        commit("GET_TEAM_META_DATA", res.data);
                        return res;
                    });
            } catch (e) {
                return e;
            }
        },
        async addPlayer({ commit }, payload) {
            try {
                let buildApiUrl = ''
                const { Action } = payload
                switch (Action) {
                    case 8010:
                        buildApiUrl = `${process.env.JAVA_API_BASE_URL}/v1/leaderboard/days/multiplayer`
                        break
                    case 8011:
                        buildApiUrl = `${process.env.JAVA_API_BASE_URL}/v1/leaderboard/days/multiplayer`
                        break
                    case 8013:
                        buildApiUrl = `${process.env.JAVA_API_BASE_URL}/v1/leaderboard/segments/multiplayer`
                        break
                    default:
                        buildApiUrl = ''
                        break
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'AuthKey': process.env.JAVA_API_HEADER_KEY,
                }
                return await axios
                    .post(buildApiUrl, payload, { headers })
                    .then((res) => {
                        if (res?.data?.data) {
                            commit("SET_OVERALL_PLAYERS_DATA", res?.data?.data);
                        }
                        commit("SET_OVERALL_PLAYERS_DATA", []);
                        return res?.data
                    });
            } catch (e) {
                return e;
            }
        },
        async updatePlayer({ commit }, payload) {
            try {
                return await Service(true)
                    .post("player/update-player-result-data", payload)
                    .then((res) => {
                        commit("UPDATE_PLAYER", res.data);
                        return res;
                    });
            } catch (e) {
                return e
            }
        },
        async removePlayer({ commit }, payload) {
            try {
                return await Service(true)
                    .post("player/remove-event-player", payload)
                    .then((res) => {
                        commit("REMOVE_PLAYER", res.data);
                        return res;
                    });
            } catch (e) {


                return e
            }
        },
        async getLiveRidersData({ commit }, payload) {
            try {
                return await Service(true)
                    .get('public/results/get-live-ridelog-data', { params: { ...payload } })
                    .then((res) => {
                        // commit("SET_PLAYERS_RIDE_DATA", res.data);
                        return res;
                    });
            } catch (e) {
                console.log("Error: ", e)
            }
        },
    },

    mutations: {
        SET_PLAYERS: (state, payload) => (state.players = payload),
        SET_PLAYERS_RIDE_DATA: (state, payload) => (state.playersRideData = payload),
        UPDATE_PLAYER: (state, payload) => (state.players = payload),
        REMOVE_PLAYER: (state, payload) => (state.players = payload),
        SET_PLAYER_FIELDS_DATA: (state, payload) => (state.playerFieldsData = payload),
        SET_LOGGEDIN_PLAYER_DATA: (state, payload) => (state.loggedInPlayerData = payload),
        SET_COMPARE_PLAYER_DATA: (state, payload) => (state.comparePlayerData = payload),
        SET_OVERALL_PLAYERS_DATA: (state, payload) => (state.overAllPlayersData = payload)
    },
};
