<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}



.sectionHeadingMain {
  font-family: 'Thunder';

  font-size: 80px;
  line-height: 100px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 35px;
  padding-top: 45px;
}

.multiStageLeftSide {
  background: #F2F2F2;
}


.stage-icon-text {
  text-align: center;
  padding: 18px;
  position: absolute;
  right: 0;
  top: 0;
  background: #F2F2F2 !important;
  height: 100%;
}

.stage-icon-image {
  margin-top: 35px;
}

h1.card-lgr-heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  padding: 0 12px;
}

.main-card {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  margin: 0;
}

.card-bg {
  background-color: #fbffef;
}

.card-heading-bg {
  background-color: #fffdea;
}

.main-card-content {
  border-top: 1px solid #e4e4e4;
  margin: 0px 20px 15px 20px;
}

.main-card .div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-wrpr {
  border-bottom: 0px solid #e4e4e4;
  padding: 15px 20px;
}

.p-20 {
  padding: 20px !important;
}

.m-20 {
  margin: 20px;
}

.m-0 {
  margin: 0 !important;
}

.heading-wrpr .div-flex .heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}

.heading-wrpr .div-flex .heading span {
  display: inline-flex;
  margin: 0 4px;
}

.heading-wrpr .div-flex .select-option select {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 12px;
  border: none;
  background-color: transparent;
}

.main-card .heading-small {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 20px 0;
}


.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-bg-sky {
  background-color: #e6f8fd;
}

.cal-bg-light-pink {
  background-color: #ffe7e7;
}

.cal-icon-text .date {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.cal-icon-text .zone {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.cal-wrpr .div-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.record-text table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0px 1rem;
}

.record-text table tr td {
  text-align: center;
  padding: 7px;
}

.record-text table tr td.number span {
  background: #91c951;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 12px 17px;
}

.record-text table tr td.name span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.0125em;
  background-color: #4f4f4f;
  color: #ffffff;
  padding: 12px 17px;
  opacity: 0.7;
  border-radius: 50%;
}

.bg-grn {
  background: #91c951;
}

.text-clr-white {
  color: #ffffff;
}


.card-btn button {
  background: #fcb913;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 12px 12px;
  margin: 20px auto;
  border: none;
}

.card-btn button:hover {
  background-color: #000;
  color: #fff;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
}

.txt-lft {
  text-align: right;
}

.txt-rgt {
  text-align: right;
}

.img-race {
  text-align: center;
  padding: 0px 0;
  height: 180px;
  margin-left: 18px;
  margin-right: 58px;
  width: 180px;
  overflow: hidden;
}

.stage-wrpr {
  padding: 30px 6px;
  border-top: 1px solid #fff;
  margin: 0;
  position: relative;
  background: #F9F9F9;
}

.stage-wrpr::before {
  content: "";
  height: 1px;
  background-image: linear-gradient(to right,
      rgba(255, 0, 0, 0),
      rgba(228, 228, 228, 1),
      rgba(255, 0, 0, 0));
}

.stage-wrpr:hover {
  background-color: #E8E8E8;
}

.stage-wrpr:last-child {
  padding: 30px 10px;
  border-bottom: 0px solid #e4e4e4;
}

.stage-wrpr .div-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.stage-icon-text .date {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.stage-icon-text .zone {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.stage-wrpr .lbl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  display: inline-block;
  align-items: center;
  text-align: center;
  background-color: #00b4e3;
  color: #ffffff;
  padding: 5px 22px;
  margin: 12px 0;
}

.stage-wrpr .lbl+img {
  margin: 0px 16px;
  vertical-align: middle;
  padding: 0px;
  margin-top: -5px;
}

.stage-wrpr .stage-heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}

.stage-heading img {
  margin: 0 8px;
}

.stage-wrpr .stage-dis {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.stage-wrpr .stage-buttons {
  flex-grow: 1 !important;
  text-align: left;
}

.stage-wrpr .stage-buttons a {
  background: #fcb913;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 12px 50px;
  margin: 20px auto;
  border: none;
  min-width: 149px;
  text-decoration: none;
}



.event_status_official {
  width: 31px;
  height: 31px;
  background: url("../../../../public/assets/officalBadge.svg");
  background-repeat: no-repeat;
  top: 10px;
  position: relative;
  margin-left: 9px;
  display: inline-block;
  background-size: contain;
}

.event_status_unofficial {
  display: block;
  width: 31px;
  height: 31px;
  background: url("../../../../public/assets/unofficalBadge.svg");
  background-repeat: no-repeat;
  top: 10px;
  position: relative;
  margin-left: 9px;
  display: inline-block;
  background-size: contain;
}


.load-btn-wrpr {
  margin: 0 auto;
  text-align: center;
  margin: 43px auto;
}

.loadmore {
  border: 1px solid #e1e1e1;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #000000;
  background-color: #fff;
  margin: 0 6px;
  padding: 12px 40px;
  cursor: pointer;
  min-width: 144px;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
}

.loadmore:hover {
  background: #fcb913;
  color: #000000 !important;
}
</style>

<style scoped>
@media screen and (max-width: 1920px) {
  .small-bld-blk:before {
    left: 28%;
  }

  .small-bld-blk:after {
    right: 28%;
  }
}

@media screen and (max-width: 1366px) {
  .small-bld-blk:before {
    left: 20%;
  }

  .small-bld-blk:after {
    right: 20%;
  }

  .section-wrap {
    padding: 12px 12px;
  }

  .event-more {
    background: url(../../../../public/themeV2/assets/images/hero-slider-v1.jpg);
    min-height: 38vh;
  }
}

@media screen and (max-width: 1200px) {
  .small-bld-blk:before {
    left: 30%;
  }

  .small-bld-blk:after {
    right: 30%;
  }

  .small-bld-blk {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg {
    font-size: 28px;
    line-height: 33px;
  }

  .heading-med {
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1024px) {

  .img-race {
    height: 85px;
    width: 85px;
  }

  .stage-wrpr .stage-heading {
    font-size: 15px;
  }

  .stage-wrpr .stage-buttons a {
    background: #fcb913;
    font-family: "Inter" !important;
    font-style: normal;

    font-size: 16px;

    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 7px 13px;
    margin: 0;

    min-width: auto;

  }

  .stage-icon-image {
    margin-top: 0;
  }

  .stage-icon-image img {
    max-width: 29px;
  }



  .sectionHeadingMain {
    font-size: 68px;

  }

  .cards-sections .card {
    width: 100%;
  }

  .stage-wrpr .div-flex {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }

  .img-race {

    margin-right: 18px;
  }



  h2.section-title {
    font-size: 68px;
  }

  .black-wrpr .div-flex .d-flex {
    flex-direction: column;
  }

  .cards-sections .card {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .section-card .col-10 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .stage-wrpr {
    height: 100% !important;
  }

  .stage-icon-text {
    position: relative !important;
  }

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B1B1B1;
    padding: 10px;
  }

  .multiStageLeftSide {
    display: none;
  }

  .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .stage-wrpr .stage-heading {
    font-size: 20px;
  }

  .stage-wrpr .stage-buttons a {
    margin: 8px auto;
    min-width: 90px;
  }
}

@media screen and (min-width: 747px) {
  .cards-sections .card-container {
    display: flex;
    flex-wrap: wrap;
  }



  .cards-sections-daily-event .card-container {
    display: flex;
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 991px) {
  .tab-chart button {
    width: 100%;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-blk:before {
    display: none;
  }

  .small-bld-blk::after {
    display: none;
  }

  .btn-regi {
    float: none;
    padding: 28px 25px;
    margin: 0 auto;
    border: none;
    width: 100%;
  }

  .card-box-info ul li {
    width: 100%;
    padding: 20px;
    margin: 5px auto;
  }

  .btn-wrpr button {
    font-size: 14px;
    padding: 18px 26px;
    margin: 4px auto;
    width: 83%;
  }

  .small-bld-text {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg-txt {
    font-size: 32px;
    line-height: 36px;
  }

  .event-wrapper button {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .cards-sections-daily-event .card {
    margin: 8px auto;
    padding: 6px;
    width: 95%;
  }

  .breadcrumb {
    padding: 5px;
    height: 85px;
    width: 100%;
    position: absolute;
    bottom: 2%;
  }

  .tab-regicontent p {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-left {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-right {
    width: 100%;
  }

  .multi-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-flex.btn-back-next .col-right {
    text-align: center;
  }

  .div-flex.btn-back-next .col-left .btn-back {
    width: 90%;
    margin: 6px 0;
  }

  .div-flex.btn-back-next .col-right .btn-next {
    width: 90%;
    margin: 6px 0;
  }

  .section-chart select {
    width: 62%;
  }

  .btn-wrpr {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
  }

  .tab-regi-wrpr h1 {
    margin: 20px 10px 30px 10px;
  }

  .tab-regi button {
    width: 100%;
  }

  .btn-upload {
    display: block;
    text-align: center;
  }

  .btn-upload button {
    margin: 0 auto;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }
}

@media (max-width: 1199.98px) {

  .routes img.intro-arrow-desktop-black {
    float: none;
    margin: 0 auto;
  }

  .div-flex .col-left {
    width: 100%;
  }

  .div-flex .col-right {
    width: 100%;
  }

  .section-wrap {
    padding: 120px 20px;
  }

  .owl-custom table thead tr th {
    font-size: 0.7rem;
  }

  .owl-custom table tbody tr td {
    font-size: 0.7rem;
  }

  .col-right h2 {
    text-align: inherit;
  }


  .main-card .heading-small {
    justify-content: center;
  }

  .card-btn.txt-rgt {
    text-align: center;
  }

  .stage-icon-text {
    margin: 12px;
  }

  .black-bar .div-flex {
    height: auto;
    padding: 12px;
  }

  .black-bar .search .row {
    flex-direction: column;
  }

  .card-box-info-overview ul li {
    width: 95%;
  }

  .live-section {
    height: auto;
  }

  .tab-live-wrpr h1 {
    padding: 12px;
  }

  .tab-live-wrpr {
    margin: 12px;
  }

  .live-tbl .table-outer {
    overflow: scroll;
  }

  .section-main select {
    width: 100%;
    margin: 5px auto;
  }

  .section-main select.sel-cutom {
    width: 100%;
    margin: 5px auto;
  }

  .list-indivi {
    width: 96%;
    margin: 12px;
  }

  .list-indivi ul {
    flex-direction: column;
  }

  .list-indivi ul li {
    padding: 11px;
    display: inline-block;
    width: 100%;
  }

  .sprint-btn a {
    margin: 2px auto;
  }

  .rank-tbl .table-outer {
    overflow: scroll;
  }
}

@media screen and (max-width: 640px) {

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B1B1B1;
    padding: 10px;
  }

  .stage-wrpr .stage-buttons {
    text-align: center;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;
    width: 100%;
    margin: 0px;
    margin-top: 20px;
  }

  .stage-wrpr .stage-heading {
    justify-content: center;
    margin-top: 20px;
  }

  .stage-icon-image {
    margin-top: 35px;
  }
}

@media screen and (max-width: 768px) {
  .sectionHeadingMain {
    margin-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    width: 100% !important;
    height: auto;

    margin-right: 18px;
    margin: 0 auto;
  }



  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .sectionHeadingMain {
    margin-bottom: 12px !important;
    padding-top: 12px !important;
    font-family: 'Thunder' !important;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {

    margin-right: 18px;

    margin: 0 auto;
  }



  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }
}
</style>

<template>

  <div class="section-card">
    <div class="container">
      <h1 class="sectionHeadingMain" >ALL RESULTS</h1>
      <div class="row m-0">
        <div class="col-2 multiStageLeftSide text-center pr-0">
          <div class="main-card"  >
             
          </div>
        </div>
        <div class="col-10">
          <div class="main-card">
            <div class="stage-wrpr" v-for="item in listData" :key="item._id"
                 :class="item?.isSeriesEvent ? 'card-heading-bg' : ''">
              <div class="div-flex">

                <div class="img-race" v-html="mapEventImage(item?.image, '', '', '100%')"></div>

                <div class="stage-detail">
                  <p class="stage-heading">{{ item?.EventName }} </p>
                  <div class="stage-buttons">
                    <router-link :to="{ name: 'EventResultIndex', query: { eventId: item.EventId } }"
                                 class="see-results-btn">VIEW RESULT
                    </router-link>
                    <img v-if="item?.ResultPublishStatus == 'official'" class="ml-4"
                         src="../../../../public/assets/officalBadge.svg"/>
                    <img v-else src="../../../../public/assets/unofficalBadge.svg" class="ml-4"/>
                  </div>
                </div>
                <div class="stage-icon-text cal-bg-sky">
                  <p class="stage-icon-image">
                    <template v-if="mapUserGenderColor(item?.Gender).gender === 1">
            <img src="../../../../public/assets/women-icon.png"/>
          </template>
          <template v-else>
            <img src="../../../../public/assets/psotIconBlue.svg"/>
          </template>
                  </p>
                  
                  <div class="date">{{ item.EventDateTime.split(" ")[0] }}</div>
                  <div class="zone">{{ item.EventDateTime.split(" ")[1] }} GST</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="load-btn-wrpr" v-if="listData.length && !noMoreData">
      <button class="loadmore" @click="loadMoreEvents()">SEE MORE</button>
    </div>
  </div>

</template>

<script>

import { mapEventImage, mapUserGenderColor } from "@/shared/events/event.helpers.ts";
import { mapActions } from "vuex";

export default {
  name: "AllEventsList",
  props: ["eventsData", "isNoMoreDataBtn"],
  data() {
    return {
      mapUserGenderColor: mapUserGenderColor,
      mapEventImage: mapEventImage,
      listData: [],
      listBackupData: [],

      noMoreData: this.isNoMoreDataBtn,

      selectedCategory: 'all',
      noResultsDataMessage: 'Loading Data....'
    }
  },

  watch: {
    eventsData: {
      handler: function (values) {
        this.listData = values
      },
      deep: true,
      immediate: true
    },
    isNoMoreDataBtn: {
      handler: function (value) {
        this.noMoreData = value
      },
      deep: true,
      immediate: true
    },

  },

  mounted() {
    this.listData = this.eventsData
    this.listBackupData = this.eventsData
  },

  methods: {
    ...mapActions("event", ["getAllEvents"]),

    async loadMoreEvents() {
      this.$emit("listenLoadMoreBtn", { btn: true });
    }

    //   this.queryFilter.page = this.queryFilter.page + 1
    //   const response = await this.getAllEvents({ isLive: this.isLive, filters: this.queryFilter })
    //   this.isNoMoreDataBtn = response?.data?.length ? false : true;
    //   console.log(" this.noMoreData", this.isNoMoreDataBtn);
    //   this.noResultsDataMessage = !response?.data?.length ? 'Sorry, No Data found with your selected filters' : '';
    //   this.listData = [...this.listData, ...response.data]
    //   this.$emit("listenerChild", { childLoader: false });
    // },
  }

}
</script>