var _ = require("lodash");
export const LiveGraphCanvas = function (sk, graphData = (e) => e) {
  var canvasPadding = {
    left: 50,
    right: 50,
    top: 50,
    bottom: 50,
  };
  var chartTop = 0;
  let chartLeft = 0;
  var chartRight = 0;
  var chartBottom = 0;
  var playIcon;
  var victoryIcon;
  var distance;
  var spectatorPlayer;
  var minElevation = 0;
  var maxElevation = 0;
  let canvasRef = "p5Canvas";
  let elevationPoints = [];
  var riderCoordinates = [];
  var areaChart;
  var setupCompleted = false;
  var riderJumpArr = [];

  sk.preload = () => {
    playIcon = sk.loadImage("assets/icons/play.png");
    victoryIcon = sk.loadImage("assets/icons/victory.png");
  };
  sk.setup = () => {
    setTimeout(() => {
      var canvas = sk.createCanvas(
        window.innerWidth - window.innerWidth * 0.215,
        window.innerHeight - window.innerHeight * 0.6
      );
      canvas.parent(canvasRef);

      areaChart = sk.createGraphics(
        window.innerWidth - window.innerWidth * 0.215,
        window.innerHeight - window.innerHeight * 0.6
      );
      chartTop = chartTop + canvasPadding.top;
      chartLeft = chartLeft + canvasPadding.left;
      chartRight = sk.width - canvasPadding.right;
      chartBottom = sk.height - canvasPadding.bottom;

      // set values on each draw
      const dataObj = graphData();
      distance = dataObj.distance || 0;
      spectatorPlayer = dataObj.spectatorPlayer;
      elevationPoints = dataObj.elevationValues;
      minElevation = _.min(elevationPoints) - 1;

      // maxElevation = dataObj.eventData.elevation;
      maxElevation = _.max(elevationPoints) * 4; // adding 50 extra elevation to the max value so that the values are fit on the screen

      areaChart.background(255, 255, 255);
      areaChart.fill(255, 255, 255);
      areaChart.rect(0, 0, sk.width, sk.height);

      // draw elevation area
      drawElevationArea();

      // segments
      drawSegmentsClans();

      // draw elevation values
      drawGraphElevationValues();

      // draw game start line
      drawVerticalLine(chartLeft, playIcon);

      // finish line
      drawVerticalLine(chartRight, victoryIcon);

      sk.image(areaChart, 0, 0);

      setupCompleted = true;
    }, 1000);
  };
  sk.draw = () => {
    // sk.frameRate(1);

    if (setupCompleted) {
      sk.image(areaChart, 0, 0);

      // set values on each draw
      const dataObj = graphData();
      spectatorPlayer = dataObj.spectatorPlayer;

      // build peletons
      const peletonsData = livePlayersPelotons();
      if (peletonsData.length) {
        // draw peletons first
        const peletons = peletonsData.filter((x) => x.playerCount > 1);
        const riders = peletonsData
          .filter((x) => x.playerCount === 1)
          .sort((a, b) => a.sortOrder - b.sortOrder);

        for (let i = 0; i < peletons.length; i++) {
          const peloton = peletons[i];
          const rider = _.maxBy(peloton?.riders, function (o) {
            return o.NewDistance;
          });
          drawRider(rider, false, false, true);
        }

        for (let i = 0; i < riders.length; i++) {
          const rider = riders[i].riders[0];
          if (rider.UserId == getCookie("whoosh_uuid")) {
            drawRider(rider, true);
          } else if (rider.UserId == spectatorPlayer?.userId) {
            drawRider(rider, false, true, false, spectatorPlayer);
          } else {
            drawRider(rider, false);
          }
        }
      }
    }
  };

  sk.keyPressed = () => {
    if (sk.key === "f") {
      // f === 70
      let fs = sk.fullscreen();
      sk.fullscreen(!fs);
    }
  };

  // Function to check if a rider is within some meters of the next rider
  function isWithin2Kilometers(distance1, distance2) {
    return Math.abs(distance1 - distance2) <= 0.02; // e.g 20m max criteria for peleton
  }

  function livePlayersPelotons() {
    const dataObj = graphData();
    const riderDistances = dataObj.rideData;

    if (!riderDistances?.length) {
      return [];
    }

    // Sort rider distances by distance
    riderDistances?.sort((a, b) => a?.NewDistance - b?.NewDistance);
    const pelotonGroups = groupIntoPeloton(riderDistances);
    return pelotonGroups;
  }

  // Function to group riders into a peloton
  function groupIntoPeloton(rideData) {
    const riderDistances = rideData;
    const pelotonGroups = [];
    let currentPelotonGroup = { riders: [], playerCount: 0, sortOrder: 1 }; // Initialize the current peloton group

    for (let i = 0; i < riderDistances.length - 1; i++) {
      const currentDistance = riderDistances[i];
      const nextDistance = riderDistances[i + 1];

      currentPelotonGroup.riders.push(currentDistance); // Add the current rider to the group
      currentPelotonGroup.playerCount++; // Increment the player count

      // push spectator
      const spectatorUser = spectatorPlayer?.userId === currentDistance.UserId;
      const currentUser = currentDistance.UserId == getCookie("whoosh_uuid");

      if (spectatorUser) {
        currentPelotonGroup.sortOrder = 4;
        pelotonGroups.push(currentPelotonGroup);
        currentPelotonGroup = { riders: [], playerCount: 0, sortOrder: 1 };
      } else if (currentDistance?.Jersey) {
        // push jersey user
        currentPelotonGroup.sortOrder = 2;
        pelotonGroups.push(currentPelotonGroup);
        currentPelotonGroup = { riders: [], playerCount: 0, sortOrder: 1 };
      } else if (currentUser) {
        // push login user
        currentPelotonGroup.sortOrder = 3;
        pelotonGroups.push(currentPelotonGroup);
        currentPelotonGroup = { riders: [], playerCount: 0, sortOrder: 1 };
      } else {
        if (
          !isWithin2Kilometers(
            currentDistance.NewDistance,
            nextDistance.NewDistance
          )
        ) {
          // push peloton
          pelotonGroups.push(currentPelotonGroup); // Add the current peloton group to the array
          currentPelotonGroup = { riders: [], playerCount: 0, sortOrder: 1 }; // Reset the current peloton group
        }
      }
    }
    // Add the last rider to the current peloton group
    currentPelotonGroup.riders.push(riderDistances[riderDistances.length - 1]);
    currentPelotonGroup.playerCount++;
    pelotonGroups.push(currentPelotonGroup); // Add the last peloton group to the array
    return pelotonGroups;
  }

  function drawElevationArea() {
    // draw the area chart
    areaChart.noStroke();
    // draw min and max distance
    areaChart.fill(0, 0, 0, 255);
    areaChart.text(`${setUptoDecimalValue(0)} km`, chartLeft, chartBottom + 14);
    areaChart.text(
      `${setUptoDecimalValue(distance)} km`,
      chartRight,
      chartBottom + 14
    );

    // draw the area chart
    // areaChart.fill(252, 185, 19);
    areaChart.fill(100, 192, 188);
    areaChart.beginShape();
    areaChart.vertex(chartLeft, chartBottom);
    for (let i = 0; i < elevationPoints.length; i++) {
      let x = areaChart.map(
        i,
        0,
        elevationPoints.length - 1,
        chartLeft,
        chartRight
      );
      let y = areaChart.map(
        elevationPoints[i],
        minElevation,
        maxElevation,
        chartBottom,
        chartTop
      );
      riderCoordinates.push({ x, y });
      areaChart.vertex(x, y);
    }
    areaChart.vertex(chartRight, chartBottom);
    areaChart.endShape();
  }

  // draw a rider with vertical line
  function drawRider(
    rider,
    isCurrentUser = false,
    isSpectator = false,
    isPeleton = false,
    spectator = null
  ) {
    const index = riderJumpArr.findIndex(
      (item) => item.UserId === rider.UserId
    );

    // map old distance on the graph
    // const oldRiderDistance = Math.floor(rider.OldDistance);
    // const OldDistance = sk.map(oldRiderDistance, 0, distance, 0, elevationPoints.length);
    // const xOld = sk.map(Math.floor(OldDistance), 0, Math.round(elevationPoints.length - 1), chartLeft, chartRight);

    // map new distance on the graph
    const newRiderDistance = rider.NewDistance;
    const NewDistance = sk.map(
      newRiderDistance,
      0,
      distance,
      0,
      elevationPoints.length
    );
    const xNew = sk.map(
      Math.floor(NewDistance),
      0,
      elevationPoints.length - 1,
      chartLeft,
      chartRight
    );

    const finishLine = sk.map(distance, 0, distance, 0, elevationPoints.length);
    const finishLineX = sk.map(Math.floor(finishLine), 0, elevationPoints.length - 1, chartLeft, chartRight);

    let oldXCord = riderCoordinates.find((c) => c?.x === xNew);

    if (xNew >= finishLineX && index != -1) {
      riderJumpArr[index].x = finishLineX;
      riderJumpArr[index].y = chartBottom;
    } else {
      if (riderJumpArr[index] && riderJumpArr[index]?.x < xNew) {
        if (index !== -1) {
          riderJumpArr[index].x = riderJumpArr[index]?.x + 0.1;
          const jumpCord = riderCoordinates.find(
            (c) => Math.round(c?.x) === Math.round(riderJumpArr[index]?.x)
          );
          if (jumpCord) {
            riderJumpArr[index].y = jumpCord?.y;
          }
        }
      } else {
        riderJumpArr.push({
          UserId: rider.UserId,
          x: oldXCord?.x,
          y: oldXCord?.y,
        });
      }
    }

    if (index !== -1) {
      const riderXpos = riderJumpArr[index]?.x;
      const riderYpos = riderJumpArr[index]?.y;

      sk.stroke(1);
      if (isSpectator) {
        const xposTop =
          riderXpos - 20 > chartLeft ? riderXpos - 20 : riderXpos + 20;
        const yposTop = chartTop + riderYpos - 100;

        sk.ellipse(xposTop, yposTop, 20, 20);
        sk.line(riderXpos, riderYpos, xposTop, yposTop + 10);

        sk.fill(40, 40, 40, 90);
        sk.text(spectator?.userFullName?.charAt(0), xposTop - 5, yposTop + 4);
        sk.fill(40, 40, 40, 90);
        sk.ellipse(riderXpos, riderYpos, 15, 15);
      } else {
        if (isPeleton) {
          sk.fill(45, 45, 45);
          sk.ellipse(riderXpos, riderYpos, 15, 15);
        } else {
          if (rider?.Jersey != null && rider?.Color) {
            const colors = rider.Color.split(",");
            sk.fill(colors[0], colors[1], colors[2]);
            sk.ellipse(riderXpos, riderYpos, 15, 15);
          } else {
            if (isCurrentUser) {
              sk.fill(0, 170, 255);
            } else {
              sk.fill(40, 40, 40, 90);
            }
            sk.ellipse(riderXpos, riderYpos, 15, 15);
          }
        }
      }

      sk.noStroke();
      // sk.text(count, riderXpos, riderYpos - 20);
    }
  }

  const between = (x, min, max) => {
    return x >= min && x <= max;
  };

  //draw segments clans
  function drawSegmentsClans() {
    const daysData = JSON.parse(sessionStorage.getItem("graphDays"));
    const gatesList = daysData?.ListOfGates || [];
    for (let i = 0; i < gatesList.length; i++) {
      const segment = gatesList[i];
      const segStart = parseFloat(segment.StartDistance || 0);
      const segEnd =
        parseFloat(segment.StartDistance || 0) +
        parseFloat(segment.TotalDistance || 0);
      const segmentStart = areaChart.map(
        segStart,
        0,
        distance,
        chartLeft,
        chartRight,
        true
      );
      const segmentEnd = areaChart.map(
        segEnd,
        0,
        distance,
        chartLeft,
        chartRight,
        true
      );

      // draw the segment lines
      areaChart.erase();
      areaChart.stroke(0);
      areaChart.drawingContext.setLineDash([5]);
      areaChart.line(segmentStart, 0, segmentStart, chartBottom);
      areaChart.line(segmentEnd, 0, segmentEnd, chartBottom);
      areaChart.noStroke();
      areaChart.noErase();

      // draw the segment rect on chart
      areaChart.noStroke();
      if (segment.GateType == 9) {
        areaChart.fill(0, 225, 0);
      } else {
        areaChart.fill(252, 220, 71);
      }

      areaChart.beginShape();
      areaChart.vertex(segmentStart, chartBottom);
      for (let i = 0; i < elevationPoints.length; i++) {
        let x = areaChart.map(
          i,
          0,
          elevationPoints.length - 1,
          chartLeft,
          chartRight
        );
        let y = areaChart.map(
          elevationPoints[i],
          minElevation,
          maxElevation,
          chartBottom,
          chartTop
        );

        const stDiff = x - segmentStart;
        const dfDiff = x - segmentEnd;
        const xnew = between(x, 0, segmentStart);
        const xnew1 = between(x, segmentStart, segmentEnd);
        if (xnew) {
          areaChart.vertex(x - stDiff, y);
        } else if (xnew1) {
          areaChart.vertex(x, y);
        } else {
          areaChart.vertex(x - dfDiff, y);
        }
      }
      areaChart.vertex(segmentEnd, chartBottom);
      areaChart.endShape();

      // areaChart.text(segEnd)
      areaChart.fill(0, 0, 0, 255);
      areaChart.text(
        `${setUptoDecimalValue(segStart)} km`,
        segmentStart,
        chartBottom + 14
      );

      // const segLineY = chartTop + maxElevation

      // draw the segment end line text
      // areaChart.push();
      // areaChart.fill(0, 0, 0, 255);
      // areaChart.text(`${setUptoDecimalValue(parseFloat(gatesList[i].TotalDistance || 0))} km`, segmentEnd, segLineY)
      // areaChart.pop();

      areaChart.push();
      let angle2 = areaChart.radians(270);
      areaChart.translate(segmentEnd, chartBottom);
      areaChart.rotate(angle2);
      // Draw the letter to the screen
      areaChart.text(
        `${setUptoDecimalValue(parseFloat(segment.TotalDistance || 0))} km`,
        chartBottom - 100,
        -10
      );
      areaChart.stroke(0);
      areaChart.drawingContext.setLineDash([5]);
      areaChart.line(0, 0, chartBottom - 50, 0);
      areaChart.pop();

      // areaChart.fill(0, 0, 0, 255);
      // areaChart.text(`${setUptoDecimalValue(parseFloat(segment.TotalDistance || 0))} km`, segmentEnd, segLineY)

      // // draw segment end line
      // areaChart.stroke(0)
      // setLineDash([5]);
      // areaChart.line(segmentEnd, chartBottom, segmentEnd, segLineY);
    }
  }

  // draw elevation of temperature up the left-hand side
  function drawGraphElevationValues() {
    const iterableNum = maxElevation / 3;

    areaChart.textAlign(areaChart.LEFT);
    for (let t = minElevation; t <= maxElevation; t += iterableNum) {
      let ty = areaChart.map(t, minElevation, maxElevation, chartBottom, 120);
      let degrees = areaChart.floor(t) + " m";
      areaChart.text(degrees, 10, ty - 5);
    }
  }

  // draw a vertical line
  function drawVerticalLine(x, img) {
    // draw image on the line
    const y = chartTop + areaChart.height * 0.05;
    areaChart.image(img, x - 15, y - 40);

    // draw the lines
    areaChart.stroke(3);
    areaChart.drawingContext.setLineDash([0]);
    areaChart.line(x, chartBottom, x, y);
  }

  function setUptoDecimalValue(value) {
    const n = 1; //upto 2 decimal places
    return Number(
      Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)
    ).toFixed(n);
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
};
