<template>
  <div id="test">
    <header-component />
      <router-view></router-view>
  
    </div>

  <footer-component />
</template>

<style id="kl-custom-fonts">
#test {
  min-height: calc(100vh - 40px);
}

@font-face {
  font-family: "Inter";
  src: url("../../../public/themeV2/custom-fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../../../public/themeV2/custom-fonts/Inter-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src: url("../../../public/themeV2/custom-fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../../../public/themeV2/custom-fonts/Inter-SemiBold.ttf");
  font-weight: bold;
  font-style: italic, oblique;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
}
</style>

<script>
import HeaderComponent from "@/shared/components/HeaderComponent.vue";
import FooterComponent from "@/shared/components/FooterComponent.vue";
// const createHost = require('cross-domain-storage/host');
// const createGuest = require('cross-domain-storage/guest');
import { mapActions } from "vuex";

export default {
  name: "FrontendLayout",
  head: {
    script: [
      {
        type: "text/javascript",
        src: "../../../public/jquery/jquery-3.3.1.min.js",
        async: true,
        body: false,
      },
    ],
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  async mounted() {
    // const res = await this.getLoggedInPlayerDetails({ eventId: this.event.serverEventId, userId: rowData.userId })

    await this.getLoggedInPlayerDetails()



    // createHost([
    //     {
    //         origin: 'https://event.mywhoosh.com',
    //         allowedMethods: ['get', 'set', 'remove'],
    //     },
    //     {
    //         origin: 'http://localhost:8081',
    //         allowedMethods: ['get', 'set', 'remove'],
    //     },
    // ]);

    // const bazStorage = createGuest('https://event.mywhoosh.com');
    // bazStorage.get('fizz', function(error, value) {
    //     // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
    //     console.log(error, value, "=================value");
    // });
  },
  methods: {

    ...mapActions("player", ["getLoggedInPlayerDetails"]),

  }
};
</script>
