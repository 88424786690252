<template>
  <div class="container-fluid">
    <div class="row">
      <section class="col-md-12 top-heading">
        <h1><slot></slot></h1>
      </section>
    </div>
  </div>
</template>

<style scoped>
.top-heading h1 {
  font-weight: 700;
  color: #121113;
  font-size: 30px;
  padding-top: 0px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
}
.top-heading h1:after {
  position: absolute;
  content: "";
  width: 6%;
  margin: 12px auto;
  height: 1px;
  border-bottom: 4px solid #f1aa00;
  bottom: -2px;
  right: 0;
  left: 0;
}
</style>
