<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}

.team-main-row>td:nth-child(1) {
  width: 33% !important;
}

.customerDetails span:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 200px !important;
}



.rank-tbl table tbody tr th {
  background: #000000 !important;
  font-family: 'Inter' !important;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF !important;
}

div.rank-tbl table tbody tr>table>tr:nth-child(1) td {
  background-color: #dbdbdb;
  vertical-align: top;
}

div.rank-tbl table tbody tr>table>tr:nth-child(1) td span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #FFFFFF;
}

div.rank-tbl table tbody tr:nth-child(1) table tr:nth-child(2) td span,
div.rank-tbl table tbody tr:nth-child(2) table tr:nth-child(1) td span,
div.rank-tbl table tbody tr:nth-child(3) table tr:nth-child(1) td span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;


  text-align: center;

  color: #FFFFFF;
}

div.rank-tbl table tbody tr:nth-child(1) table tr:nth-child(2) td {
  background: #FCB913 !important;
  vertical-align: top;
}


div.rank-tbl table tbody tr:nth-child(2) table tr:nth-child(1) td {
  background: #62C3EC !important;
}

div.rank-tbl table tbody tr:nth-child(3) table tr:nth-child(1) td {
  background: #EA5F28 !important;
}









.rank-tbl table tr.active {
  border: 2px solid #fcb913 !important;
}

.rank-tbl table tr td span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 0px 0px;
  width: 69px;
  height: auto;
  display: inline-block;
}

.rank-tbl table tr.active td span {
  background: #91c951;
  color: #ffffff;
}


.rank-tbl table tr.active td {
  font-weight: 700;
  padding: 16px 20px;
}

.rank-tbl table tr td .jersey-img img {
  width: 50px;
}

.rank-tbl table tr td .jersey-img {
  width: 76px;
  text-align: center;
}

.rank-tbl table tr.active td .jersey-img img {
  width: 76px;
}

.rank-tbl table tr td {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #000000;
  background-color: transparent;
  border-bottom: 1px solid #eee;
  padding: 4px 18px;
  vertical-align: middle;
}

.rank-tbl .table-outer {
  border: 0px solid #e1e1e1;
  padding: 0px;
  overflow-y: scroll !important;
}

.rank-tbl table tr:nth-child(even) {
  background: white !important;
}

.rank-tbl table tr:nth-child(odd) {
  background: white !important;
}

.rank-tbl .div-flex {
  display: flex;
  align-items: center;
  justify-content: inherit;
}


.vis-none {
  visibility: hidden;
}

.table.dataTable tr td.separate-list {
  border-left: 0px solid #fcb913;
  padding: 0;
}

.table.dataTable tr td.separate-list ul li {
  min-height: 40px;
  border-bottom: 0px solid #fcb913;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.txt-flex-s {
  justify-content: flex-start !important;
  padding-left: 12px;
}



.table.dataTable tr td.separate-list ul li div {
  display: inline-block;
}

.table.dataTable tr td.separate-list img {
  max-width: 20px !important;
  margin-right: 5px;
}

.rank-tbl table tr.active {
  border: 2px solid #fcb913 !important;
}

.teamJersy img {
  height: 60px;
  margin-right: 10px;
}

.table .teamJersyTitle {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 250px;
}

.table .teamJersyTitleMob {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 250px;
}

.table .teamJersyDescription {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}

.gapsMain {
  color: #2FAC24 !important;
}

@media (max-width: 991px) {
  tr.team-sub-row td {
    width: 74% !important;
  }
}


@media (max-width: 575px) {

  .team-main-row>td:nth-child(1) {
    width: 33% !important;
  }

  .rank-tbl .container {
    padding: 0;
  }

  .rank-tbl-mob .container {
    padding: 0;
  }

  .team-name-mob {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }

  .rank-tbl table tr td span {
    width: 45px !important;
    height: auto !important;
    font-size: 13px !important;
    padding: 0px 9px;
  }

  .rank-tbl table tr td span.rank {
    font-size: 20px !important;
  }

  .customerDetails span:nth-child(3) {
    white-space: nowrap;
    width: 100px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    min-width: 100px !important;
  }

  .table .teamJersyTitle {
    font-size: 16px;
    width: 100px;
  }

  .table .teamJersyTitleMob {
    font-size: 13px;
  }

  .table .teamJersyDescription {
    font-size: 9px;
    margin-left: 18px;
  }

  .rank-tbl table tr td {
    padding: 0px 0px;
  }
}

tr.team-sub-row td {
  width: 30%;

}

tr.team-rank-row td {
  width: 10%;
  text-align: center;
}
</style>

<template>

  <page-loader v-if="loader" style="margin-top: 50px"/>

  <div class="rank-tbl">
    <div :class="isMobileDevice ? 'container-fluid p-0' : 'container'">
      <!--      <div class="table-outer tab-tab tab-active" data-id="tab1">-->
      <table class="table dataTable" style="border: 1px solid #f2f2f2">
        <tbody>
        <tr scope="row" class="mainTR" v-for="(team, i) in teamResults" :key="i" v-show="!team.dqStatus">

          <table class="table dataTable" width="100%">
            <tr scope="row" v-if="i === 0" v-show="!isMobileDevice">
              <th scope="col">Rank</th>
              <th scope="col">Rider</th>
              <th scope="col">Time</th>
              <th scope="col" v-if="!isMobileDevice && !isHandiCapEvent">Gap</th>
            </tr>
            <tr scope="row" class="team-rank-row">
              <td scope="col" :rowspan="(team.players.length + 2)">
                <span class="rank">{{ i + 1 }}</span>
              </td>
            </tr>
            <tr scope="row" class="team-sub-row">
              <td scope="col">
                <div class="d-flex teamJersy" v-if="!isMobileDevice">
                  <span style="padding: 0" v-html="mapJerseyImage(team.jerseyImage, '60px', '60px')"></span>
                  <div>
                    <p class="teamJersyTitle">{{ team.teamName }}</p>
                    <p class="teamJersyDescription">Team name</p>
                  </div>
                </div>
                <div class="d-flex teamJersy team-name-mob" v-else>
                  <span style="padding: 0" v-html="mapJerseyImage(team.jerseyImage, '40px', '40px')"></span>
                  <div>
                    <p class="teamJersyTitle">{{ team.teamName }}</p>
                  </div>
                </div>
              </td>
              <td scope="col">
                <div class="d-flex teamJersy" v-if="!isMobileDevice">
                  <div>
                    <p class="teamJersyTitle" v-html="teamTimeFormat(i, team)"></p>
                    <p class="teamJersyDescription">Team time</p>
                  </div>
                </div>
                <div class="d-flex teamJersy team-mob-finished-time" v-else>
                  <div>
                    <p class="teamJersyTitleMob" v-html="teamTimeFormat(i, team)"></p>
                    <p class="teamJersyDescription gapsMain"  v-if="!isHandiCapEvent" v-html="teamGapTimeFormat(i, team)"></p>
                  </div>
                </div>
              </td>
              <td scope="col" v-if="!isMobileDevice && !isHandiCapEvent">
                <div class="d-flex teamJersy">
                  <div>
                    <p class="teamJersyTitle gapsMain" v-html="teamGapTimeFormat(i, team)"></p>
                    <p class="teamJersyDescription"> Team Gap</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr scope="row" class="team-sub-row" v-for="(player, j) in team.players" :key="j">
              <td scope="col">
                <div class="customerDetails">
                  <span>{{ player.selectionStatus && (eventResultStatus == "official" || isSeriesEvent) ? player.selectionStatus : (player.rank == -1 ? j + 1 : player.rank) }}</span>
                  <span style="padding: 0" v-if="isMobileDevice"
                        v-html="mapCountryFlag(player.userCountryFlag, '30px', '30px')"></span>
                  <span style="padding: 0" v-else
                        v-html="mapCountryFlag(player.userCountryFlag, '40px', '40px')"></span>
                  <span>{{ player.userFullName }}</span>
                </div>
              </td>
              <td scope="col">
                <div class="customerDetails">
                  <span class="time">{{ playerFinishTimeFormat(player) }}</span>
                </div>
              </td>
              <td scope="col" v-if="!isMobileDevice && !isHandiCapEvent">
                <div class="customerDetails">
                  <span class="gaps">{{ playerGapTimeFormat(player, team.players[0]) }}</span>
                </div>
              </td>
            </tr>
          </table>
        </tr>

        <tr scope="row" v-if="!teamResults.length && !isMobileDevice">
          <th scope="col">Rank</th>
          <th scope="col">Rider</th>
          <th scope="col">Time</th>
          <th scope="col" v-if="!isHandiCapEvent" >Gap</th>
        </tr>

        <tr v-if="!teamResults.length">
          <td colspan="8" style=" line-height: 57px;" class="text-center"> {{ tableMessage }}</td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { teamColumns } from '@/shared/events/columns.dto.ts';
import { formatTime, mapCountryFlag, mapCountryName, mapJersey, mapJerseyImage } from '@/shared/events/event.helpers.ts';
import teampointsboards from '@/theme/local-db/team-points-leader-board.json';
import { mapGetters } from "vuex";


export default {
  props: ['showColumns', 'eventId', 'datetime', 'isStages', 'isSeriesEvent', 'stage', 'isLive', 'loader', 'isMobileDevice', 'isTabletDevice', 'noResultsDataMessage', 'isHandiCapEvent', 'eventResultStatus'],
  name: 'TeamsResultTableComponent',
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters("event", ["teamResults"]),
  },
  data() {
    return {
      columns: teamColumns,
      mapJersey: mapJersey,
      mapJerseyImage: mapJerseyImage,
      mapCountryName: mapCountryName,
      mapCountryFlag: mapCountryFlag,
      formatTime: formatTime,
      teampointsboards: teampointsboards,
      loading: false,
      tableMessage: this.noResultsDataMessage,
    }
  },

  async mounted() {
    // this.tableMessage = !this.teamResults.length ? "No Data is available" : this.tableMessage
  },

  watch: {
    noResultsDataMessage: {
      handler: function (values) {
        this.tableMessage = values
      },
      deep: true,
      immediate: true
    },

  },

  methods: {

    teamTimeFormat(i, team) {
      return (team.dqStatus && this.eventResultStatus == "official") ? "-" : formatTime(team.finishedTime || 0, this.datetime);
    },

    teamGapTimeFormat(i, team) {
      let firstTeam = null;
      if (this.teamResults.length > 0) {
        firstTeam = this.teamResults[0];
      }
      return (team.dqStatus && this.eventResultStatus == "official") ? "-" : '+' + formatTime(team.finishedTime - firstTeam.finishedTime);
    },

    playerFinishTimeFormat(player) {

      return (player.selectionStatus && this.eventResultStatus == "official") ? "-" : formatTime(player.finishedTime);
    },

    playerGapTimeFormat(player, firstPlayer) {
      return (player.selectionStatus && this.eventResultStatus == "official") ? "-" : '+' + formatTime(player.finishedTime - firstPlayer.finishedTime);
    },
  }
};
</script>