<template>
  <page-heading> Roles & Permissions </page-heading>
  <AdminNavigationVue />

  <div class="container">
    <page-loader v-if="loading" />

    <div class="container">
      <div class="table-responsive individual-result table-wrap">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Actions</th>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Value</th>
              <th scope="col">Total Users</th>
              <th scope="col">Created At</th>
              <th scope="col">Updated At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="14">
                <page-loader />
              </td>
            </tr>
            <tr class="eventRow" v-for="(role, k) in rolesList" :key="k">
              <td scope="row">
                <button
                  type="button"
                  class="points-btn btn-sm"
                  v-if="role.value != 'whoosh-super-admin'"
                >
                  Edit Permissions
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  v-if="role.value != 'whoosh-super-admin'"
                >
                  Delete
                </button>
              </td>
              <td scope="row">{{ rowNumber(k) }}</td>
              <td scope="row">{{ role.name }}</td>
              <td scope="row">{{ role.value }}</td>
              <td scope="row">{{ role.totalUsers }}</td>
              <td scope="row">{{ getDate(role.created_at) || "-" }}</td>
              <td scope="row">{{ getDate(role.updated_at) || "-" }}</td>
            </tr>
          </tbody>
        </table>
        <p class="mt-3" v-if="!rolesList.length" style="text-align: center">
          {{ loading ? "Loading.... " : "No Roles are found" }}
        </p>
      </div>
    </div>

    <h2>NOTE:</h2>

    <div class="row">
      <section class="col-lg-12 col-md-12">
        <div class="form-group">
          <h6>Some points regarding roles and Permissions:</h6>
          <br />
          <p>1- Only Super Admin can create new roles and assign</p>
          <p>
            2- No Role can be deleted unless there are no users attached to it
          </p>
          <p>3- Each Role has set of permissions to be enabled and disabled</p>
          <p>
            4- Each User can have one role at a time with multiple permissions
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AdminNavigationVue from "../../../../shared/components/AdminNavigation.vue";

export default {
  name: "RolesAndPermissions",

  components: {
    AdminNavigationVue,
  },

  data() {
    return {
      loading: false,
      currentPage: 1,
      limit: 10,
      rolesList: [
        {
          name: "Super Admin",
          value: "whoosh-super-admin",
          totalUsers: 1,
          created_at: 12 / 12 / 12,
          updated_at: 12 / 121 / 21,
        },
        {
          name: "Admin",
          value: "whoosh-admin",
          totalUsers: 12,
          created_at: 12 / 12 / 12,
          updated_at: 12 / 121 / 21,
        },
        {
          name: "Editor",
          value: "whoosh-editor",
          totalUsers: 12,
          created_at: 12 / 12 / 12,
          updated_at: 12 / 121 / 21,
        },
      ],
    };
  },

  methods: {
    rowNumber(k) {
      return k + 1 + (this.currentPage - 1) * this.limit;
    },

    getDate(dateTime) {
      const dt = new Date(dateTime);
      return dt.toLocaleString();
    },
  },
};
</script>
