<template>
  <VueFinalModal
    v-model="isShowModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
    class="popup"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <span class="modal__title">
      Add Points
      <br />
      {{ event.name }}
    </span>
    <div class="modal__content">
      <hr />
      <Form
      @submit="submitHandler"
      :validation-schema="isUCIEvent ? UciFormschema : schema"
      v-slot="{ errors }"
        ref="addPointsForm"
      >
        <div class="row" v-if="!isUCIEvent">
          <section class="col-lg-8 col-md-6">
            <div class="form-group">
              <label
                for="type"
                :class="{ 'is-invalid': errors.samePointsAcross }"
              >- Do you want to add same points for all days and gates?</label>
            </div>
          </section>

          <section class="col-lg-4 col-md-4">
            <div class="form-group form-check">
              <Field
                name="samePointsAcross"
                type="radio"
                value="yes"
                class="form-check-input"
                id="samePointsAcross"
                v-model="samePointsAcross"
              />
              <label class="form-check-label" for="Yes">Yes</label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <Field
                name="samePointsAcross"
                type="radio"
                value="no"
                class="form-check-input"
                id="samePointsAcross"
                v-model="samePointsAcross"
              />
              <label class="form-check-label" for="No">No</label>
              <div class="invalid-feedback">{{ errors.samePointsAcross }}</div>
            </div>
          </section>
        </div>

        <div v-if="samePointsAcross == 'no' || isUCIEvent">
          <div class="row">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedDay">- Select Day</label>
                <Field
                  id="selectedDay"
                  name="selectedDay"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedDay }"
                  @change="selectDayDropdownReply($event.target.value)"
                  v-model="selectedDayIndex"
                >
                  <option v-for="(item, i) in stages" :value="i" :key="i">{{ item.DayName }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedDay }}</div>
              </div>
            </section>
          </div>

          <div class="row" v-if="selectedDayIndex != null && !isUCIEvent">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to add same points for all gates in this day?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="daySamePoints"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="daySamePoints"
                  v-model="daySamePoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="daySamePoints"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="daySamePoints"
                  v-model="daySamePoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.daySamePoints }}</div>
              </div>
            </section>
          </div>
          <hr />

          <!-- List of Gates Points Setting -->
          <!-- <div class="row" v-if="daySamePoints == 'no'">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedGate">- Select Gate</label>
                <Field
                  id="selectedGate"
                  name="selectedGate"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedGate }"
                  v-model="selectedGate"
              
                >
                  <option
                    :value="item.id + '+' + item.lapNo"
                    v-for="(item, i) in selectedDayStages"
                    :key="i"
                  >{{ item.label }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedGate }}</div>
              </div>
            </section>


            <div>
    

          </div>
         
          </div> -->



          <div class="row" v-if="daySamePoints == 'no' || isUCIEvent" >
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedGatesOption">- Select Leaderboard</label>
                <VueMultiselect
                v-model="selectedGates"
                :options="selectedDayStages"
                :multiple="true"
                :close-on-select="true"
                placeholder="Pick some"
                label="label"
                name="label"
                track-by="label" />
            </div>
            </section>
          </div>
          <!-- End of gate points setting -->
          </div>


        <div
          class="row"
          v-if="samePointsAcross == 'yes' || daySamePoints == 'yes' || selectedGates?.length && selectedDayTotalGates > 0"
        >
          <section class="col-lg-9 col-md-12">
            <div class="form-group">
              <label for="type">
                - Set Points Separated by Commas?
                <sub>(e.g: 2,40,23,49)</sub>
              </label>
              <br />
              <Field
                name="pointsArr"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': regexValidMsg }"
                id="pointsArr"
                @keyup="checkCharsLimit($event.target.value)"
                v-model="pointsArr"
              >
              </Field>
              <!-- <div class="invalid-feedback">{{ errors.pointsArr }}</div> -->
              <p v-if="regexValidMsg?.length == 0">
                Points set for
                <strong>Top {{ totalRanks }} Rank</strong>
              </p>
              <p v-else :style="{ color: '#dc3545' }">{{ regexValidMsg }}</p>
            </div>
          </section>

          <section class="col-lg-12 col-md-12">
            <div class="form-group">
              <ul>
                <li>Min value of each point is 0. No negative values are accepting.</li>
                <li>Points will be set for ranks according to your order. for example [100,90,80,40,33,10] the 100 represents 1st Rank points and so on.</li>
              </ul>
            </div>
          </section>
          <section class="col-lg-12 col-md-12">
            <div class="form-group" style="background-color: #fcb913;">
              <p style="padding: 8px">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Make sure you have set the correct ordering and options. Upon submission, it will set points according to the options you selected.
              </p>
            </div>
          </section>
        </div>

        <hr />

        <!-- <div class="row" v-if="samePointsAcross != ''"> -->
           <div class="row" v-if="samePointsAcross != ''">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn btn-lg float-center" style="width: 140px; font-size: 15px">Submit</button>
          </section>
        </div>
      </Form>
    </div>
  </VueFinalModal>
</template>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;

  font-weight: 700;
  text-align: center;
  text-decoration: underline;

  color: #fcb913;
  font-size: 30px;

  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {

  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>

 

<script>
import { Field, Form } from "vee-validate";
import { VueFinalModal } from "vue-final-modal";
import VueMultiselect from 'vue-multiselect';
import { mapActions } from "vuex";
import * as Yup from "yup";


export default {

  name: "AddPointsModal",
  props: ["event", "addPointsModal", "stages", "currentStageGates", "totalGateTabs", "isUCIEvent"],
  components: {
    Form,
    Field,
    VueFinalModal,
    VueMultiselect
  },

  watch: {
    totalGateTabs: function (value) {
      this.selectedDayTotalGates = value
    },
    currentStageGates: function (values) {
      this.selectedDayStages = values
    },
    stages: function () {
    },
    addPointsModal: function (value) {
      this.isShowModal = value;
    }
  },
  setup() {
    const schema = Yup.object().shape({
      samePointsAcross: Yup.string().required("please choose option"),
      pointsArr: Yup.string().required("please enter points")
    });

    const UciFormschema = Yup.object().shape({
      pointsArr: Yup.string().required("please enter points")
    });
    return {
      schema, UciFormschema
    };
  },
  data() {
    return {
      // selectedGate: null,
      selectedDayIndex: null,
      selectedDayId: null,
      regexValidMsg: "",
      totalRanks: 0,
      pointsArr: "",
      samePointsAcross: null,
      daySamePoints: null,

      isShowModal: this.addPointsModal,
      selectedDayTotalGates: this.totalGateTabs,
      selectedDayStages: this.currentStageGates,
      loading: false,

      selectedGates: null,
      options: [],

    };
  },
  mounted() {

  },

  methods: {
    ...mapActions("event", ["setEventPoints"]),
    async submitHandler(formData) {

      this.$refs.addPointsForm.resetForm();
      formData = {
        ...formData,
        eventId: this.event?.serverEventId,
        selectedDay: this.stages[this.selectedDayIndex]?.DayId || null,
        daySamePoints: this.daySamePoints || 'no',
        samePointsAcross: this.samePointsAcross || 'no',
        selectedGates: this.selectedGates?.length ? this.selectedGates.map(x => { return { gateId: x.id, lapNo: x.lapNo, type: x.type } }) : [],
        eventType: this.event?.EventFormatType,
        isUCIEvent: this.isUCIEvent || false
      };
      const getResponse = await this.setEventPoints(formData);
      this.isShowModal = false;
      this.$emit("listenerChild", { type: 'add-points', popup: false });
      this.$refs.addPointsForm.resetForm();
      this.selectedGates = []
      getResponse.error ? true : setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    handleClose() {
      this.isShowModal = false;
      this.$emit("listenerChild", { type: 'add-points', popup: false });
      this.selectedGates = []
      this.$refs.addPointsForm.resetForm();

    },
    selectDayDropdownReply(selectedDayId) {
      this.$emit("selectedDayDropdownListener", selectedDayId)
      this.selectedGatesOption = [] //if day changed the selectedOptions will be reset
      this.selectedGates = []
    },
    checkCharsLimit(pointsValue) {
      if (pointsValue.includes(",")) {
        if (!/^([0-9]*)+(,[0-9]+)+$/.test(pointsValue)) {
          this.regexValidMsg = "Invalid Format";
        } else {
          this.totalRanks = pointsValue.split(",").length;
          this.regexValidMsg = "";
        }
        if (!pointsValue.split(",").every(n => n.length < 6)) {
          this.regexValidMsg = "Invalid Format";
        }
      } else {
        if (!/^[0-9]*$/.test(pointsValue) || pointsValue.length > 5) {
          //the second OR condition restrict and limit the maximum points to 3 digits max 999
          this.regexValidMsg = "Invalid Format";
        } else {
          this.totalRanks = pointsValue.length ? 1 : 0;
          this.regexValidMsg = "";
        }
      }
    }
  }
};
</script>

