<template>
  <div class="container">
    <page-loader v-if="loader" style="margin-top: 50px"/>
    <div class="table-wrap">
      <div class="individual-result team-result-new team-leader-board">
        <table class="table" id="teamsResultTable" style="width: 100%">
          <thead>
          <tr>
            <th><i class="fas fa-users"></i> TEAM RANK</th>
            <th><i class="fas fa-users"></i> JERSEY</th>
            <th><i class="fas fa-users"></i> CATEGORY</th>
            <th><i class="fas fa-users"></i> TEAM NAME</th>
            <!-- <th scope="col"><i class="fas fa-users"></i> Team Points</th> -->
            <th><i class="fas fa-users"></i> OVERALL TEAM TIME</th>
            <th v-for="column in columns" v-show="isStages && columnNotHidden(column.name)" :key="column.name" :width="column.width ? column.width : ''">
              <span v-html="column.label.toUpperCase()"></span>
            </th>
          </tr>
          </thead>

          <tbody>

            <tr v-for="(team, i) in teamResult" :key="i" v-show="!team.dqStatus" :class="i % 2 !== 0 ? 'even' : 'odd'">
              <!-- <td v-html="team.dqStatus === false ? i + 1:'DQ'"></td> -->
              <td v-html="(i + 1)"></td>


              <td v-html="mapJersey(team.jerseyId)"></td>
              <td>{{ team?.categoryId && team?.categoryId.length > 0
                ? `CAT-${[...new Set(team?.categoryId || [])].join(", ")}`
                : 'OPEN' }}</td>
              <td v-html="team.teamName"></td>
              <!-- <td v-html="team.points"></td> -->
              <td v-html="teamTimeFormat(i, team)"></td>

              <template v-if="team?.players?.length > 0">
                <td :class="{ 'separate-list': column.showBorder }" v-for="(column, k) in columns"
                    v-show="isStages && columnNotHidden(column.name)" :key="k">
                  <ul class="list-inline">
                    <li v-for="(player, j) in team.players" :key="j" class="white-bg" :class="column.name === 'userFullName' && 'jc-l'">

                      <template v-if="column.name === 'rank'">
                        {{ player.selectionStatus || player.rank }}
                          <!-- <template v-if="player.isRemoved === 'false'">
                            <template v-if="player.dissQualify === 'false'">{{ player.selectionStatus }} - {{ player.rank }}</template>
                            <template v-else>{{ player.selectionStatus }}</template>
                          </template>
                          <template v-else>REM</template> -->
                      </template>

                      <template v-else-if="column.type === 'actions'">
                        <!--                    <button type="button" class="btn btn-primary btn-sm">Download</button>-->
                        <!--                    <button type="button" class="btn btn-primary btn-sm">Email</button>-->
                        <button type="button" class="btn btn-primary btn-sm" @click="removePlayer(player)"
                                v-if="!player.isRemoved">Remove Player
                        </button>
                        <button type="button" class="btn btn-secondary btn-sm" @click="removePlayer(player, 'restore')"
                                v-else>Restore Player
                        </button>
                      </template>

                      <template v-else>
                        <template v-if="column.name === 'finishedTime'">{{ formatTime(player[column.name], datetime) }}</template>
                        <template v-else-if="column.name === 'userFullName'">

                          <div v-html="mapCountryFlag(player.userCountryFlag)" class="team-rider-jersey"></div>

                          {{ player[column.name] }}</template>
                        <template v-else>{{ player[column.name] }}</template>
                      </template>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
            <page-loader v-if="loading"/>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import teampointsboards from '@/theme/local-db/team-points-leader-board.json';
import { teamColumns } from '@/shared/events/columns.dto.ts';
import { mapJersey, mapCountryName, formatTime, mapCountryFlag } from '@/shared/events/event.helpers.ts';
import $ from "jquery";

export default {
  props: ['showColumns', 'eventId', 'datetime', 'teamResult', 'isStages', 'isSeriesEvent', 'stage', 'isLive', 'loader'],
  name: 'TeamResultEditPanel',
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
  data() {
    return {
      columns: teamColumns,
      mapJersey: mapJersey,
      mapCountryName: mapCountryName,
      mapCountryFlag: mapCountryFlag,
      formatTime: formatTime,
      teampointsboards: teampointsboards,
      // teamResult: [],
      loading: false,
    }
  },

  async mounted() {
    try {
      await this.getTable();
    } catch (err) {
      console.log(err)
    }
    // this.addBlurryEffect()
  },

  methods: {
    async getTable() {
      $('#teamsResultTable').DataTable({
        // destroy: true,
        paging: false,
        searching: false,
        // responsive: true,
        info: false,
        // scrollY: false,
        scrollX: true,
        preDrawCallback: function () {
          $('#teamsResultTable tbody td').addClass("blurry");
          //$('#resultTable tbody').fadeOut(600);
        },
        drawCallback: function () {
          $('#teamsResultTable tbody td').addClass("blurry");
          $('#teamsResultTable tbody').fadeIn(800);
          setTimeout(function () {
            $('#teamsResultTable tbody td').removeClass("blurry");
            $('.DTFC_LeftBodyLiner tbody td').removeClass("blurry");
          }, 600);
        },
      }).columns.adjust().fixedColumns().relayout();
    },

    teamTimeFormat(i, team) {
      //return team.dqStatus ? "-" : i === 0 ? formatTime(team.finishedTime || 0, this.datetime) : '+' + formatTime(team.gapTime || 0);
      return team.dqStatus ? "-" : formatTime(team.finishedTime || 0, this.datetime);
    },

    columnNotHidden(column) {

      return this.showColumns.includes(column);
    },

    removePlayer(player, type = 'remove') {

      this.$emit('removePlayer', {
        player,
        type,
      })
    },

    // addBlurryEffect() {
    //   $('.individual-result tbody tr').addClass('blurry')
    //
    //   setTimeout(function () {
    //     $('.individual-result tbody tr').removeClass("blurry");
    //   }, 600);
    // }
  }
};
</script>
