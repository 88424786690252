<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}

.main-card {
  background: #ffffff;
  border: 1px solid #e1e1e1;
}

.main-card .div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-wrpr {
  border-bottom: 0px solid #e4e4e4;
  padding: 15px 20px;
  background: #000000;
}

.heading-wrpr .div-flex .heading {
  color: #ffffff !important;
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.p-20 {
  padding: 20px !important;
}

.m-20 {
  margin: 20px;
}

.m-0 {
  margin: 0 !important;
}



.heading-wrpr .div-flex .heading span {
  display: inline-flex;
  margin: 0 4px;
}

.heading-wrpr .div-flex .select-option select {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 12px;
  border: none;
  background-color: transparent;
}

.main-card .heading-small {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 20px 0;
}



.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-bg-sky {
  background-color: #e6f8fd;
}

.cal-bg-light-pink {
  background-color: #ffe7e7;
}

.cal-icon-text .date {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.cal-icon-text .zone {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  text-align: center;
  color: #000000;
}

.cal-wrpr .div-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.record-text table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0px 1rem;
}

.record-text table tr td {
  text-align: center;
  padding: 7px;
}

.record-text table tr td.number span {
  background: #91c951;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 12px 17px;
}

.record-text table tr td.name span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.0125em;
  background-color: #4f4f4f;
  color: #ffffff;
  padding: 12px 17px;
  opacity: 0.7;
  border-radius: 50%;
}

.bg-grn {
  background: #91c951;
}

.text-clr-white {
  color: #ffffff;
}



.card-btn button {
  background: #fcb913;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 12px 12px;
  margin: 20px auto;
  border: none;
}

.card-btn button:hover {
  background-color: #000;
  color: #fff;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
}

.txt-lft {
  text-align: right;
}

.txt-rgt {
  text-align: right;
}

.img-race {
  text-align: center;
  padding: 0px 0;
  height: 180px;
  width: 180px;
  margin-left: 18px;
  margin-right: 58px;
}

.img-race img {
  height: 100%;
}

.multiStageLeftSide .stage-wrpr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0;
}


.multiStageLeftSide .stage-wrpr .stage-wrpr-count {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #000000;
}

.stage-wrpr {
  padding: 20px 0px;
  border-top: 1px solid #e4e4e4;
  margin: 0;
  background: #F9F9F9;
  position: relative;
  height: 220px;
  overflow: hidden;
}

.stage-wrpr::before {
  content: "";
  height: 1px;
  background-image: linear-gradient(to right,
      rgba(255, 0, 0, 0),
      rgba(228, 228, 228, 1),
      rgba(255, 0, 0, 0));
}

.stage-wrpr:hover {
  background-color: #E8E8E8;
}

.stage-wrpr .div-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.stage-icon-text {
  text-align: center;
  padding: 18px;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  height: 100%;
}

.stage-icon-image {
  margin-top: 35px;
}

.multiStageLeftSide .main-card .heading-wrpr {
  background: #000;
  margin-top: 2px;
}

.multiStageLeftSide .main-card .heading-wrpr .heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.stage-icon-text .date {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.stage-icon-text .zone {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  text-align: center;
  color: #000000;
}

.stage-wrpr .lbl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  display: inline-block;
  align-items: center;
  text-align: center;
  background-color: #00b4e3;
  color: #ffffff;
  padding: 5px 22px;
  margin: 12px 0;
}

.stage-wrpr .lbl+img {
  margin: 0px 16px;
  vertical-align: middle;
  padding: 0px;
  margin-top: -5px;
}

.stage-wrpr .stage-heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0px;
}

.stage-wrpr .stage-description {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  color: #000000;
}

.stage-heading img {
  margin: 0 8px;
}

.stage-wrpr .stage-dis {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  color: #000000;
}

.stage-wrpr .stage-buttons {
  flex-grow: 1 !important;
  text-align: left;
}

.stage-wrpr .stage-buttons a {
  background: #fcb913;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 12px 20px;
  margin: 20px auto;
  border: none;
  min-width: 149px;
  text-decoration: none;
}




.event_status_official {
  width: 20px;
  height: 22px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPoSURBVHgBlVRbaBxVGP7OzOzOZm+ZbUKxSUh3SQtW0VYQvPVh40vBF+ONig9q8EJ9ampBhBYTEUQUjQUfSl+aB0FqpQkqQimSItSIFkwjiZrUzsYkpsXE3Ww2u7NzOcf/zGw2m5Ra/MOXOec///n+61mG28iAMI0IYv2CC6OqlI8MsEzhv+zZTQQVM61FdOFaVaZqWlpVtNNXeTUtLbtYOKcqao8LryDPpd1x1jZ7S8KBymJa08S1Mvfq+iuijEVhgzGGTqajCzrCtWtRpsAN2anGqLVGQgVO1nRtdtlbC67QPyE2vF5DGSZB+DuB+9U4dvLIi7T5eIOjQcjwhXnHAvc8eB4l5nq1dYD62nVpzWG6FXLIH98clEw1bxr9JXOw5DnZP51y7SLfIK6TBKRxznz9MjkvuXb2rTWzv17DN/O/psOaPrzg2Xt/dFeYLbgfqqxZY6E55S51SabinHEPRqpL+KQ8jxjV8S41hkyoKeepyCoatPQ/rr3vu8oSsxzHT8WPzA2iktG5BMED/VDzHrSrOnr0FsTId9Gx8YNVAHHsZBa/TznXe7igr5SgUgSc83qdOKdvbb/u4N3ELuzRYljwLDy3NIE8kck7GmWVFAo70/taTpka+ca4PjGFHQgFERHaWBjPR3dArNeOcCyZwVPR7Zh3LTx7YxxzdlBrl87uoLu/f30BMyMX0nJsxl3HKdChIQ0SVITPtu9DhxZBkno2WDDRZ2TQm+hAkbt45cYVzNqlxsaCKx70REwuL7I+82cjHA3lz67+Bb8hJEdSXTjasstfj1XyeKgp5a8PzI1hslqstUnUCcPUqGeSbQV7zc5oaljNLrtVWK7jGwr6+3Bp2p/oo62762R9ixP4pZwPBn3T+2KoCA/LtmVsi4T2qqk7M5HmaPxQqtmgTlmwZSMo0ktrS77xI7EWfPD3NE4t/0Fk5I4Ln5QG2t+HyGp/tBXKbznMXrp8QvrqIQw/+Poh3Hv4JXxV3EhdHj4ca8X3RC5JpILV0hW1WX2yuR3Tpz/H2EcnUS2udsvTNGFUfp8+cwqTd7fjulO5qU7rL1r4Ax5oOkNR7J6cxxcHX5XbccIT8unlCBnC21fPjyKjRYM3vD7YbsOQE/wB9984p0lowuTZLyXZCUK35NpU3qaW1OjLP53Plmg8pNg03FOVFcxYRT/YTj2GB+Kt9a7EacQ+PXDwYn7G7N6cx4b0JTp2DK5v9EQcj518H3PbmvxUO/MWvn3jHRQXFusXVucXe+kzhFuIUStcHXoyLh5977jYf6xP6ImE2Hpe68GWSm+W9BYHww26HKG39kWD7n+JJB1C8Kts3M74X1OlLQcie0ApAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  top: 1px;
  position: relative;
  margin: 2px auto;
  display: inline-block;
}


.event_status_unofficial {
  display: block;
  width: 20px;
  height: 22px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKZSURBVHgBlVS7bhpREJ3F64f8XGQ3toUCDR1y8gXAF8QpqRJLSIgGnJrCcUFBlaSDKu4pKJAAQYH5AvwDhI0EoiM8xEO8NnPWXLRsDNgjjWZ37r1nzjzulWiDVKtV5fj4+E7TNGVra+ur1WptrdsvmR2NRsO+t7enDYdDrNkZ5NdoNLJjbXt7W2VzvbOz08I69nGAPysBAWaxWH5Pp9OFfzAY0GQyoTkg7e7ukiQ9L/NerFsdDseCtWwEZCAPIvf7fXpJAIwAQg4ODhDgC3/+ED6L6cxnBgSwfhjWqMInLAKz/fhfyig807+bzWa3zWZTT4WboKcGu0qwrigKybL8zWaz3es+BrOzTY3H46tOpyMBQIAZxegzfiP4/v4+cYNUTt8jM307M3sPZuvECGYWngI6PT19x+l/sITD4Vav1yMGXVkrKGew5BeKf5zlgFIwGFTlTCaj+Hw+cjqdSx10u920SUqlks4YKefzeSoUCnaMzRNHb3EkRczba0XsB8vDw0N8Pkrlclnhyf9br9fXdnSdoDEXFxctrqVD5quFYV5EM4Kua4QYKVicZQyFb9KVnMvlVJfLRScnJ9Rut/UCv4Up2HGHqVKpkKqqbVC4Zk0FAgHy+/2E1OddWzponkPB8vLykpLJJMXjcep2u179RWEtwiYSCdQC9FcyMgKju7VajUCG5Yn1E+6yyupgvS8Wi/omMV8vzR7YCx/fDkqn0wD7yeoF1lJe/LYVs9msxzgOqCtfSf0fr8vZ2dmCId9h4hl+5OvrFRjmB/b2/Pz8u/jBbMViMZ0JUkUpotEo3s3FAf6+YfNAK0RBmYzKoFokEtFCoZB2dHSkmdfnPaBVDMm0AQFSBp/KejO3ZPC9SQD6QM+vsrJp8z8EH6620aNI2gAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  top: 1px;
  position: relative;
  margin: 2px auto;
  display: inline-block;
}


.sectionHeadingMain {
  font-family: 'Thunder';
  font-size: 80px;
  line-height: 100px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 35px;
  padding-top: 45px;
}


.section-card.grey {
  background: #F2F2F2;
  margin: 70px 0;
  padding: 40px 0;
}

.stage-detail {
  flex: 1;
}
</style>
<style scoped>
.multiStageLeftSide {
  background: #ffffff;
}


@media screen and (max-width: 1920px) {
  .small-bld-blk:before {
    left: 28%;
  }

  .small-bld-blk:after {
    right: 28%;
  }
}

@media screen and (max-width: 1366px) {
  .small-bld-blk:before {
    left: 20%;
  }

  .small-bld-blk:after {
    right: 20%;
  }

  .section-wrap {
    padding: 12px 12px;
  }

  .event-more {
    background: url('../../../../public/themeV2/assets/images/hero-slider-v1.jpg');
    min-height: 38vh;
  }
}

@media screen and (max-width: 1200px) {
  .small-bld-blk:before {
    left: 30%;
  }

  .small-bld-blk:after {
    right: 30%;
  }

  .small-bld-blk {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg {
    font-size: 28px;
    line-height: 33px;
  }

  .heading-med {
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1024px) {
  .sectionHeadingMain {
    font-size: 68px;

  }

  .stage-icon-image img {
    max-width: 29px;
  }

  .cards-sections .card {
    width: 100%;
  }

  .stage-icon-image {
    margin-top: 0;
  }

  .stage-wrpr {
    height: 180px;
  }

  .stage-wrpr .stage-buttons a {
    background: #fcb913;
    font-family: "Inter" !important;
    font-style: normal;

    font-size: 16px;

    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 7px 13px;
    margin: 0;

    min-width: auto;

  }

  .stage-wrpr .div-flex {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }

  .stage-wrpr .stage-heading {
    font-size: 15px;
  }

  .img-race {
    height: 85px;
    margin-right: 18px;
    width: 85px;
  }


}

@media screen and (min-width: 747px) {
  .cards-sections .card-container {
    display: flex;
    flex-wrap: wrap;
  }



  .cards-sections-daily-event .card-container {
    display: flex;
    flex-wrap: wrap;
  }


}

@media screen and (max-width: 991px) {
  .tab-chart button {
    width: 100%;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-blk:before {
    display: none;
  }

  .small-bld-blk::after {
    display: none;
  }

  .btn-regi {
    float: none;
    padding: 28px 25px;
    margin: 0 auto;
    border: none;
    width: 100%;
  }

  .card-box-info ul li {
    width: 100%;
    padding: 20px;
    margin: 5px auto;
  }

  .btn-wrpr button {
    font-size: 14px;
    padding: 18px 26px;
    margin: 4px auto;
    width: 83%;
  }

  .small-bld-text {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg-txt {
    font-size: 32px;
    line-height: 36px;
  }

  .event-wrapper button {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .cards-sections-daily-event .card {
    margin: 8px auto;
    padding: 6px;
    width: 95%;
  }

  .breadcrumb {
    padding: 5px;
    height: 85px;
    width: 100%;
    position: absolute;
    bottom: 2%;
  }

  .tab-regicontent p {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-left {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-right {
    width: 100%;
  }

  .multi-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-flex.btn-back-next .col-right {
    text-align: center;
  }

  .div-flex.btn-back-next .col-left .btn-back {
    width: 90%;
    margin: 6px 0;
  }

  .div-flex.btn-back-next .col-right .btn-next {
    width: 90%;
    margin: 6px 0;
  }

  .section-chart select {
    width: 62%;
  }

  .btn-wrpr {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
  }

  .tab-regi-wrpr h1 {
    margin: 20px 10px 30px 10px;
  }

  .tab-regi button {
    width: 100%;
  }

  .btn-upload {
    display: block;
    text-align: center;
  }

  .btn-upload button {
    margin: 0 auto;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }
}

@media (max-width: 1199.98px) {


  .routes img.intro-arrow-desktop-black {
    float: none;
    margin: 0 auto;
  }

  .div-flex .col-left {
    width: 100%;
  }

  .div-flex .col-right {
    width: 100%;
  }

  .section-wrap {
    padding: 120px 20px;
  }


  .owl-custom table thead tr th {
    font-size: 0.7rem;
  }

  .owl-custom table tbody tr td {
    font-size: 0.7rem;
  }

  .col-right h2 {
    text-align: inherit;
  }


  .main-card .heading-small {
    justify-content: center;
  }

  .card-btn.txt-rgt {
    text-align: center;
  }

  .stage-icon-text {
    margin: 12px;
  }

  .black-bar .div-flex {
    height: auto;
    padding: 12px;
  }

  .black-bar .search .row {
    flex-direction: column;
  }

  .card-box-info-overview ul li {
    width: 95%;
  }

  .live-section {
    height: auto;
  }

  .tab-live-wrpr h1 {
    padding: 12px;
  }

  .tab-live-wrpr {
    margin: 12px;
  }

  .live-tbl .table-outer {
    overflow: scroll;
  }

  .section-main select {
    width: 100%;
    margin: 5px auto;
  }

  .section-main select.sel-cutom {
    width: 100%;
    margin: 5px auto;
  }

  .list-indivi {
    width: 96%;
    margin: 12px;
  }

  .list-indivi ul {
    flex-direction: column;
  }

  .list-indivi ul li {
    padding: 11px;
    display: inline-block;
    width: 100%;
  }

  .sprint-btn a {
    margin: 2px auto;
  }

  .rank-tbl .table-outer {
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .section-card .col-10 {
    padding-right: 0px;
    padding-left: 0px;
  }



  .multiStageLeftSide {
    display: none;
  }

  .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .stage-wrpr .stage-heading {
    font-size: 16px;
  }

  .stage-wrpr .stage-buttons a {

    margin: 8px auto;
    min-width: 90px;
  }


  .img-race {
    width: 100% !important;
    height: auto;

    margin-right: 18px;
    margin: 0 auto;
  }



  .disclaimer-img {
    padding: 24px 10px 0px !important;
  }

  .disclaimer-img img {
    width: 55px;
  }

  .disclaimer-section .d-flex {
    padding-right: 0px;
  }
}


@media screen and (max-width: 640px) {
  .stage-wrpr {
    height: auto;
    padding-bottom: 0;
  }

  .stage-wrpr .stage-description {
    justify-content: center;
  }

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B1B1B1;
    padding: 10px;
  }

  .stage-wrpr .stage-buttons {
    text-align: center;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;

  }

  .stage-wrpr .stage-heading {
    justify-content: center;
    margin-top: 0;
  }

  .stage-icon-image {
    margin-top: 35px;
  }
}

@media screen and (max-width: 768px) {
  .stage-wrpr {
    height: 100% !important;
  }

  .sectionHeadingMain {
    margin-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .stage-icon-text {
    position: relative !important;
  }

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B1B1B1;
    padding: 10px;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    height: auto;
    width: 100% !important;
    margin: 0 auto;
  }


  .stage-wrpr .stage-heading {
    font-size: 20px;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;

  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {

  .sectionHeadingMain {
    font-family: 'Thunder' !important;
  }


  .img-race {

    width: 100% !important;
    margin: 0 auto;
  }


  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;

  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }
}

.disclaimer-img {
  background: #fcb913;
  padding: 30px;
}

.disclaimer-text {
  color: #ffffff;
  background: #000000;
  border-right: 20px solid #fcb913;
  padding: 22px;
}

.disclaimer-text strong {
  font-size: 20px;
}
</style>


<template>
  <div class="section-card grey">
    <div class="container">
      <h1 class="sectionHeadingMain p-0 mb-2">Multistage</h1>

      <div class="row mr-0 ml-0 disclaimer-section mb-4">
        <div class="col-12 pl-0 d-flex">
          <div class="disclaimer-img">
            <img src="/assets/images/disclaimer_ico.png" />
          </div>
          <div class="disclaimer-text">
            <strong>DISCLAIMER</strong>
            <p>These are the preliminary results. We take fair play seriously, that is why we are currently running the ultimate verification process, which may take up to two weeks. Once completed, we will release the overall final results.</p>
          </div>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-2 p-0 multiStageLeftSide text-center">
          <div class="main-card" v-for="item in eventsList" :key="item._id">
            <div class="heading-wrpr">
              <div class="heading">
                STAGE
              </div>
            </div>
            <div class="stage-wrpr" v-for="(stage, indx) in item.ListOfDays" :key="stage.DayId"
                 :class="item.isSeriesEvent && type === 'normalEvents' ? 'card-heading-bg' : ''"
                 v-show="stage.StartTime <= getDateTime()">
                      <span class="stage-wrpr-count">
                        {{ indx + 1 }}
                      </span>
            </div>
          </div>
        </div>
        <div class="col-10 multiStageRightSide">
          <div class="main-card" v-for="item in eventsList" :key="item._id">
            <div class="heading-wrpr">
              <div class="div-flex">
                <div class="heading">
                  {{ item.EventName + " | " + item.ListOfDays.length + " STAGES" }}
                </div>
                <!-- <div class="select-option">

                  <strong> {{ item.EventsData[0]?.event_category?.toUpperCase() }}</strong>
                </div> -->
              </div>
            </div>

            <div class="stage-wrpr" v-for="stage in item.ListOfDays" :key="stage.DayId"
                 :class="item.isSeriesEvent && type === 'normalEvents' ? 'card-heading-bg' : ''"
                 v-show="stage.StartTime <= getDateTime()">
              <div class="div-flex">
                <!-- <div class="stage-icon-text cal-bg-sky">
                  <p>
                    <img
                      src="../../../../public/themeV2/assets/images/cal-icon-sky.png"
                    />
                  </p>
                  <div class="date">{{ stage.StartDate }}</div>
                  <div class="zone">{{ stage.StartDateTime }} GST</div>
                </div> -->
                <div class="img-race" v-html="mapEventImage(item?.image, '', '', '100%')"></div>
                <div class="stage-detail">

                  <!-- <p class="lbl"> Stage {{ stage.DayNo }}</p>    <span :class="item.EventsData[0]?.result_status != 'official' ? 'event_status_unofficial' : 'event_status_official'" ></span> -->
                  <p class="stage-heading">{{ stage.DayName }} </p>
                  <!--<p class="stage-description">Lonely Castle and Canyon Peaks</p>-->
                  <p class="stage-description"> Elevation: {{ stage.Elevation }} | Distance: {{ stage.TotalDistance }}
                    KM </p>
                  <div class="stage-buttons">
                    <router-link
                        :to="{ name: 'EventResultIndex', query: { eventId: item.EventId, dayId: stage.DayId, isLive: stage.ExpiresAt >= getDateTime() } }"
                        class="see-results-btn">
                      {{ stage.ExpiresAt >= getDateTime() ? 'LIVE' : 'VIEW' }} RESULT
                    </router-link>
                    <img v-if="item?.OfficialStatuses?.includes(stage.DayId)" class="ml-4"
                         src="../../../../public/assets/officalBadge.svg"/>
                    <img v-else src="../../../../public/assets/unofficalBadge.svg" class="ml-4"/>

                  </div>
                </div>


                <div class="stage-icon-text cal-bg-sky">
                  <p class="stage-icon-image">
                    <template v-if="mapUserGenderColor(item?.Gender).gender === 1">
                      <img src="../../../../public/assets/women-icon.png"/>
                    </template>
                    <template v-else>
                      <img src="../../../../public/assets/psotIconBlue.svg"/>
                    </template>
                  </p>
                  <div class="date">{{ stage.StartDate }}</div>
                  <div class="zone">{{ stage.StartDateTime }} GST</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { getDateTime, mapEventImage, mapUserGenderColor } from "@/shared/events/event.helpers.ts";

export default {
  name: "SeriesEventsList",
  props: ['eventsData'],

  watch: {
    eventsData: {
      handler: function (values) {
        this.eventsList = values || [];
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      mapUserGenderColor: mapUserGenderColor,
      mapEventImage: mapEventImage,
      getDateTime: getDateTime,
      eventsList: this.eventsData || [],
    }
  },
  mounted() {
  }
}
</script>