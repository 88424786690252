/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
// @ts-ignore
import Service from "@/services/http.ts";

export default {
  namespaced: true,

  state: {
    events: [],
    event: {},
    eventResult: [],
    isLiveResult: false,
    gateList: { days: [], overall_gates: [] },
    showResultColumns: [],
    teamResults: [],
    currentStage: 1,
    stage: false,
    rewardJerseys: [],
    elevationValues: [],
    eliEventPlayersInfo: []
  },

  getters: {
    events: ({ events }) => events,
    event: ({ event }) => event,
    getEventResult: ({ eventResult }) => eventResult,
    isLiveResult: ({ isLiveResult }) => isLiveResult,
    gateList: ({ gateList }) => gateList,
    showResultColumns: ({ showResultColumns }) => showResultColumns,
    teamResults: ({ teamResults }) => teamResults,
    rewardJerseys: ({ rewardJerseys }) => rewardJerseys,
    elevationValues: ({ elevationValues }) => elevationValues,
    eliEventPlayersInfo: ({ eliEventPlayersInfo }) => eliEventPlayersInfo
  },

  actions: {
    async getEvents({ commit }, { isLive, filters }) {
      try {
        return await Service()
          .post(`public/results/event-list`, filters)
          .then((res) => {
            const data = res?.data || [];
            if (isLive && data.length > 0) {
              commit("SET_EVENT_RESULT_LIVE", true);
            }
            commit("SET_EVENTS", data);
            return data;
          });
      } catch (e) {
        commit("SET_EVENTS", []);

      }
    },


    async getAllEvents({ commit }, { isLive, filters }) {
      try {
        return await Service()
          .post(`public/results/get-events-list`, filters)
          .then((res) => {
            const data = res?.data || [];
            if (isLive && data.length > 0) {
              commit("SET_EVENT_RESULT_LIVE", true);
            }
            commit("SET_EVENTS", data);
            return data;
          });
      } catch (e) {
        commit("SET_EVENTS", []);

      }
    },

    //FOR NEW PAGE DESIGN
    async getSrcEventsList({ commit }, { filters }) {
      try {
        return await Service()
          .post(`public/results/get-src-events`, filters)
          .then((res) => {
            const data = res?.data || [];
            commit("SET_EVENTS", data);
            return data;
          });
      } catch (e) {
        commit("SET_EVENTS", []);

      }
    },

    async getLiveEvents({ commit }, { filters }) {

      try {
        return await Service().post('public/results/get-live-events', filters).then(res => {
          // commit('SET_EVENT_RESULT', res.data);
          return res;
        });
      }
      catch (e) {
        // commit('SET_EVENT_RESULT', []);
        return e

      }
    },

    async getEventPoints({ commit }, payload) {
      try {
        return await Service().post('/result/get-event-points', payload);
      } catch (e) {
        return e
      }
    },


    // POINTS RELATED
    async setEventPoints({ commit }, payload) {
      try {
        return await Service().post('/result/set-event-points', payload);
      } catch (e) {
        return e
      }
    },

    async removeEventPoints({ commit }, payload) {
      try {
        return await Service().post('/result/remove-event-points', payload);
      } catch (e) {
        return e
      }
    },

    async getSelectedColumns({ commit }, { eventId }) {
      try {
        commit("SET_SHOW_RESULT_COLUMNS", []);
        return await Service().get(`public/result/get-columns/${eventId}`);
      } catch (e) {
        commit("SET_SHOW_RESULT_COLUMNS", []);

        return e
      }
    },

    //this will also work for current stage status also full event status
    async publishEventResult({ commit }, payload) {
      try {
        return await Service(true)
          .post("result/change-status", payload)
          .then((res) => {
            commit("PUBLISH_EVENT_RESULTS", res.data);
            return res;
          });
      } catch (e) {
        return e
      }
    },

    async updateShowColumns({ commit }, payload) {
      try {
        return await Service(true)
          .post("/result/show-or-hide-columns", payload)
          .then((res) => {
            commit("SET_SHOW_RESULT_COLUMNS", res.data);
            return res;
          });
      } catch (e) {
        commit("SET_SHOW_RESULT_COLUMNS", []);
      }
    },

    async uploadTeamsResult(data, payload) {
      try {
        return await Service(true)
          .post("teams/upload-result", payload)
          .then((res) => {
            return res;
          });
      } catch (e) { }
    },

    async getGateList({ commit }, { eventId }) {
      try {
        commit("SET_GATE_LIST", []);
        return await Service()
          .get(`public/event-gates/${eventId}`)
          .then((res) => {
            const data = res.data || [];
            commit("SET_GATE_LIST", data);
            return data;
          });
      } catch (e) {
        commit("SET_GATE_LIST", []);
        return e
      }
    },

    //this will be use for admin and web user result call
    async getEventResult({ commit }, payload) {
      try {
        return await Service().post('public/result/getEventResult', payload).then(res => {
          // commit('SET_EVENT_RESULT', res.data);
          return res;
        });
      }
      catch (e) {
        // commit('SET_EVENT_RESULT', []);

      }
    },


    // /v2/public/result/get-event-user-list?eventId=cd4e5caf-251c-4335-8e24-f7001e70b067

    async getEliminationEventPlayersInfo({ commit }, eventId) {
      try {
        return await Service()
          .get('public/result/get-event-user-list', { params: { eventId } })
          .then((res) => {
            commit("SET_ELI_EVENT_PLAYERS_INFO", res?.data || []);
            return res?.data || [];
          });
      } catch (error) {
        commit("SET_ELI_EVENT_PLAYERS_INFO", []);
      }
    },


    async getEvent({ commit }, eventId) {
      try {
        commit("SET_EVENT", {});
        return await Service()
          .get(`public/event/${eventId}`)
          .then((res) => {
            const { data } = res
            commit("SET_EVENT", data || {});
            return data || {};
          });
      } catch (e) {
        commit("SET_EVENT", {});
      }
    },

    async getTeamResults({ commit }, payload) {
      try {
        return await Service()
          .post("public/result/teams", payload)
          .then((res) => {
            commit("SET_TEAM_RESULT", res.data);
            return res;
          });
      } catch (e) {
        commit("SET_TEAM_RESULT", []);

      }
    },

    async getOverallTeamResults({ commit }, payload) {
      try {
        return await Service()
          .post("public/result/teams/overall", payload)
          .then((res) => {
            commit("SET_TEAM_RESULT", res.data);
            return res;
          });
      } catch (e) {
        commit("SET_TEAM_RESULT", []);

      }
    },

    async rewardJerseyCorrections({ commit }, payload) {
      try {
        return await Service()
          .post("public/result-data/correct-rewards", payload)
          .then((res) => {
            commit("SET_TEAM_RESULT", res.data);
            return res;
          });
      } catch (e) {

        return {};
      }
    },


    async getRewardJerseys({ commit }, payload) {
      try {
        return await Service()
          .post('admin/jerseys/get-list', payload)
          .then((res) => {
            // commit("SET_REWARD_JERSEYS", res.data);
            return res;
          });
      } catch (e) {

        return {};
      }
    },

    async deleteRewardJersey({ commit }, payload) {
      try {
        return await Service()
          .post('admin/jerseys/delete', payload)
          .then((res) => {
            return res;
          });
      } catch (e) {

        return {};
      }
    },


    async updateRewardJersey({ commit }, payload) {
      try {
        return await Service()
          .post('admin/jerseys/update', payload)
          .then((res) => {
            return res;
          });
      } catch (e) {

        return {};
      }
    },

    async getElevationValues({ commit }, payload) {
      try {
        return await Service()
          .get('public/results/get-live-graph-data', { params: payload })
          .then((res) => {
            const { data } = res;
            commit("SET_ELEVATION_VALUES", JSON.parse(data));
            return res;
          });
      } catch (e) {
        commit("SET_ELEVATION_VALUES", []);
        console.log(e.error)
      }
    },

    async getEventPvpData({ commit }, payload) {
      try {
        return await Service()
          .post('/result/get-event-pvp-data', payload)
          .then((res) => {
            return res;
          });
      } catch (e) {
        console.log(e.error)
      }
    },

  },

  mutations: {
    SET_EVENTS: (state, payload) => (state.events = payload),
    SET_EVENT: (state, payload) => (state.event = payload),
    // SET_EVENT_RESULT: (state, payload) => (state.eventResult = payload),
    // SET_EVENT_RESULT_LIVE: (state, payload) => (state.isLiveResult = payload),
    SET_GATE_LIST: (state, payload) => (state.gateList = payload),
    SET_SHOW_RESULT_COLUMNS: (state, payload) =>
      (state.showResultColumns = payload),
    SET_TEAM_RESULT: (state, payload) => (state.teamResults = payload),
    SET_REWARD_JERSEYS: (state, payload) => (state.rewardJerseys = payload),
    SET_EVENT_CURRENT_STAGE: (state, payload) => (state.currentStage = payload),
    SET_EVENT_STAGE: (state, payload) => (state.stage = payload),
    SET_ELEVATION_VALUES: (state, payload) => (state.elevationValues = payload),
    SET_ELI_EVENT_PLAYERS_INFO: (state, payload) => (state.eliEventPlayersInfo = payload)
  },
};
