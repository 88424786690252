<template>
    <page-heading>Add New Role</page-heading>
    <AdminNavigationVue />
  
    <div class="container">
      <page-loader v-if="loading" />
  
      <Form
        class="player-form"
        @submit="submitHandler"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div id="accordionDiv">
          <ul>
            <li>
              <h2>REQUIRED FIELDS (*)</h2>
  
              <div class="row">
                <section class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="name">ROLE NAME</label>
  
                    <Field
                      name="name"
                      id="name"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      @change="roleName = $event.target.value"
                      v-model="roleName"
                    />
                    <div class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </section>
  
                <section class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label for="value">VALUE <sup> (Auto generated)</sup></label>
                    <Field
                      name="value"
                      id="value"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.value }"
                      v-model="roleValue"
                      disabled

                    />
                    <div class="invalid-feedback">{{ errors.value }}</div>
                  </div>
                </section>
              </div>
            </li>
  
            <li>
              <!-- <i></i> -->
              <h2>PERMISSIONS (*)</h2>
  
              <div class="row">
                <section class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <h6>Select the related permissions for this role</h6>
                  </div>
                </section>
              </div>
  
              <div class="row col-lg-12 col-md-12">
                <section class="col-lg-4 col-md-2">
                  <div>
                    <h6>USERS MODULE</h6>
                    <Field
                      name="userCreate"
                      id="userCreate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.userCreate }"
                      :value="false"
                    />
                    <span> CREATE</span>
                  </div>
  
                  <div>
                    <Field
                      name="userDelete"
                      id="userDelete"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.userDelete }"
                      :value="false"
                    />
                    <span> DELETE</span>
                  </div>
  
                  <div>
                    <Field
                      name="userRead"
                      id="userRead"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.userRead }"
                      :value="false"
                    />
                    <span> READ</span>
                  </div>
  
                  <div>
                    <Field
                      name="userUpdate"
                      id="userUpdate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.userUpdate }"
                      :value="false"
                    />
                    <span> UPDATE</span>
                  </div>
                </section>
  
                <section class="col-lg-4 col-md-2">
                  <div>
                    <h6>EVENTS MODULE</h6>
                    <Field
                      name="eventCreate"
                      id="eventCreate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.eventCreate }"
                      :value="false"
                    />
                    <span> CREATE</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="eventDelete"
                      id="eventDelete"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.eventDelete }"
                      :value="false"
                    />
                    <span> DELETE</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="eventRead"
                      id="eventRead"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.eventRead }"
                      :value="false"
                    />
                    <span> READ</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="eventUpdate"
                      id="eventUpdate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.eventUpdate }"
                      :value="false"
                    />
                    <span> UPDATE</span>
                  </div>
                </section>
  
                <section class="col-lg-4 col-md-2">
                  <div>
                    <h6>PLAYER MODULE</h6>
                    <Field
                      name="playerCreate"
                      id="playerCreate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.playerCreate }"
                      :value="false"
                    />
                    <span> CREATE</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="playerDelete"
                      id="playerDelete"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.playerDelete }"
                      :value="false"
                    />
                    <span> DELETE</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="playerRead"
                      id="playerRead"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.playerRead }"
                      :value="false"
                    />
                    <span> READ</span> <br />
                  </div>
  
                  <div>
                    <Field
                      name="playerUpdate"
                      id="playerUpdate"
                      type="checkbox"
                      :class="{ 'is-invalid': errors.playerUpdate }"
                      :value="false"
                    />
                    <span> UPDATE</span>
                  </div>
                </section>
              </div>
            </li>
          </ul>
  
          <div class="row">
            <section class="col-lg-3 col-md-6">
              <label class="hide-mobile">&nbsp;</label>
            </section>
  
            <section class="col-lg-3 col-md-6">
              <label class="hide-mobile">&nbsp;</label>
            </section>
  
            <section class="col-lg-3 col-md-6">
              <label class="hide-mobile">&nbsp;</label>
            </section>
  
            <section class="col-lg-3 col-md-6">
              <label class="hide-mobile">&nbsp;</label>
              <button type="submit" class="btn btn-primary">Submit</button>
            </section>
          </div>
        </div>
      </Form>
    </div>
  </template>
  
<style scoped>
body {
  overflow-x: hidden;
}

section.accordionDiv {
  padding-top: 2em;
  padding-bottom: 3em;
}

#accordionDiv ul {
  text-align: left;
}

#accordionDiv h1 {
  color: #fcb913;
  margin-bottom: 30px;
  margin-top: 0;
}

#accordionDiv h2 {
  color: #fcb913;
  font-family: "hm_light", sans-serif;
  font-size: 20px;
  line-height: 34px;
  text-align: left;
  padding: 15px 15px 0;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
}

#accordionDiv div {
  text-align: left;
  font-family: "hm_light", sans-serif;
  font-size: 14px;
  line-height: 1.45;
  position: relative;
  will-change: max-height;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 5px;
  margin-bottom: 9px;
  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  z-index: 2;
  padding-right: 10px;
  padding-left: 10px;
}

#accordionDiv ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}

#accordionDiv ul li {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

#accordionDiv ul li+li {
  margin-top: 15px;
}
</style>
<script>
import { Field, Form } from "vee-validate";

import AdminNavigationVue from '../../../../shared/components/AdminNavigation.vue';
export default {
  name: "AddNewRole",
  components: {
    AdminNavigationVue,
    Form,
    Field,

  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      limit: 10,
      roleName: '',
      roleValue: ''

    }
  },
  methods: {

    process(text) {
      return text.split(' ').join('-');
    }

  },
  watch: {
    roleName(text) {
      this.roleValue = this.process(text);
    },
    roleValue(text) {
      this.roleName = this.process(text);
    }
  }

}
</script>
  