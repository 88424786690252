<template>
  <VueFinalModal
    v-model="isShowModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <span class="modal__title">
      Remove Points
      <br />
      {{ event.name }}
    </span>
    <div class="modal__content">
      <hr />
      <Form
        @submit="submitHandler"
        :validation-schema="schema"
        v-slot="{ errors }"
        ref="removePointsForm"
      >
        <div class="row">
          <section class="col-lg-8 col-md-6">
            <div class="form-group">
              <label
                for="type"
                :class="{ 'is-invalid': errors.removeEntireEventPoints }"
              >- Do you want to remove points for all days and gates?</label>
            </div>
          </section>

          <section class="col-lg-4 col-md-4">
            <div class="form-group form-check">
              <Field
                name="removeEntireEventPoints"
                type="radio"
                value="yes"
                class="form-check-input"
                id="removeEntireEventPoints"
                v-model="removeEntireEventPoints"
              />
              <label class="form-check-label" for="Yes">Yes</label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <Field
                name="removeEntireEventPoints"
                type="radio"
                value="no"
                class="form-check-input"
                id="removeEntireEventPoints"
                v-model="removeEntireEventPoints"
              />
              <label class="form-check-label" for="No">No</label>
              <div class="invalid-feedback">{{ errors.removeEntireEventPoints }}</div>
            </div>
          </section>
        </div>

        <div v-if="removeEntireEventPoints == 'no'">
          <div class="row">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedDay">- Select Day</label>
                <Field
                  id="selectedDay"
                  name="selectedDay"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedDay }"
                  @change="selectDayDropdownReply($event.target.value)"
                  v-model="selectedDayIndex"
                >
                  <option v-for="(item, i) in stages" :value="i" :key="i">{{ item.DayName }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedDay }}</div>
              </div>
            </section>
          </div>

          <div class="row" v-if="selectedDayIndex != null">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to remove points for all gates in this day?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="removeEntireDayPoints"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="removeEntireDayPoints"
                  v-model="removeEntireDayPoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="removeEntireDayPoints"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="removeEntireDayPoints"
                  v-model="removeEntireDayPoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.removeEntireDayPoints }}</div>
              </div>
            </section>
          </div>
          <hr />

          <!-- List of Gates Points Setting -->
          <div class="row" v-if="removeEntireDayPoints == 'no'">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedGate">- Select Leaderboard</label>
                <Field
                  id="selectedGate"
                  name="selectedGate"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedGate }"
                  v-model="selectedGate"
                >
                  <option
                    :value="item.id + '+' + item.lapNo"
                    v-for="(item, i) in selectedDayStages"
                    :key="i"
                  >{{ item.label }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedGate }}</div>
              </div>
            </section>
          </div>
          <!-- End of gate points setting -->
        </div>

        <div
          class="row"
          v-if="removeEntireEventPoints == 'yes' || removeEntireDayPoints == 'yes' || selectedGate != null && selectedDayTotalGates > 0"
        >
          <section class="col-lg-9 col-md-12">
            <div class="form-group">
              <label for="type">
                - Confirm "Delete" to perform this action?
                <sub>(write the word in the box below)</sub>
              </label>
              <br />
              <Field
                name="deleteWordField"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': regexValidMsg }"
                id="deleteWordField"
                v-model="deleteWordField"
              ></Field>
              <p class="invalid-feedback">{{ errors }}</p>
               
            </div>
          </section>

          <section class="col-lg-12 col-md-12">
            <div class="form-group" style="background-color: #fcb913;">
              <p style="padding: 8px">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Make sure you have select the correct options to delete points. Upon submission, this action will not be reverted again.
              </p>
            </div>
          </section>
        </div>

        <hr />

        <div class="row" v-if="removeEntireEventPoints != ''">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn btn-lg float-center" style="width: 140px; font-size: 15px"> Submit  &nbsp; &nbsp;  <i class="fa fa-trash" aria-hidden="true"></i></button>
          </section>
        </div>
      </Form>
    </div>
  </VueFinalModal>
</template>


<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;

  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  color: #fcb913;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {
  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>

<script>
import { Field, Form } from "vee-validate";
import { VueFinalModal } from "vue-final-modal";
import { mapActions } from "vuex";
import * as Yup from "yup";

export default {
  name: "RemovePointsModal",
  props: ["event", "removePointsModal", "stages", "currentStageGates", "totalGateTabs", "isUCIEvent"],
  components: {
    Form,
    Field,
    VueFinalModal
  },

  watch: {
    totalGateTabs: function (value) {
      this.selectedDayTotalGates = value
    },
    currentStageGates: function (values) {
      this.selectedDayStages = values
    },
    stages: function () {
    },
    removePointsModal: function (value) {
      this.isShowModal = value;
    }
  },
  setup() {
    const schema = Yup.object().shape({
      removeEntireEventPoints: Yup.string().required("please choose option"),
      deleteWordField: Yup.string().required("please enter word")
    });
    return {
      schema
    };
  },
  data() {
    return {
      selectedGate: null,
      selectedDayIndex: null,
      selectedDayId: null,
      regexValidMsg: "",
      totalRanks: 0,
      deleteWordField: "",
      removeEntireEventPoints: null,
      removeEntireDayPoints: null,

      isShowModal: this.removePointsModal,
      selectedDayTotalGates: this.totalGateTabs,
      selectedDayStages: this.currentStageGates,

    };
  },
  mounted() {

  },
  methods: {
    ...mapActions("event", ["removeEventPoints"]),
    async submitHandler(formData) {
      this.loading = true

      formData = {
        ...formData,
        eventId: this.event.serverEventId,
        selectedDay: this.stages[this.selectedDayIndex]?.DayId || null,
        removeEntireDayPoints: this.removeEntireDayPoints || 'no',
        isUCIEvent: this.isUCIEvent || false
      };

      const getResponse = await this.removeEventPoints(formData);
      this.isShowModal = false;
      this.$emit("listenerChild", { type: 'remove-points', popup: false })
      this.$refs.removePointsForm.resetForm();


      getResponse.error ? true : setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    handleClose() {
      this.isShowModal = false;
      this.selectedDayStages = []
      this.$emit("listenerChild", { type: 'remove-points', popup: false });
      this.$refs.removePointsForm.resetForm();
    },
    selectDayDropdownReply(selectedDayId) {
      this.selectedDayStages = []
      this.$emit("selectedDayDropdownListener", selectedDayId)
    },

  }
};
</script>

