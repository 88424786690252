// @ts-ignore
import Service from "@/services/http.ts";

export default {
    namespaced: true,

    state: {
        user: {},
        loggedIn: null,
    },

    getters: {
        user: (state) => state.user,
        loggedIn: (state) => state.loggedIn,
    },

    actions: {
        async login({ commit }, payload) {
            try {

                const { data, error } = await Service(true).post('auth/admin/login', payload);
                if (!error) {
                    const user = JSON.stringify(data.user);
                    localStorage.setItem('resultAdminUser', user);
                    localStorage.setItem('resultAdminToken', data.token);

                    commit('SET_USER', JSON.parse(user));
                    commit('SET_LOGGED_IN', true);
                    return true;
                }

                localStorage.removeItem('resultAdminUser');
                localStorage.removeItem('resultAdminToken');

                commit('SET_USER', {});
                commit('SET_LOGGED_IN', false);
                return false;

            } catch (e) {
                commit('SET_USER', {});
                commit('SET_LOGGED_IN', false);
                return false;
            }
        },

        async logout({ commit }) {
            const res = await Service(true).post('auth/admin/logout');
            if (!res?.error) {
                localStorage.removeItem('resultAdminToken');
                localStorage.removeItem('resultAdminUser');
                commit('SET_USER', {});
                commit('SET_LOGGED_IN', false);
            }
            return res
        },

        async loggedIn({ commit }) {
            const token = localStorage.getItem('resultAdminToken');
            const user = localStorage.getItem('resultAdminUser');
            if (token && user) {
                commit('SET_LOGGED_IN', true);
                commit('SET_USER', JSON.parse(user));
                return;
            }
            commit('SET_USER', {});
            commit('SET_LOGGED_IN', false);
        },
    },

    mutations: {
        SET_USER: (state, payload) => state.user = payload,
        SET_LOGGED_IN: (state, payload) => state.loggedIn = payload,
    }
}
