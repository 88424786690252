export const EventFormatType = {
    TIME_BASED: "TIME_BASED",
    POINTS_BASED: "POINTS_BASED",
    TIME_POINTS_BASED: "TIME_POINTS_BASED",
}

export const javaActionCodesEnum = {
    segEnd: 8013,
    dayEnd: 8011,
    dayStart: 8010
}