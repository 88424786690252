import Service from '@/services/http.ts';

export default {
  namespaced: true,

  state: {
    loading: false,
    mainLoading: false,
    temp_key: null,
    cacheData: null,
  },

  getters: {
    getLoading(state) {
      return state.loading;
    },

    getMainLoading(state) {
      return state.mainLoading;
    },

    getTempKey(state) {
      return state.temp_key
    },

    getCacheData(state) {
      return state.cacheData
    }
  },

  actions: {
    async getCacheData({ commit }) {
      try {
        return await Service().get(`static`).then(res => {
          commit('SET_CACHE_DATA', res.data);
        });
      } catch (e) {
        commit("SET_ERRORS", e.errors, { root: true });

      }
    },

    async generateKey({ commit }, payload) {
      try {
        return await Service().post(`key/new`, payload).then(res => {
          if (res.status) {
            commit('SET_TEMP_KEY', res.data);
          }
        });
      } catch (e) {
        global.app.$store.commit("SET_ERRORS", e.errors, { root: true });

      }
    },

    async sendNotifcationsFCM({ commit }, payload) {
      try {
        return await Service().post(`/push-notification/send`, payload).then(res => {
          return res;
        })
      } catch (e) {
        return e;
      }
    },

    async getSearchUsersList({ commit }, payload) {
      try {
        return await Service().post(`/search-users-list`, payload).then(res => {
          return res;
        })
      } catch (e) {
        return e;
      }
    },


  },



  mutations: {
    SET_LOADING(state, status) {
      state.loading = status;
    },

    SET_MAIN_LOADING(state, status) {
      state.mainLoading = status;
    },

    SET_TEMP_KEY(state, payload) {
      state.temp_key = payload;
    },

    SET_CACHE_DATA(state, payload) {
      state.cacheData = payload;
    },
  }
}
