<template>
    <div v-if="elevationValues.length !== 0 || test" class="pb-4" id="p5Canvas"></div>
</template>

<script>
import { LiveGraphCanvas } from "./chart";
import { mapActions, mapGetters } from "vuex";
import elevationTestValues from "./elevationValues.json"; //for test data only

export default {
  name: "LiveEventCanvas",
  props: ["spectatorPlayer", "selectedDayId", "test"],
  computed: {
    ...mapGetters("player", ["playersRideData"]),
    ...mapGetters("event", ["event", "elevationValues"]),
  },
  data() {
    return {
      eventId: this.$route.query.eventId || null,
      lPlayer: null,
      rideData: [],
    };
  },
  async mounted() {
    this.rideDataTopic = `event:ride:${this.eventId}`;

    if (this.test) {
      await this.drawRidersData(100, 5000);
      await this.loadGraph();
    } else {
      await this.getElevationValues({
        eventId: this.event.serverEventId,
        routeId: this.event.currentDayRouteId,
      });
      if (this.elevationValues.length !== 0) {
        await this.loadGraph();
        await this.getRiderData();
      }
    }
  },
  watch: {
    spectatorPlayer(player) {
      this.lPlayer = player;
    },
  },
  methods: {
    ...mapActions("player", ["getLiveRidersData"]),
    ...mapActions("event", ["getElevationValues"]),

    async loadGraph() {
      const Chart = require("p5");
      new Chart((sk) =>
        LiveGraphCanvas(sk, () => ({
          eventData: this.event,
          rideData: this.rideData,
          // rideDataTopic: this.rideDataTopic,
          spectatorPlayer: this.lPlayer,
          // spectatorPlayer: { userId: "user-10" },
          elevationValues: this.test
            ? elevationTestValues
            : this.elevationValues,
          distance:
            this.event.routeDistances.find(
              (x) => x.DayId === this.selectedDayId
            )?.distance || 0,
        }))
      );
    },

    drawRidersData(countUsers = 10, duration = 5000) {
      // Function to generate a random number between min (inclusive) and max (exclusive)
      function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }

      // Array to store distances for each rider
      var distances = [];

      // Generate random distances for 100 riders
      for (var i = 0; i < countUsers; i++) {
        // Generate random speed between 10 and 30 km/h
        var speed = getRandomArbitrary(100, 150);
        // Initialize distance to 0
        var distance = 0;
        // Calculate time using distance = speed * time, rearranging to time = distance / speed
        var time = 0;
        // Store the distance, speed, and time for this rider
        distances.push({
          speed: speed,
          time: time,
          Elevation: 0,
          OldDistance: 0,
          NewDistance: distance,
          UserId: `user-${i + 1}`,
        });
      }

      // Call the updateDistances function every 5 seconds
      setInterval(() => {
        // Update distance for each rider
        distances.forEach(function (rider) {
          // update old distance
          (rider.OldDistance = rider.NewDistance),
            // Increment distance based on speed
            (rider.NewDistance += (rider.speed / 3600) * 5); // Convert speed from km/h to km/s
          // Update time
          rider.time += 5;
        });
        // Output the updated distances
        // console.log(distances);

        this.rideData = distances;
      }, duration);
    },

    getRiderData() {
      setInterval(async () => {
        const res = await this.getLiveRidersData({ eventId: this.eventId });
        this.rideData = res.data;
      }, 5000);
    },
  },
};
</script>
