<template>
  <div>
    <input
      type="text"
      class="form-control"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
  </div>
</template>

<script>
import { formatTime } from "@/shared/events/event.helpers.ts";

export default {
  props: ["value", "eventTime"],
  data: function () {
    return {
      isInputActive: false,
    };
  },
  computed: {
    // :todo need to refator this
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString();
        } else {
          // User is not modifying now. Format display value for user interface
          return formatTime(parseInt(this.value.toString()), this.eventTime);
        }
      },
      set: function (modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
