<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }

  .event_status_official {
    width: 20px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPoSURBVHgBlVRbaBxVGP7OzOzOZm+ZbUKxSUh3SQtW0VYQvPVh40vBF+ONig9q8EJ9ampBhBYTEUQUjQUfSl+aB0FqpQkqQimSItSIFkwjiZrUzsYkpsXE3Ww2u7NzOcf/zGw2m5Ra/MOXOec///n+61mG28iAMI0IYv2CC6OqlI8MsEzhv+zZTQQVM61FdOFaVaZqWlpVtNNXeTUtLbtYOKcqao8LryDPpd1x1jZ7S8KBymJa08S1Mvfq+iuijEVhgzGGTqajCzrCtWtRpsAN2anGqLVGQgVO1nRtdtlbC67QPyE2vF5DGSZB+DuB+9U4dvLIi7T5eIOjQcjwhXnHAvc8eB4l5nq1dYD62nVpzWG6FXLIH98clEw1bxr9JXOw5DnZP51y7SLfIK6TBKRxznz9MjkvuXb2rTWzv17DN/O/psOaPrzg2Xt/dFeYLbgfqqxZY6E55S51SabinHEPRqpL+KQ8jxjV8S41hkyoKeepyCoatPQ/rr3vu8oSsxzHT8WPzA2iktG5BMED/VDzHrSrOnr0FsTId9Gx8YNVAHHsZBa/TznXe7igr5SgUgSc83qdOKdvbb/u4N3ELuzRYljwLDy3NIE8kck7GmWVFAo70/taTpka+ca4PjGFHQgFERHaWBjPR3dArNeOcCyZwVPR7Zh3LTx7YxxzdlBrl87uoLu/f30BMyMX0nJsxl3HKdChIQ0SVITPtu9DhxZBkno2WDDRZ2TQm+hAkbt45cYVzNqlxsaCKx70REwuL7I+82cjHA3lz67+Bb8hJEdSXTjasstfj1XyeKgp5a8PzI1hslqstUnUCcPUqGeSbQV7zc5oaljNLrtVWK7jGwr6+3Bp2p/oo62762R9ixP4pZwPBn3T+2KoCA/LtmVsi4T2qqk7M5HmaPxQqtmgTlmwZSMo0ktrS77xI7EWfPD3NE4t/0Fk5I4Ln5QG2t+HyGp/tBXKbznMXrp8QvrqIQw/+Poh3Hv4JXxV3EhdHj4ca8X3RC5JpILV0hW1WX2yuR3Tpz/H2EcnUS2udsvTNGFUfp8+cwqTd7fjulO5qU7rL1r4Ax5oOkNR7J6cxxcHX5XbccIT8unlCBnC21fPjyKjRYM3vD7YbsOQE/wB9984p0lowuTZLyXZCUK35NpU3qaW1OjLP53Plmg8pNg03FOVFcxYRT/YTj2GB+Kt9a7EacQ+PXDwYn7G7N6cx4b0JTp2DK5v9EQcj518H3PbmvxUO/MWvn3jHRQXFusXVucXe+kzhFuIUStcHXoyLh5977jYf6xP6ImE2Hpe68GWSm+W9BYHww26HKG39kWD7n+JJB1C8Kts3M74X1OlLQcie0ApAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    top: 1px;
    position: relative;
    margin: 2px auto;
  }

  .event_status_unofficial {
    display: block;
    width: 20px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKZSURBVHgBlVS7bhpREJ3F64f8XGQ3toUCDR1y8gXAF8QpqRJLSIgGnJrCcUFBlaSDKu4pKJAAQYH5AvwDhI0EoiM8xEO8NnPWXLRsDNgjjWZ37r1nzjzulWiDVKtV5fj4+E7TNGVra+ur1WptrdsvmR2NRsO+t7enDYdDrNkZ5NdoNLJjbXt7W2VzvbOz08I69nGAPysBAWaxWH5Pp9OFfzAY0GQyoTkg7e7ukiQ9L/NerFsdDseCtWwEZCAPIvf7fXpJAIwAQg4ODhDgC3/+ED6L6cxnBgSwfhjWqMInLAKz/fhfyig807+bzWa3zWZTT4WboKcGu0qwrigKybL8zWaz3es+BrOzTY3H46tOpyMBQIAZxegzfiP4/v4+cYNUTt8jM307M3sPZuvECGYWngI6PT19x+l/sITD4Vav1yMGXVkrKGew5BeKf5zlgFIwGFTlTCaj+Hw+cjqdSx10u920SUqlks4YKefzeSoUCnaMzRNHb3EkRczba0XsB8vDw0N8Pkrlclnhyf9br9fXdnSdoDEXFxctrqVD5quFYV5EM4Kua4QYKVicZQyFb9KVnMvlVJfLRScnJ9Rut/UCv4Up2HGHqVKpkKqqbVC4Zk0FAgHy+/2E1OddWzponkPB8vLykpLJJMXjcep2u179RWEtwiYSCdQC9FcyMgKju7VajUCG5Yn1E+6yyupgvS8Wi/omMV8vzR7YCx/fDkqn0wD7yeoF1lJe/LYVs9msxzgOqCtfSf0fr8vZ2dmCId9h4hl+5OvrFRjmB/b2/Pz8u/jBbMViMZ0JUkUpotEo3s3FAf6+YfNAK0RBmYzKoFokEtFCoZB2dHSkmdfnPaBVDMm0AQFSBp/KejO3ZPC9SQD6QM+vsrJp8z8EH6620aNI2gAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    top: 1px;
    position: relative;
    margin: 2px auto;
  }


}

@font-face {
  font-family: 'Thunder';
  src: url("../../../../public/themeV2/custom-fonts/Thunder-ExtraBoldLC.ttf");
}

@font-face {
  font-family: "ThunderExtraBold";
  src: url("../../../../public/themeV2/custom-fonts/Thunder-ExtraBoldLC.ttf");
}

.recentPosts {
  margin: 15px 0;
}


.sectionHeadingMain {
  font-family: 'Thunder';
  font-weight: 800;
  font-size: 80px;
  line-height: 100px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 35px;
  padding-top: 45px;
}

.div-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-flex .col-left {
  width: 50%;
  height: auto;
  margin: auto;
  z-index: 1;
}

.div-flex .col-right {
  width: 50%;
  height: auto;
  margin: auto;
}

h1.card-lgr-heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  padding: 0 12px;
}

.main-card {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  margin: 35px 10px 70px 10px;
}

.card-bg {
  background-color: #fbffef;
}

.card-heading-bg {
  background-color: #fffdea;
}

.main-card-content {
  border-top: 1px solid #e4e4e4;
  margin: 0px 20px 15px 20px;
}

.main-card .div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-wrpr {
  border-bottom: 0px solid #e4e4e4;
  padding: 15px 20px;
}

.p-20 {
  padding: 20px !important;
}

.m-20 {
  margin: 20px;
}

.m-0 {
  margin: 0 !important;
}

.heading-wrpr .div-flex .heading {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}

.heading-wrpr .div-flex .heading span {
  display: inline-flex;
  margin: 0 4px;
}

.heading-wrpr .div-flex .select-option select {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 12px;
  border: none;
  background-color: transparent;
}

.main-card .heading-small {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 20px 0;
}



.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-icon-text {
  text-align: center;
  min-width: 150px;
  padding: 18px;
  margin: 0.8rem auto;
}

.cal-bg-sky {
  background-color: #e6f8fd;
}

.cal-bg-light-pink {
  background-color: #ffe7e7;
}

.cal-icon-text .date {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.cal-icon-text .zone {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  text-align: center;
  color: #000000;
}

.cal-wrpr .div-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.record-text table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0px 1rem;
}

.record-text table tr td {
  text-align: center;
  padding: 7px;
}

.record-text table tr td.number span {
  background: #91c951;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 12px 17px;
}

.record-text table tr td.name span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.0125em;
  background-color: #4f4f4f;
  color: #ffffff;
  padding: 12px 17px;
  opacity: 0.7;
  border-radius: 50%;
}

.bg-grn {
  background: #91c951;
}

.text-clr-white {
  color: #ffffff;
}


.card-btn button {
  background: #fcb913;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 12px 12px;
  margin: 20px auto;
  border: none;
}

.card-btn button:hover {
  background-color: #000;
  color: #fff;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
}

.txt-lft {
  text-align: right;
}

.txt-rgt {
  text-align: right;
}

.see-results-btn {
  background: #ffb91d;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 12px 12px;
  margin: 20px auto;
  border: none;
  min-width: 149px;
  text-decoration: none;
}
</style>

<style scoped>
@media screen and (max-width: 1920px) {
  .small-bld-blk:before {
    left: 28%;
  }

  .small-bld-blk:after {
    right: 28%;
  }
}

@media screen and (max-width: 1366px) {
  .small-bld-blk:before {
    left: 20%;
  }

  .small-bld-blk:after {
    right: 20%;
  }

  .section-wrap {
    padding: 12px 12px;
  }

  .event-more {
    background: url(../../../../public/themeV2/assets/images/hero-slider-v1.jpg);
    min-height: 38vh;
  }
}

@media screen and (max-width: 1200px) {
  .small-bld-blk:before {
    left: 30%;
  }

  .small-bld-blk:after {
    right: 30%;
  }

  .small-bld-blk {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg {
    font-size: 28px;
    line-height: 33px;
  }

  .heading-med {
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1024px) {
  .sectionHeadingMain {
    font-size: 68px;
  }

  .cards-sections .card {
    width: 100%;
  }
}

@media screen and (min-width: 747px) {
  .cards-sections .card-container {
    display: flex;
    flex-wrap: wrap;
  }



  .cards-sections-daily-event .card-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 991px) {
  .tab-chart button {
    width: 100%;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-blk:before {
    display: none;
  }

  .small-bld-blk::after {
    display: none;
  }

  .btn-regi {
    float: none;
    padding: 28px 25px;
    margin: 0 auto;
    border: none;
    width: 100%;
  }

  .card-box-info ul li {
    width: 100%;
    padding: 20px;
    margin: 5px auto;
  }

  .btn-wrpr button {
    font-size: 14px;
    padding: 18px 26px;
    margin: 4px auto;
    width: 83%;
  }

  .small-bld-text {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg-txt {
    font-size: 32px;
    line-height: 36px;
  }

  .event-wrapper button {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .cards-sections-daily-event .card {
    margin: 8px auto;
    padding: 6px;
    width: 95%;
  }

  .breadcrumb {
    padding: 5px;
    height: 85px;
    width: 100%;
    position: absolute;
    bottom: 2%;
  }

  .tab-regicontent p {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-left {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-right {
    width: 100%;
  }

  .multi-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-flex.btn-back-next .col-right {
    text-align: center;
  }

  .div-flex.btn-back-next .col-left .btn-back {
    width: 90%;
    margin: 6px 0;
  }

  .div-flex.btn-back-next .col-right .btn-next {
    width: 90%;
    margin: 6px 0;
  }

  .section-chart select {
    width: 62%;
  }

  .btn-wrpr {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
  }

  .tab-regi-wrpr h1 {
    margin: 20px 10px 30px 10px;
  }

  .tab-regi button {
    width: 100%;
  }

  .btn-upload {
    display: block;
    text-align: center;
  }

  .btn-upload button {
    margin: 0 auto;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }
}

@media (max-width: 1199.98px) {
  .div-flex {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .routes img.intro-arrow-desktop-black {
    float: none;
    margin: 0 auto;
  }

  .div-flex .col-left {
    width: 100%;
  }

  .div-flex .col-right {
    width: 100%;
  }

  .section-wrap {
    padding: 120px 20px;
  }

  /*table custom*/
  .owl-custom table thead tr th {
    font-size: 0.7rem;
  }

  .owl-custom table tbody tr td {
    font-size: 0.7rem;
  }

  .col-right h2 {
    text-align: inherit;
  }

  .main-card .heading-small {
    justify-content: center;
  }

  .card-btn.txt-rgt {
    text-align: center;
  }

  .stage-icon-text {
    margin: 12px;
  }

  .black-bar .div-flex {
    height: auto;
    padding: 12px;
  }

  .black-bar .search .row {
    flex-direction: column;
  }

  .card-box-info-overview ul li {
    width: 95%;
  }

  .live-section {
    height: auto;
  }

  .tab-live-wrpr h1 {
    padding: 12px;
  }

  .tab-live-wrpr {
    margin: 12px;
  }

  .live-tbl .table-outer {
    overflow: scroll;
  }

  .section-main select {
    width: 100%;
    margin: 5px auto;
  }

  .section-main select.sel-cutom {
    width: 100%;
    margin: 5px auto;
  }

  .list-indivi {
    width: 96%;
    margin: 12px;
  }

  .list-indivi ul {
    flex-direction: column;
  }

  .list-indivi ul li {
    padding: 11px;
    display: inline-block;
    width: 100%;
  }

  .sprint-btn a {
    margin: 2px auto;
  }

  .rank-tbl .table-outer {
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .main-card {
    margin: 0 15px;
  }

  .stage-wrpr {
    height: 100% !important;
  }

  .stage-icon-text {
    position: relative !important;
  }

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B1B1B1;
    padding: 10px;
  }

  .customTableBodyHeading {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .customTableHead h4 {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .sectionHeadingMain {
    margin-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    height: 140px;
    margin-right: 18px;
    width: 100% !important;
    margin: 0 auto;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;
    width: 100%;
    margin: 0px;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }

  .recentPosts {
    flex-direction: column;
    border: 1px solid #b1b1b1;
  }

  .recentPostsLeftSide {
    padding-top: 12px !important;
    max-width: 100%;
  }

  .recentPostsRightSide {
    max-width: 100%;
    padding: 0px;
    margin: 8px 0;
  }

  .customTableBody {
    background: #F2F2F2;
    padding: 18px 0;
    /*overflow: scroll;*/
    width: 100%;
  }

  .customTableStiped {
    margin: 0 auto;
    width: 700px;
    overflow-y: scroll;
    position: relative;
  }
}

@media screen and (max-width: 575px) {
  .customTableBodyHeading {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .customTableHead h4 {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .sectionHeadingMain {
    margin-bottom: 12px !important;
    padding-top: 12px !important;
    font-weight: unset;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    height: 140px;
    margin-right: 18px;
    width: 100% !important;
    margin: 0 auto;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;
    width: 100%;
    margin: 0px;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }

  .recentPosts {
    flex-direction: column;
    border: 1px solid #b1b1b1;
  }

  .recentPostsLeftSide {
    padding-top: 12px !important;
    max-width: 100%;
  }

  .recentPostsRightSide {
    max-width: 100%;
    padding: 0px;
    margin: 8px 0;
  }

  .customTableBody {
    background: #F2F2F2;
    padding: 18px 0;

    width: 100%;
  }

  .customTableStiped {
    margin: 0 auto;
    width: 700px;
    overflow-y: scroll;
    position: relative;
  }
}

.recentPostsLeftSide {
  background-color: #F2F2F2;
  padding-top: 30px;
}

.postLabel {
  font-family: 'Inter' !important;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
}

.posTime {
  font-family: 'Inter' !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}



.customTableHead {
  background-color: #000000;
  padding: 10px 30px;
  margin: 0;
}

.customTableHead h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.customTableBody {
  background: #F2F2F2;
  padding: 18px 0;
}

.customTableBodyHeading {
  font-family: 'Poppins' !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #282828;
  padding-left: 28px;

}


.tableCount {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  margin-left: 21px;
}

.tableCount.yellow {
  color: #FCB913;
}

.tableCount.blue {
  color: #00B2E3;
}

.tableCount.red {
  color: #EA5F28;
}

.customTableStiped {
  position: relative;
}

.customTableBody .customTableStiped:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 100%;
}

.customTableBody .container .customTableStiped:nth-child(1):after {
  background: #FCB913;
}

.customTableBody .container .customTableStiped:nth-child(2):after {
  background: #00B2E3;
}

.customTableBody .container .customTableStiped:nth-child(3):after {
  background: #EA5F28;
}

div.customTableBody div:nth-child(1) div.col-1.strpedInner>span {
  color: #FCB913 !important;
}

div.customTableBody div:nth-child(2) div.col-1.strpedInner>span {
  color: #00B2E3;
}

div.customTableBody div:nth-child(3) div.col-1.strpedInner>span {
  color: #EA5F28;
}


.customTableButtons .see-results-btn {
  color: #FFFFFF;
  padding: 12px 28px;
}

.customTableButtons {
  text-align: right;
  padding-right: 25px;
}

sub {
  bottom: 0.0em;
}
</style>

<template>
  <div class="section-card">
    <div class="container">
      <h1 class="sectionHeadingMain">RECENT</h1>
      <div class="recentPosts row" v-for="item in srcEvents" :key="item._id">
        <div class="col-2 recentPostsLeftSide text-center">
          <template v-if="mapUserGenderColor(item?.Gender).gender === 1">
            <img src="../../../../public/assets/women-icon.png"/>
          </template>
          <template v-else>
            <img src="../../../../public/assets/psotIconBlue.svg"/>
          </template>
          <blockquote class="postLabel mb-1">{{ item?.EventDateTime.split(" ")[0] }}</blockquote>
          <span class="postTime">{{ item?.EventDateTime.split(" ")[1] }} GST </span>
        </div>
        <div class="recentPostsRightSide col-10">
          <div class="customTable">
            <div class="customTableHead">
              <h4 class="mb-0">{{ item.EventName }} - <span :style="mapUserGenderColor(item?.Gender)" style="margin-left: 5px;"> {{ mapUserGender(item?.Gender) }}</span></h4>
            </div>
            <div class="customTableBody">
              <h5 class="customTableBodyHeading mb-3">Elevation: {{ item?.Elevation }} - Distance: {{ item?.Distance }} KM</h5>

              <table class="table table-responsive-md">
                <tr scope="row" class="bg-white mb-3 customTableStiped" v-for="(player, indx) in item.ResultData" :key="player.userId">
                  <td scope="col" class="col-1 strpedInner">
                    <span class="tableCount yellow" v-if="indx === 0">{{ indx + 1 }}</span>
                    <span class="tableCount blue" v-if="indx === 1">{{ indx + 1 }}</span>
                    <span class="tableCount red" v-if="indx === 2">{{ indx + 1 }}</span>
                  </td>
                  <td scope="col" class="col-1 strpedInner" v-html="mapJerseyImage(player.jerseyImage)"></td>
                  <td scope="col" class="col strpedInner">
                    <span>{{ player.userFullName }}</span>
                    <span v-if="IsSinglePointBasedEvents(item)">
                    <br> 
                    <sub> {{ player.teamName }}</sub>
                  </span>
                  </td>
                  <td scope="col" class="col strpedInner" v-if="!isMobileDevice && !IsSinglePointBasedEvents(item)">
                    <span>{{ player.teamName }}</span>
                  </td>
                  <td scope="col" class="col strpedInner">
                    <span>{{ IsSinglePointBasedEvents(item) ? (player.points) + " PTS" : formatTime(player.finishedTime) }} </span>
                  </td>

                  <td scope="col" class="col strpedInner" v-if="!isMobileDevice && IsSinglePointBasedEvents(item)">
                    <span> </span>
                  </td>

                  <td scope="col" class="col strpedInner" v-if="!isMobileDevice && !IsSinglePointBasedEvents(item)">
                    <span>+{{ formatTime(player.finishedTime - item.ResultData[0].finishedTime) }}</span>
                  </td>
                </tr>
              </table>

              <div class="customTableButtons">
                <router-link :to="{ name: 'EventResultIndex', query: { eventId: item.EventId } }"
                             class="see-results-btn">VIEW RESULTS
                </router-link>
                <img v-if="item?.ResultPublishStatus == 'official'"  class="ml-4" src="../../../../public/assets/officalBadge.svg" />
                 <img v-else src="../../../../public/assets/unofficalBadge.svg"  class="ml-4" />

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="div-flex">
        <div v-for="(item, index) in srcEvents" :class="index % 2 == 0 ? 'col-left' : 'col-right txt-right'" :key="index">
          <div class="main-card">
            <div class="heading-wrpr">
              <div class="div-flex">
                <div class="heading">
                  {{ item.EventName }}
                  <span :class="item?.EventsData[0]?.result_status != 'official' ? 'event_status_unofficial' : 'event_status_official'" >
                   </span>

                </div>

                <div class="select-option">
                  <strong>{{ item?.EventsData[0]?.event_category?.toUpperCase() }}</strong>
                </div>
              </div>
            </div>
            <div class="main-card-content">
              <div class="heading-small">     Elevation   :  {{ item?.EventsData[0]?.elevation }}  |  &nbsp; Distance  :  {{ item?.EventsData[0]?.distance }} KM</div>
              <div class="cal-wrpr">
                <div class="div-flex">
                  <div class="cal-icon-text cal-bg-sky">

                      <img  src="../../../../public/themeV2/assets/images/cal-icon-sky.png">

                    <div class="date">{{ item?.EventsData[0]?.datetime.split(" ")[0] }}</div>
                    <div class="zone">{{ item?.EventsData[0]?.datetime.split(" ")[1] }} GST </div>
                  </div>
                  <div class="record-text" style="width: 100%">
                    <table style="width: 100%">
                      <tr v-for="(player, indx) in item.ResultData" :key="indx">
                        <td class="number"><span> {{ indx + 1 }}</span></td>
                        <td   v-html="mapCountryFlag(player.userCountryFlag)"> </td>
                        <td class="bg-grn text-clr-white">{{ player.userFullName }}</td>
                        <td class="bg-grn text-clr-white"> {{ formatTime(player.finishedTime) }}</td>
                      </tr>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>


<script>

import { formatTime, IsSinglePointBasedEvents, mapCountryFlag, mapJerseyImage, mapUserGender, mapUserGenderColor } from "@/shared/events/event.helpers.ts";

// import config from "@/config/app.config.ts";
// import countries from "@/shared/store/json/country.flags.json";


export default {
  name: "EventCard",
  props: ["srcEvents", "isMobileDevice"],
  data() {
    return {
      formatTime: formatTime,
      mapCountryFlag: mapCountryFlag,
      mapJerseyImage: mapJerseyImage,
      mapUserGender: mapUserGender,
      mapUserGenderColor: mapUserGenderColor,
      IsSinglePointBasedEvents: IsSinglePointBasedEvents
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

