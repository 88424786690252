// @ts-nocheck

import axios from "axios";
import config from "@/config/app.config.ts";
import { encryptPayload } from "../shared/events/event.helpers.ts";

// eslint-disable-next-line no-unused-vars
const Service = (isAdmin = false) => {
  // const apiUrl = config.API_URL;
  const apiUrl = config.API_URL;
  const store = global.app.$store;
  store.commit("common/SET_LOADING", true);

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //'X-Requested-With': 'XMLHttpRequest',
  };

  const token = localStorage.getItem("resultAdminToken");
  if (token) {
    // @ts-ignore
    headers["Authorization"] = `Bearer ${token}`;
  }



  const instance = axios.create({
    withCredentials: true,
    baseURL: apiUrl,
    headers,
  });
  if (process.env.NODE_ENV == 'production') {
    instance.interceptors.request.use(
      function (config) {
        //Encrypt All Post Data Request
        if (config?.data) {
          config.data = encryptPayload(config.data)
        }
        // Do something before request is sent
        store.commit("SET_ERRORS", {}, { root: true });
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
  instance.interceptors.response.use(
    (response) => {
      store.commit("common/SET_LOADING", false);
      const data = response.data;
      if (!data.error && data.display) {
        global.app.$toast.success(data.message);
      }
      return data;
    },
    (error) => {
      store.commit("common/SET_LOADING", false);

      if (!error.response) {
        global.app.$toast.error(
          "No response received. We are regretted for inconvenience. please try later"
        );
        return Promise.reject(error);
      }

      if (401 === error.response.status) {
        location.href = "/result-admin/login";
        return;
      }

      const data = error.response.data;


      if (data.error && data.display) {
        global.app.$toast.error(data.message);
      }

      if (422 === error.response.status) {
        global.app.$store.commit("SET_ERRORS", data.errors);
      }

      return Promise.reject(data);
    }
  );

  return instance;
};

export default Service;
