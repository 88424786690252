import { createStore } from "vuex";
// @ts-ignore
import common from "@/shared/store/common.ts";
// @ts-ignore
import event from "@/shared/store/event.ts";
// @ts-ignore
import auth from "@/shared/store/auth.ts";
// @ts-ignore
import player from "@/shared/store/player.ts";
// @ts-ignore
import adminUser from "@/shared/store/adminUser.ts";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const store = () => {
  return createStore({
    state: {
      errors: [],
    },
    getters: {
      errors: (state) => state.errors,
    },
    mutations: {
      SET_ERRORS(state, errors) {
        state.errors = errors;
      },
    },
    actions: {},
    modules: {
      common,
      event,
      auth,
      player,
      adminUser,
    },
    plugins: [createPersistedState()],
    strict: debug,
  });
};

export default store;
