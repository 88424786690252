<template>
  
  <page-heading>Push Notifications</page-heading>

  <AdminNavigation />

  <div class="container">

    <page-loader v-if="loading" />

    <Form
      class="player-form"
      @submit="submitHandler"
      :validation-schema="schema"
      v-slot="{ errors }"
      ref="form"
    >


      <div id="accordionDiv">
       
            <h2>REQUIRED FIELDS (*)</h2>
            <div class="row">
              <section class="col-lg-8 col-md-12">
                <div class="form-group">
                  <div class="row">

                   
                    <section class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label for="type">SEND TO</label>
                        <Field
                          id="type"
                          name="type"
                          class="form-control"
                          as="select"
                          @change="selectedType = $event.target.value"
                          :class="{ 'is-invalid': errors.type }"
                          v-model="sendingType"
                        >
                          <option
                            :value="item.value"
                            v-for="(item, i) in sendingChannelsList"
                            :key="i"
                          >{{ item.name }}</option>
                        </Field>
                        <div class="invalid-feedback">{{ errors.type }}</div>
                      </div>
                    </section>

                 
                      <section class="col-lg-12 col-md-12" v-if="selectedType == 'specific_users'">
                        <div class="form-group">
                          <label for="selectedGatesOption">Select Users</label>
                          <VueMultiselect
                            v-model="selectedUsers"
                            :options="sendToOptions"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="Search via name/email"
                            label="label"
                            name="selectedUsers"
                            track-by="value"
                            :searchable="true"
                            @search-change="asyncFind"
                            @update:model-value="updateSelected"
                           >
                          <template #noResult>
                            No user found. Consider changing the search query.
                          </template>
                        </VueMultiselect>

                        <Field
                          name="usersSelected"
                          id="usersSelected"
                          type="text"
                          class="form-control d-none"
                          :class="{ 'is-invalid': errors.usersSelected }"
                          v-model="selectedUsers"
                        />
                        <div class="invalid-feedback">{{ errors.usersSelected }}</div>
                         </div>
                      </section>
                  

                     <section class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <label for="title">Title</label>
                        <Field
                          name="title"
                          id="title"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.title }"
                        />
                        <div class="invalid-feedback">{{ errors.title }}</div>
                      </div>
                    </section>
                  </div>

                 

                  <div class="row">
                    <section class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label for="message">Message</label>
                        <Field
                          as="textarea"
                          rows="5"
                          name="message"
                          id="message"
                          type="textarea"
                          class="form-control"
                          :class="{ 'is-invalid': errors.message }"
                          @keyup="checkCharsLimit()"
                          v-model="message"
                          :maxLength="totalcharacters"
                        />
                        <div class="invalid-feedback">{{ errors.message }}</div>

                        <br />
                        <span
                          :style="(typedcharacters == totalcharacters) ? { color: 'red' } : { color: 'black' }"
                        >{{ typedcharacters }}/{{ totalcharacters }} characters</span>
                      </div>
                    </section>
                  </div>


                </div>
              </section>

              <section class="col-lg-4 col-md-12" v-if="sendingType == 'all_game_users' || sendingType == 'specific_users'">
                <div class="form-group">
                  <!-- right side -->
                  <img width="320" src="../../../../../public/assets/all-push-notifications.jpg" />
                </div>
              </section>

              <section class="col-lg-4 col-md-12" v-if="sendingType == 'android_game_users'">
                <div class="form-group">
                  <!-- right side -->
                  <img height="320" src="../../../../../public/assets/push-notification.png" />
                </div>
              </section>

              <section class="col-lg-4 col-md-12" v-if="sendingType == 'ios_game_users'">
                <div class="form-group">
                  <!-- right side -->
                  <img height="320" src="../../../../../public/assets/ios-push-notification.png" />
                </div>
              </section>
            </div>
         

        <div class="row">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn points-btn">Send Notification</button>
          </section>
        </div>
      </div>
    </Form>
  </div>
</template>

 
<style scoped src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped src="./index.css"></style>

<style scoped>
.points-btn {
  background-color: #f1aa00;
  color: #121113;
  border-color: black;
  border: 1px solid;
}
</style>
<script>
import { Field, Form } from "vee-validate";
import AdminNavigation from "../../../../shared/components/AdminNavigation.vue";

import $ from "jquery";
import VueMultiselect from 'vue-multiselect';
import { mapActions } from "vuex";
import * as Yup from "yup";

export default {
  name: "PushNotificationsPage",
  components: {
    Form,
    Field,
    AdminNavigation,
    VueMultiselect
  },

  data() {
    return {
      sendingChannelsList: [
        {
          name: "All Game User's",
          value: "all_game_users"
        },
        {
          name: "Andriod Game Users",
          value: "android_game_users"
        },
        {
          name: "iOS Game Users",
          value: "ios_game_users"
        },
        // {
        //   name: "Specific Users Only",
        //   value: "specific_users"
        // }
      ],
      sendToOptions: [],
      selectedUsers: [],
      sendingType: "",
      selectedType: null,
      totalcharacters: 100,
      typedcharacters: 0,
      updateSelected: null,

    };
  },

  setup() {
    const schema = Yup.object().shape({
      type: Yup.string().required("Please select an option"),
      title: Yup.string().required("Title is required"),
      message: Yup.string().required("Message is required"),
      usersSelected: Yup.array().when("type", {
        is: "specific_users",
        then: Yup.array().min(1, "Please select user"),
      }),
    });
    return {
      schema
    };
  },

  mounted() {

  },

  methods: {
    ...mapActions("common", ["sendNotifcationsFCM", "getSearchUsersList"]),

    async submitHandler(formData) {
      $("#accordionDiv").addClass("blurry");
      this.loading = true;
      if (localStorage.getItem("resultAdminToken")) {
        formData = {
          ...formData,
          adminToken: localStorage.getItem("resultAdminToken"),
        };
        await this.sendNotifcationsFCM(formData);
        this.$refs.form.resetForm();
        this.selectedType = null
        setTimeout(function () {
          $("#accordionDiv").removeClass("blurry");
        }, 600);
        this.loading = false;
      }
    },
    checkCharsLimit() {
      this.typedcharacters = this.message.length;
    },
    async asyncFind(query) {
      this.sendToOptions = []
      if (query.length >= 3) {
        const { data } = await this.getSearchUsersList({ value: query })
        this.sendToOptions = data
      }
    }


  }
};
</script>