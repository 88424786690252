 

<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;

}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  border: none;
  width: 970px;
}

.vfm--overlay {
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  color: #fcb913;
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'Barlow Condensed', sans-serif !important;

}

.modal__content {

  overflow-y: auto;


  border: black
}

.table-striped tbody tr:nth-of-type(odd) {

  background: #F6FBFF;
}

.table-striped tbody tr:nth-of-type(even) {

  background: rgba(255, 255, 255, 0.30);
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;

}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

#profileRow {
  background: #282828;
  padding: 50px 30px 12px 30px;

}

.circular_image {
  border-radius: 100%;
  overflow: hidden;
  display: inline-flex;
  border: 1px solid #404040;
  width: 100px;
  height: 100px;
  justify-content: center;
  position: relative;
  align-items: center;
}

.circular_image img {
  width: 100%;
}



.tag-span>p {
  border-radius: 36px;
  text-align: center;
  padding: 0px 10px 0px 10px;
  vertical-align: middle;
  width: 80px;
  flex-shrink: 0;
  color: white;
  font-size: 15px;
}

.tag-span:nth-child(1)>p {
  background: var(--secondary_blue, #00B2E3);
}

.tag-span:nth-child(2)>p {
  background: var(--secondary_blue, #00CF85);
}

.propValue {
  color: #fff;
  display: block;
  position: absolute;
}

.propTitle {
  color: rgb(99, 95, 95);
  text-transform: uppercase;
}


.propTitle>img {
  width: 23px;
  height: 23px;
  padding-left: 5px;
}

.playerName {
  font-size: calc(0.6vw + 1.0rem);
}




p {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: bolder;
}

.countryName {
  font-weight: bolder;
  text-decoration: underline;
  text-transform: uppercase;
  color: #00B2E3;
}

#modalFilterBar {
  display: inline-flex;
  padding: 10px 7px 10px 6px;
  justify-content: flex-end;
  align-items: center;
  background: var(--Border, #E1E1E1);
  align-items: center;
}

.segment-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  border: 0.5px solid #000;
  height: 32px;
}


.segment-btn1 {
  border-radius: 20px 0px 0px 20px;
}

button.segment-btn1:hover,
button.segment-btn2:hover,
.segment-btn.active {
  background: black;
  color: white;
}


.segment-btn2 {
  border-radius: 0px 20px 20px 0px;
}

.profileBtn {
  border-radius: 39px;
  background: var(--secondary_blue, #00B2E3);
  box-shadow: 0px 8px 11px 0px rgba(0, 0, 0, 0.25);
  width: 139.089px;
  color: var(--Secondary1, #FFF);
  text-align: center;
  border: none;
  font-style: normal;
  line-height: 31.5px;
  /* 112.5% */


}

.profileBtn>a {
  color: white;
}

.profileBtn:hover {
  background: var(--secondary_blue, #000);
}

#riderDataRow {

  background: var(--Border, #ffffff);
  align-items: center;
  padding: 10px 10px 10px 0px
}

span>.event-name,
.route-name {
  color: var(--Secondary2, #282828);
  /* font-family: DIN Pro; */

  font-style: normal;
  font-weight: bolder;
  /* line-height: 31.5px; */
  text-transform: uppercase;
}

.event-name {
  font-size: 20px;
}

.route-name {
  font-size: 16px;
}

table thead {
  background: #282828;
  color: #fff
}

table tr td {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.propertiesTitle {
  color: #59636B
}

.propertiesValue {

  font-style: normal;
  font-weight: 700;
}
</style>
 

<style scoped>
.example .btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}


.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle:before {
  left: -4rem;

}

.btn-toggle:after {
  right: -4rem;
  opacity: 0.5;
}

.btn-toggle>.handle {
  position: absolute;
  top: 1.3px;
  left: 28px;
  width: 1.125rem;
  height: 21px;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;

}

.btn-toggle.active {
  transition: background-color 0.25s;

}

.btn-toggle.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;

}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;

}

.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-sm.active:after {
  opacity: 1;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}

.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}

.btn-toggle.active {
  background-color: #FCB913;
}






.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
  line-height: 0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.6875rem;
  width: 3.875rem;
}

.btn-toggle.btn-lg.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-lg.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-lg.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-lg.btn-sm.active:after {
  opacity: 1;
}



.btn-toggle.btn-sm {
  margin: 0 0.5rem 0.2rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 4.3rem;
  border-radius: 1.5rem;
}

.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}

.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle.btn-sm:before {
  content: '';
  left: -0.5rem;
}

.btn-toggle.btn-sm:after {
  content: '';
  right: -0.5rem;
  opacity: 0.5;
}


.table-bordered th {
  border: 0px solid #000;
}

.table-bordered tr>td {
  border: 1px solid #fff;
}

.btn-toggle.btn-sm>.handle {
  position: absolute;
  left: 0.1875rem;
  height: 21px;
  width: 2.5rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
}

.btn-toggle.btn-sm.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active:before {
  opacity: 0.5;
}

.btn-toggle.btn-sm.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}





.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
  line-height: -1rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.275rem;
  width: 1.55rem;
}

.btn-toggle.btn-xs.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-xs.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-xs.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-xs.btn-sm.active:after {
  opacity: 1;
}


.table-bordered tr>td:nth-child(1) {
  text-transform: uppercase;
}

.player-col-cat {
  text-align: center;
  align-items: baseline;
  display: inline-block;
  border-radius: 36px;
  text-align: center;
  width: 80px;
  flex-shrink: 0;
  color: white;
  font-size: 15px;
  margin-top: 2px;
  padding-bottom: 0px;
}

.palyer-col-cat1 {
  background: #00b2e3;
}

.palyer-col-cat2 {
  background: #04cf85;
}

.test-class {
  display: inline-flex;
  column-gap: 6px;
  vertical-align: middle;
  align-items: center;
}

.tableCols:first-child {
  color: #282828
}

.tableCols {
  color: #635f5f
}
</style>

 

<template>
    <VueFinalModal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      :click-to-close="false">
      <button class="modal__close btn   btn-sm" @click="handlePopup(false)">
       <img :src="btnCloseIcon" width="35" />
      </button>
   
      <div class="" id="profileRow">  
       <div class="row"  >
        
          <div class="col-2 d-flex justify-content-end">
            <div class="circular_image">
              <span style="padding: 0" v-html="mapJersey(comparePlayerData?.lastJerseyId, '60px', '60px')"></span>
                <!-- <img src="../../../public/assets/jersey-1.png"/> -->
            </div>
          </div>
          <div class="col-8">
            <div class='pl-1'>
              <p class="playerName"><span v-html="mapCountryFlag(comparePlayerData?.countryId, '45px', '30px')"></span> {{ getFullName }} </p>
            </div>
            <div class='pl-1'>
              <p>TEAM: <span class="countryName">{{ comparePlayerData?.teamName || "INDIVIDUAL" }}</span></p>
            </div>
            <div class="d-flex">
              <div class="pl-1 tag-span"><p>{{ mapGenderValue(comparePlayerData?.gender) }}</p></div>
              <div class="pl-1 tag-span "><p>{{ getCategoryName }}</p></div>
            </div>
          </div>
          <div class="col-2 mt-4">
              <div class="d-flex flex-row-reverse  mt-5">
  
                <div class="p-2 px-3">
                  <span class="propTitle ">
                   Age  
                 </span>
                 <span class="propValue">
                   {{ calculateAge(comparePlayerData?.dobYear) || 0 }}
                 </span>
               </div>

               <div class="p-2  px-3">
                <span class="propTitle d-flex">
                  Height  <img :src="checkIcon" />
                </span>
                <span class="propValue">
                  {{ comparePlayerData?.height || 0 }} CM
                </span>
              </div>

              <div class="p-2 px-3"> 
                <span class="propTitle d-flex">
                  Weight  <img :src="checkIcon" />
                </span>
                <span class="propValue">
                  {{ setUptoDecimalValue(comparePlayerData?.weight) || 0 }} KG
                </span>
              </div>

              <div class="p-2 px-3">
                <span class="propTitle d-flex">
                  FTP  <img :src="checkIcon" />
                </span>
                <span class="propValue">
                  {{ comparePlayerData?.playerFtp || 0 }} W
                </span>
              </div>
                <div class="p-2 px-3">
                  <span class="propTitle">
                    Level
                  </span>
                  <span class="propValue">
                    {{ comparePlayerData?.playerLevel || 0 }}
                  </span>
                </div>
              </div>

          </div>

        </div>  
      </div>

      <div class="modal__content" >

      <div class="d-flex " id="modalFilterBar" >
        <!-- <div class="p-2 w-100 " v-if="isSegmentTabActive">
          <div class="d-flex pl-4">
            <button class="segment-btn segment-btn1" :class="{ active: filters?.type === 'gate' }"  @click="applyFilter({ type: 'gate' })"> Segment</button>
            <button class="segment-btn segment-btn2"   :disabled="!overAllData.length" :class="{ active: filters?.type !== 'gate' }" @click="applyFilter({ type: 'overall' })"> Overall </button>
          </div>
        </div> -->
        <div class="pl-2 pr-4 flex-shrink-1  ">
          <button class="profileBtn" > <a href="https://event.mywhoosh.com/user/activities#profile" target="_blank"> View Profile</a> </button>
        </div>
      </div>


      <div class="d-flex " id="riderDataRow">
        <div class="p-2 w-100 ">

          <div class="pl-4 ">
            <span class="event-name"> {{ event?.name }} | {{ mapGenderValue(event?.gender) }}</span> <br>
            <span class="route-name">{{ event?.route_name }}</span>
          </div>


        </div>

        <div class="pl-1  d-flex flex-shrink-0  " style="gap: 10px;">
          <div class="d-flex inline-flex align-middle align-items-center" style="gap: 5px; top: 2px; position: relative">
            <span><img :src="distanceIcon" width="28" /></span>
            <span class="propertiesTitle ">  DISTANCE:  </span>
            <span class="propertiesValue"> {{ event?.distance }} km </span>
          </div>
         <div class="d-flex inline-flex align-middle align-items-center" style="gap: 5px"> 
          <span><img :src="elevationIcon" width="28" /></span>
          <span class="propertiesTitle">  ELEVATION:  </span>
          <span class="propertiesValue"> {{ event?.elevation }} m</span>
        </div>


         <div class="d-flex inline-flex align-middle align-items-center" style="gap: 5px" v-if="!isOwnDataView && isCompareOpen">
          <span class="propertiesTitle">COMPARE  </span>
          <span class="">
              <button type="button" class="btn btn-sm btn-toggle active" data-toggle="button" :aria-pressed="compare" autocomplete="off" @click="handleCompareToggle($event.currentTarget.ariaPressed)">
                <div class="handle" id="handler"></div>
              </button>
          </span>
        </div>

        </div>
      </div>     
      </div>  
     
      <table class="table table-bordered table-striped text-center"  id="table">
        <thead class="">
          <tr>
            <th v-for="(column, i) in tableColumns" v-bind:key="i" :style="'vertical-align: middle'">
              
              <div class="test-class">

                <div v-if="i > 0"  class="s">

                  <!-- {{ column.userInfo.jerseyImage }} -->
                  <span v-html="column.userInfo.jerseyImage"></span>
 
                  <!-- <img src="../../../public/assets/jersey-1.png"/> -->
                </div>

                <div class="">
                    <div>
                      {{ column.name }}
                    </div>
                    <div v-if="i > 0"  class="player-col-cat" :class="i == 2 ? 'palyer-col-cat2' : 'palyer-col-cat1'">
                      {{ mapGenderValue(column.userInfo.gender) }}
                    </div>
                </div>

               
                
                 
              </div>
            

            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dataObj, ind) in compareData" v-bind:key="ind"  >
            <td v-for="(objValue, index) in Object.values(dataObj)"  v-bind:key="index"  class="tableCols" >
              {{ objValue }}
            </td>
          </tr>
        </tbody>
      </table>   
    </VueFinalModal>
  </template>
  


<script>
import { calculateAge, eventCategories, getRedirectionUrl, mapCountryFlag, mapJersey, setUptoDecimalValue } from '@/shared/events/event.helpers.ts';
import $ from 'jquery';
import { VueFinalModal } from "vue-final-modal";
import { mapGetters } from "vuex";
import btnCloseIcon from '../../../public/themeV2/assets/svgs/btn_close.svg';
import distanceIcon from '../../../public/themeV2/assets/svgs/distance_icon.svg';
import elevationIcon from '../../../public/themeV2/assets/svgs/elevation_icon.svg';
import checkIcon from '../../../public/themeV2/assets/svgs/icn_check.svg';

export default {
  name: "PlayerProfileModal",
  props: ["isShowPlayerProfileModal", "activeItem", "event", "comparisonPlayerNames", "segData", "isCompareOpen", "comparePlayerId", "overAllData"],
  data() {
    return {
      redirectUrl: getRedirectionUrl,
      compare: this.isCompareOpen,
      showModal: this.isShowPlayerProfileModal,
      handlerState: true,
      btnCloseIcon: btnCloseIcon,
      checkIcon,
      distanceIcon,
      elevationIcon,
      filters: {},
      loader: true,
      // comparisonPlayerNames: comparisonPlayerNames,
      defaultColumns: [{ name: 'SEGMENT', order: 0, userInfo: { gender: 1 } }, { name: 'OVERALL', order: 1, userInfo: { gender: 1 } }],
      mapCountryFlag: mapCountryFlag,
      setUptoDecimalValue: setUptoDecimalValue,
      mapJersey: mapJersey,
      eventCategories: eventCategories,
      calculateAge: calculateAge,
      compareData: this.overAllData,
      compareDataCopy: this.overAllData,
      tableColumns: [],
      segmentData: this.segData,
      segmentDataCopy: this.segData
    };
  },
  components: { VueFinalModal },

  computed: {

    ...mapGetters("player", ["comparePlayerData", "loggedInPlayerData"]),

    getFullName() {
      return this.comparePlayerData?.firstName + " " + this.comparePlayerData?.lastName
    },
    isSegmentTabActive() {
      return this.filters.type === 'gate'
    },
    getCategoryName() {
      return this.eventCategories?.find(item => item.id == this.comparePlayerData?.categoryId).name || ""
    },
    isOwnDataView() {
      return this.loggedInPlayerData?.userId === this.comparePlayerId
    }
  },
  async mounted() {
    this.tableColumns = []

    if (!this.isOwnDataView && this.isCompareOpen) {
      document.getElementById('handler').innerText = 'ON';
    }
    this.filters.type = this.activeItem?.type
    await this.handleTableDataAndCols(this.filters.type, false)
  },

  methods: {

    mapGenderValue(genderValue) {
      let msg = ''
      switch (genderValue) {
        case 0:
          msg = 'Men'
          break;
        case 1:
          msg = 'Women'
          break;
        case 2:
          msg = 'Both'
          break;
        default:
          msg = ""
      }
      return msg
    },

    async handleTableDataAndCols(type) {
      this.tableColumns = [...this.defaultColumns, ...this.comparisonPlayerNames]

      if (type != 'gate' && type != 'daylap') {
        this.tableColumns = this.tableColumns.filter((e) => e.name != "SEGMENT")
        this.compareData = (this.loggedInPlayerData && (!this.compare || this.isOwnDataView)) ? this.compareData.map((item) => { return { name: item.name, loggedPlayer: item.loggedPlayer } }) : this.compareDataCopy
      } else {
        this.tableColumns = this.tableColumns.filter((e) => e.name != "OVERALL")
        this.compareData = (this.loggedInPlayerData && this.comparePlayerData?.LoggedPlayerParticipated && (!this.compare || this.isOwnDataView)) ? this.segmentData.map((item) => { return { name: item.name, loggedPlayer: item.loggedPlayer } }) : this.segmentDataCopy
      }
      if (this.loggedInPlayerData && this.comparePlayerData?.LoggedPlayerParticipated && (!this.compare || this.isOwnDataView)) {
        this.tableColumns.pop()
      }


    },

    handlePopup(isClose) {
      this.showModal = isClose
      this.defaultColumns = []
      this.tableColumns = []
      this.$emit("listenerChild", false)
    },

    async handleCompareToggle() {
      this.handlerState = !this.handlerState;
      const element = document.getElementById('handler')
      this.compareData = this.compareDataCopy

      // this.tableColumns = this.defaultColumns

      if (!this.handlerState) {
        element.innerText = 'OFF';
        this.compare = false
      } else {
        element.innerText = 'ON'
        this.compare = true
      }
      await this.handleTableDataAndCols(this.filters.type)
    },

    applyFilter(obj) {

      $("#table").addClass("blurry");
      // if (obj.type == 'overall' && !this.overAllData.length) {
      //   this.$emit("listenerChild", false)
      //   this.showModal = false
      //   return
      // }
      this.loader = true
      this.filters.type = obj.type
      this.tableColumns = this.defaultColumns



      this.handleTableDataAndCols(this.filters.type, false)
      setTimeout(() => {
        this.loader = false
        $("#table").removeClass("blurry");
      }, 1000)
    },




  }
};
</script>
  