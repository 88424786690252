<template>
  <page-heading>Add New Player</page-heading>

  <AdminNavigationVue />

  <div class="container">
    <div class="row">
      <div class="col-md-12 left-button mt-lg-2 mb-lg-3">
        <div class="tab tab-left">
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link
                :to="{
                  name: 'AdminResultEditPanelv2',
                  params: { eventId: eventId },
                }"
                >BACK TO RESULTS
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

   <page-loader v-if="loading" />

   <Form class="player-form" @submit="submitHandler">
    <div id="accordionDiv" v-if="addingPlayers.length">
      <ul>

        <li>
<!--   @change="selectedType = $event.target.value" -->
          <div class="row">
            <section class="col-lg-6 col-md-6">
              <div class="form-group">
                <label for="Type">Type</label>
                <Field
                  id="Type"
                  name="Type"
                  class="form-control"
                  as="select"
                
                  :class="{ 'is-invalid': errors?.Type }"
                  v-model="selectedType"
                >
                  <option :value="item.value" v-for="(item, i) in eventTypeList" :key="i">
                    {{ item.name }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors?.Type }}</div>
              </div>
            </section>

            <section class="col-lg-6 col-md-6">
              <div class="form-group">
                <label for="DayId">Day ID</label>
                <Field
                  id="DayId"
                  name="DayId"
                  as="select"
                  class="form-control"
                  :class="{ 'is-invalid': errors.DayId }"
                  v-model="SelectedDayId"
                >
                <option :value="day?.DayId" v-for="(day, i) in daysList" :key="i">
                  {{ day?.DayName + " - " + day.DayId }}
                </option>
              </Field>
                <div class="invalid-feedback">{{ errors.DayId }}</div>
              </div>
            </section>


            <section class="col-lg-3 col-md-6" v-if="selectedType === 'segEnd'">
              <div class="form-group">
                <label for="SegmentId">Segment</label>
                <Field
                  id="SegmentId"
                  name="SegmentId"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.SegmentId }"
                  v-model="selectedSegmentId"
                >
                  <option :value="j.GateId" v-for="j in gatesList(SelectedDayId)" :key="j">
                    {{ j.GateName }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors.SegmentId }}</div>
              </div>
            </section>
            </div>
        </li>

        <li v-for="(player, index) in addingPlayers" :key="index">
          <div class="row">
            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`PlayerId[${index}]`">Player ID</label>
                <Field
                  :id="`PlayerId[${index}]`"
                  :name="`PlayerId[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].PlayerId`] }"
                  v-model="player.PlayerId"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].PlayerId`] }}</div>
              </div>
            </section>

            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`TotalRepetition[${index}]`">Total Repetition</label>
                <Field
                  :id="`TotalRepetition[${index}]`"
                  :name="`TotalRepetition[${index}]`"
                  type="text"
                  class="form-control"
                  value="1"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].TotalRepetition`] }"
                  v-model="player.TotalRepetition"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].TotalRepetition`] }}</div>
              </div>
            </section>

            <section class="col-lg-3 col-md-6" v-if="selectedType !== 'segEnd'">
              <div class="form-group">
                <label :for="`StartedAt[${index}]`">Started At (HH:MM:SS.MS)</label>
                <Field
                  v-maska="'##:##:##.###'"
                  :id="`StartedAt[${index}]`"
                  :name="`StartedAt[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].StartedAt`] }"
                  v-model="player.StartedAt"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].StartedAt`] }}</div>
              </div>
            </section>

            <section class="col-lg-3 col-md-6" v-if="selectedType !== 'dayStart'">
              <div class="form-group">
                <label :for="`FinishedAt[${index}]`">Finished At (HH:MM:SS.MS)</label>
                <Field
                  v-maska="'##:##:##.###'"
                  :id="`FinishedAt[${index}]`"
                  :name="`FinishedAt[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].FinishedAt`] }"
                  v-model="player.FinishedAt"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].FinishedAt`] }}</div>
              </div>
            </section>

           

            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`CategoryId[${index}]`">Category ID</label>
                <Field
                  :id="`CategoryId[${index}]`"
                  :name="`CategoryId[${index}]`"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].CategoryId`] }"
                  v-model="player.CategoryId"
                >
                  <option :value="j" v-for="j in [1, 2, 3, 4]" :key="j">
                    Category {{ j }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].CategoryId`] }}</div>
              </div>
            </section>

            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`AvgPower[${index}]`">AVG Power</label>
                <Field
                  :id="`AvgPower[${index}]`"
                  :name="`AvgPower[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].AvgPower`] }"
                  v-model="player.AvgPower"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].AvgPower`] }}</div>
              </div>
            </section>

            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`AvgHeartRate[${index}]`">AVG HEART RATE</label>
                <Field
                  :id="`AvgHeartRate[${index}]`"
                  :name="`AvgHeartRate[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].AvgHeartRate`] }"
                  v-model="player.AvgHeartRate"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].AvgHeartRate`] }}</div>
              </div>
            </section>

            <section class="col-lg-12 col-md-6 d-flex flex-row-reverse" v-if="(index + 1) === playerCount">
              <div>
                <span class="btn btn-black" @click="AddAnotherHandler('increment')">Add Another</span>
              </div>
              <div v-if="index > 0">
                <span class="btn btn-primary" @click="AddAnotherHandler('decrement')">Cancel</span>
              </div>
            </section>
          </div>
        </li>
      </ul>

      <div class="row">
        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
          <button type="submit" class="btn btn-primary">Submit</button>
        </section>
      </div>
    </div>
  </Form>
  </div>
</template>

<style scoped>
.jerseyImage {
  width: 50px;
  height: 50px;
}


section.accordionDiv {
  padding-top: 2em;
  padding-bottom: 3em;
}

#accordionDiv ul {
  text-align: left;
}

.transition,
p,
ul li i:before,
ul li i:after {
  transition: all 0.3s;
}

#accordionDiv .no-select,
#accordionDiv h2 {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

#accordionDiv h1 {
  color: #fcb913;
  margin-bottom: 30px;
  margin-top: 0;
}

#accordionDiv h2 {
  color: #fcb913;
  font-family: "hm_light", sans-serif;
  font-size: 20px;
  line-height: 34px;
  text-align: left;
  padding: 15px 15px 0;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
}

#accordionDiv div {
  text-align: left;
  font-family: "hm_light", sans-serif;
  font-size: 14px;
  line-height: 1.45;
  position: relative;
  will-change: max-height;

  opacity: 1;
  transform: translate(0, 0);
  margin-top: 5px;
  margin-bottom: 9px;

  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  z-index: 2;
  padding-right: 10px;
  padding-left: 10px;
}

#accordionDiv ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}

#accordionDiv ul li {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

#accordionDiv ul li+li {
  margin-top: 15px;
}

#accordionDiv ul li:last-of-type {
  padding-bottom: 0;
}

#accordionDiv ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 28px;
  right: 15px;
}

#accordionDiv ul li i:before,
ul li i:after {
  content: "";
  position: absolute;
  background-color: #fcb913;
  width: 3px;
  height: 9px;
}

#accordionDiv ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}

#accordionDiv ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}

#accordionDiv ul li input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  touch-action: manipulation;
}

#accordionDiv ul li input[type="checkbox"]:checked~h2 {
  color: #000;
}

#accordionDiv ul li input[type="checkbox"]:checked~div {

  max-height: 0;
  transition: 0.3s;
  opacity: 0;

}

#accordionDiv ul li input[type="checkbox"]:checked~i:before {
  transform: translate(2px, 0) rotate(45deg);
}

#accordionDiv ul li input[type="checkbox"]:checked~i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

ul {
  padding: 0;
  list-style: none;
}

sup {
  font-size: 70%;
}
</style>

<script>
import { Field, Form } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import * as Yup from "yup";
import AdminNavigationVue from "../../../../shared/components/AdminNavigation.vue";
import {
  eventTypes
} from "../../../../shared/events/columns.dto.ts";

import { javaActionCodesEnum } from "../../../../shared/events/enums.ts";
const sanitizedPayload = require("string-values-to-numbers")
import $ from 'jquery';




export default {
  name: "AddNewPlayer",
  components: {
    Form,
    Field,
    // Multiselect
    // TopSection,
    AdminNavigationVue,
  },

  computed: {
    ...mapGetters("player", ["playerFieldsData"]),

    daysList() {
      if (this.addingPlayers.length > 1 && this.addingPlayers.length && this.addingPlayers[0].dayId) {
        return this.playerFieldsData?.ListOfDays.filter(e => e.DayId == this.addingPlayers[0].dayId)
      }
      return this.playerFieldsData?.ListOfDays || [];
    },
    jerseysList() {
      return this.playerFieldsData?.ListOfJerseys || [];
    },
    usersList() {
      return this.playerFieldsData?.ListOfUsers || [];
    },

  },
  data() {
    return {
      loading: false,
      selectedType: '',
      selectedSegmentId: '',
      eventId: this.$route.query.eventId,
      eventTypeList: eventTypes,
      addingPlayers: [
        {
          PlayerId: '',
          TotalRepetition: 1,
          StartedAt: '',
          FinishedAt: '',
          CategoryId: 0,
          AvgPower: '',
          AvgHeartRate: '',
        },
      ],
      playerCount: 1,
      errors: [],
    };
  },

  async mounted() {
    await this.getAddPlayerFieldsData({ eventId: this.eventId });
  },

  methods: {
    ...mapActions("player", ["getAddPlayerFieldsData", "fetchUsersList", "addPlayer"]),
    gatesList(day) {
      return (
        this.selectedType === "segEnd" ? this.playerFieldsData?.ListOfDays?.find((e) => e.DayId === day)?.ListOfGates : this.playerFieldsData?.ListOfGates || []
      );
    },

    AddAnotherHandler(type) {
      if (type == 'decrement') {
        this.addingPlayers.pop()
        this.playerCount -= 1
      } else {
        this.addingPlayers.push(
          {
            PlayerId: '',
            TotalRepetition: 1,
            StartedAt: '',
            FinishedAt: '',
            CategoryId: 0,
            AvgPower: '',
            AvgHeartRate: '',
          }
        )
        this.playerCount += 1
      }
    },

    async submitHandler() {
      this.loading = true
      const playerSchema = Yup.object().shape({
        PlayerId: Yup.string().required('Player ID is required'),
        StartedAt: Yup.string().required('Started At is required'),
        CategoryId: Yup.number().required('Category ID is required'),
        TotalRepetition: Yup.number().required('Total Repetition is required').min(1, 'Total Repetition must be at least 1'),
        FinishedAt: Yup.string().when('$parentType', {
          is: type => type !== 'dayStart',
          then: Yup.string().required('Finished At is required')
        }),
        AvgPower: Yup.string().required('Average Power is required'),
        AvgHeartRate: Yup.string().required('Average Heart Rate is required'),
      });

      const schema = Yup.object().shape({
        EventId: Yup.string().required('Event ID is required'),
        DayId: Yup.string().required('Day ID is required'),
        Type: Yup.string().required('Type is required'),
        SegmentId: Yup.string().when('Type', {
          is: 'segEnd',
          then: Yup.string().required('Segment Id is required')
        }),
        ListOfPlayers: Yup.array().of(playerSchema).required('List of Players is required'),
        Action: Yup.number().required('Action is required')
      });

      let finalPayload = {
        EventId: this.eventId,
        DayId: this.SelectedDayId,
        Type: this.selectedType,
        //eslint-disable-next-line
        ListOfPlayers: this.selectedType == 'dayStart' ? this.addingPlayers.map(({ FinishedAt, ...rest }) => rest) : this.addingPlayers,
        Action: javaActionCodesEnum[this.selectedType],
        CorrelationId: "1C4EEEF84B8602C6900AA29F9BE32DE0",
        DeviceId: "",
        RequestFromClient: false,
      }
      if (this.selectedType == 'segEnd') {
        finalPayload = { ...finalPayload, SegmentId: this.selectedSegmentId }
      }

      try {
        finalPayload = await schema.validate(finalPayload, { abortEarly: false, context: { parentType: this.selectedType } });
        finalPayload.ListOfPlayers = this.addingPlayers.map(player => {
          //eslint-disable-next-line
          const { FinishedAt, CategoryId, ...rest } = player;
          const filteredPlayer = {
            ...rest,
          };
          if (FinishedAt !== "") {
            filteredPlayer.FinishedAt = FinishedAt;
          }
          if (CategoryId !== 0) {
            filteredPlayer.CategoryId = CategoryId;
          }
          return filteredPlayer;
        });
        delete finalPayload.Type
        //send api call
        const response = await this.addPlayer(sanitizedPayload(finalPayload))
        response?.status != 200 ? global.app.$toast.error(response?.message ? response.message : "Error Occured") : this.$router.push({ name: "AdminResultEditPanelv2", params: { eventId: this.eventId } });

        // Handle valid form submission logic here
      } catch (validationErrors) {
        this.errors = validationErrors.inner.reduce((acc, error) => {
          const { path, message } = error;
          if (!acc[path]) acc[path] = '';
          acc[path] = message;
          return acc;
        }, {});

      }
      this.loading = false
    },

    convertDurationtoSeconds(duration) {
      const [hours, minutes, seconds] = duration.split(':');
      return (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)) * 1000;
    },

    async setUserCountry(value) {
      $('#accordionDiv').addClass('blurry');
      this.loading = true
      const result = await this.fetchUsersList({ userId: value });
      if (result?.data) {
        this.userCountryId = result.data.country
      }
      setTimeout(function () {
        $('#accordionDiv').removeClass("blurry");
      }, 600);
      this.loading = false;
    },


  },
};
</script>
