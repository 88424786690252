<template>
  <div class="row">
    <section class="col-md-12">
      <div class="table-responsive checkbox-table">
        <table class="table">
          <tr>
            <td v-for="column in columns" :key="column.name">
              <div class="form-group">
                <label>{{ column.label }}</label>
                <span>SHOW</span>
                <input type="checkbox" class="form-control" :checked="selectedColumns.includes(column.name)" @change="updateShowColumnsHandler" :id="column.name"
                       :value="column.name">
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "columnsShowHide",
  props: ['columns', 'selectedColumns'],
  methods: {
    updateShowColumnsHandler(event) {
      this.$emit('updateShowColumns', event);
    },
  }
}
</script>