import {
  formatTime,
  formatName,
  mapCountryFlag,
  mapJerseyImage,
  // mapJersey,
  wattPerKgFormat,
  powerFormat,
  mapCategory,
} from "@/shared/events/event.helpers.ts";

export const columns = [
  { id: "rank", label: "RANK", width: 75, orderable: false },
  {
    id: "userFullName",
    thClassName: "no-sort",
    className: "tx-l",
    label: "RIDER",
    format: (name) => formatName(name),
    width: 75,
    orderable: false,
  },
  {
    id: "userCountryFlag",
    thClassName: "no-sort",
    className: "text-center",
    label: "COUNTRY",
    format: (id) => mapCountryFlag(id),
    width: 75,
    orderable: false,
  },
  {
    id: "jerseyImage",
    thClassName: "no-sort",
    className: "text-center",
    label: "JERSEY",
    width: 50,
    format: (url) => mapJerseyImage(url),
    orderable: false,
  },
  // {"id": "jerseyId", "thClassName": "no-sort", "label": "JERSEY", "width": 50, "format" : id => mapJersey(id), "orderable": true},
  {
    id: "categoryId",
    thClassName: "no-sort",
    label: "Category",
    width: 50,
    format: (id) => mapCategory(id),
    orderable: false,
  },
  { id: "teamName", label: "TEAM NAME", width: 200, orderable: false },
  {
    id: "points",
    label: "Points",
    width: 75,
    orderable: false,
    defaultValue: 0,
  },
  {
    id: "finishedTime",
    thClassName: "no-sort",
    label: "OVERALL TIME",
    width: 100,
    format: (time, eventTime) => formatTime(time, eventTime),
    time: "gapTimeNew",
    orderable: false,
  },
  {
    id: "wattPerKG",
    label: "AVG (W/KG)",
    width: 75,
    format: (v) => wattPerKgFormat(v),
    orderable: false,
  },
  {
    id: "avgPower",
    label: "AVG (W)",
    width: 75,
    format: (v) => powerFormat(v),
    orderable: false,
  },
  { id: "avgHeartRate", label: "AVG (HR)", width: 75, orderable: false },
  { id: "userWeight", label: "Weight", width: 75, orderable: false },
  { id: "type", label: "Type", width: 75, orderable: false },
];

export const columnsV2 = [
  { id: "userId", label: "", width: 75, orderable: false },

  { id: "rank", label: "RANK", width: 75, orderable: false },
  {
    id: "userFullName",
    thClassName: "no-sort",
    className: "tx-l",
    label: "RIDER",
    format: (name) => formatName(name),
    width: 75,
    orderable: false,
  },
  {
    id: "userCountryFlag",
    thClassName: "no-sort",
    className: "text-center",
    label: "COUNTRY",
    format: (id) => id,
    width: 75,
    orderable: false,
  },
  {
    id: "jerseyImage",
    thClassName: "no-sort",
    className: "text-center",
    label: "JERSEY",
    width: 50,
    format: (url) => mapJerseyImage(url),
    orderable: false,
  },
  // {"id": "jerseyId", "thClassName": "no-sort", "label": "JERSEY", "width": 50, "format" : id => mapJersey(id), "orderable": true},
  {
    id: "categoryId",
    thClassName: "no-sort",
    label: "Category",
    width: 50,
    format: (id) => id,
    orderable: false,
  },
  { id: "teamName", label: "TEAM NAME", width: 200, orderable: false },
  {
    id: "points",
    label: "Points",
    width: 75,
    orderable: false,
    defaultValue: 0,
  },
  {
    id: "finishedTime",
    thClassName: "no-sort",
    label: "OVERALL TIME",
    width: 100,
    orderable: false,
  },
  {
    id: "wattPerKG",
    label: "AVG (W/KG)",
    width: 75,
    format: (v) => wattPerKgFormat(v),
    orderable: false,
  },
  {
    id: "avgPower",
    label: "AVG (W)",
    width: 75,
    format: (v) => powerFormat(v),
    orderable: false,
  },
  { id: "avgHeartRate", label: "AVG (HR)", width: 75, orderable: false },
  { id: "userWeight", label: "Weight", width: 75, orderable: false },
  { id: "type", label: "Type", width: 75, orderable: false },
  { id: "selectionStatus", label: "Type", width: 75, orderable: false },
];

// watts columns
export const wattPerKg = [
  { id: "wattsPerKG5sec", label: "5S W/kg", format: (v) => wattPerKgFormat(v) },
  {
    id: "wattsPerKG15sec",
    label: "5S W/kg",
    format: (v) => wattPerKgFormat(v),
  },
  {
    id: "wattsPerKG20sec",
    label: "20S W/kg",
    format: (v) => wattPerKgFormat(v),
  },

  { id: "wattsPerKG1min", label: "1M W/kg", format: (v) => wattPerKgFormat(v) },
  { id: "wattsPerKG5min", label: "5M W/kg", format: (v) => wattPerKgFormat(v) },
  {
    id: "wattsPerKG20min",
    label: "20M W/kg",
    format: (v) => wattPerKgFormat(v),
  },
];

// watts/kg columns
export const wattsData = [
  { id: "watts5sec", label: "5S WATTS", format: (v) => powerFormat(v) },
  { id: "watts15sec", label: "15S WATTS", format: (v) => powerFormat(v) },
  { id: "watts20sec", label: "20S WATTS", format: (v) => powerFormat(v) },
  { id: "watts30sec", label: "30S WATTS", format: (v) => powerFormat(v) },

  { id: "watts1min", label: "1M WATTS", format: (v) => powerFormat(v) },
  { id: "watts3min", label: "3M WATTS", format: (v) => powerFormat(v) },
  { id: "watts5min", label: "5M WATTS", format: (v) => powerFormat(v) },
  { id: "watts10min", label: "10M WATTS", format: (v) => powerFormat(v) },
  { id: "watts20min", label: "20M WATTS", format: (v) => powerFormat(v) },
];
