<template>
<page-heading>
  List of Users
</page-heading>
<AdminNavigation />
<div class="container-fluid btn-wrap">
  <div class="row">
    <div class="col-md-12">
      <div class="white-top-bg">


        <AdminNavigation v-if="loggedIn" />

        <div class="row justify-content-md-center">
          <!-- <div class="col-sm-6 mb-10 tx-l">
           
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="filterResult()"  
                >
                 Add New
                </button>
              </div>
            </div> -->
          <!-- Search box -->
          <!-- <div class="col-sm-6 mb-10 tx- col-md-2">
              <div class="input-group">
                <input type="text" class="form-control col-md-9" placeholder="Search A User..." v-model="searchText" @keyup="search">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" @click="searchEvents" :disabled="searchText.length < 3">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div> -->

          <!-- Reset filters button-->
          <!-- <div class="col-sm-6  mb-10 tx-c col-md-1">
              <button type="button" v-if="this.isResetBtnVisible" class="btn btn-outline-primary"
                      @click="filterResult('reset','all')">RESET
              </button>
            </div> -->
        </div>


      </div>
    </div>

  </div>
</div>
<div class="container">
  <div class="table-responsive individual-result event-list table-wrap">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" >Actions</th>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="14">
              <page-loader />
            </td>
          </tr>
          <tr class='eventRow' v-for="(user, k) in usersList" :key="k">
            <td scope="row"  v-if="this.user.role == roles[0]">
              <button  type="button" class="points-btn btn-sm"  @click="updateAdmin(user.id, user.email)">Update Password</button>
              &nbsp;
              <button  type="button" class="btn btn-danger btn-sm" @click="deleteAdmin(user.id, user.email)">Delete</button>
            </td>
            <td v-else>
               <button  v-if="this.user.id == user.id" type="button" class="points-btn btn-sm"  @click="updateAdmin(user.id, user.email)">Update Password</button>
               <span v-else> - </span>
            </td>
            <td scope="row">{{ rowNumber(k) }}</td>
            <td scope="row">{{ user.name }}</td>
            <td scope="row">{{ user.email }}</td>
            <td scope="row">{{ user.role }}</td>
            <td scope="row">{{ getDate(user.created_at) || '-' }}</td>
            <td scope="row">{{ getDate(user.updated_at) || '-' }}</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-3 " v-if="!usersList.length" style="text-align: center">
        {{ loading ? "Loading.... " : "No Admin users are found" }}</p>
    </div>
</div>
    
<div v-if="usersList.length" class="pagination justify-content-center">
  <pagination v-model="currentPage" :records="totalRecords" :per-page="limit" @paginate="loadUsersList" />
</div>

</template>

<style scoped>
.btn-primary {
  color: black;
}

#accordionDiv {
  width: 100%;
  padding-right: 120px;
  padding-left: 120px;
  margin-right: auto;
  margin-left: auto;

}

#accordionDiv div {

  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
  position: relative;
  opacity: 1;
  transform: translate(0, 0);
  margin-bottom: 20px;
}
</style>
<script>
import { mapActions, mapGetters } from 'vuex';

import Pagination from 'v-pagination-3';
import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import sweetAlert from "@/services/alert.ts";
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'

export default {
  name: 'ListAllAdminUsers',
  components: { Pagination, AdminNavigation, },
  data() {
    return {
      loading: false,
      usersList: [],
      totalPage: 1,
      currentPage: 1,
      limit: 10,
      totalRecords: 0,
      roles: ['whoosh-super-admin', 'whoosh-admin'],
    }
  },

  computed: {
    //   ...mapGetters('adminUserLists', ['getAdminUsers']),
    ...mapGetters('auth', ['loggedIn', 'user']),
  },

  async mounted() {
    this.getAdminUsersList()
  },

  methods: {
    ...mapActions("adminUser", ["getAdminUsers", "deleteAdminUser", "udpateAdminUserPassword"]),

    rowNumber(k) {
      return (k + 1) + ((this.currentPage - 1) * this.limit)
    },


    loadPageData(page) {
      this.currentPage = parseInt(page.toString());
      this.getAdminUsersList();
    },

    getTotalPage(total) {
      this.totalRecords = total;
      let page = (total >= this.limit ? total / this.limit : total > 0 ? 1 : 0).toString();
      let i = parseInt(page) % 1;
      this.totalPage = parseInt(page + "") + (i > 0 ? 1 : 0);
    },

    getDate(dateTime) {
      const dt = new Date(dateTime);
      return dt.toLocaleString();
    },

    getTime(dateTime) {
      return dateTime.split(' ')[1];
    },

    async getAdminUsersList() {

      this.loading = true;
      const filters = {
        page: this.currentPage
      }
      const response = await this.getAdminUsers(filters);
      this.usersList = response.data.usersArr

      this.getTotalPage(response.data.total)
      this.loading = false;
    },

    loadUsersList(currentPage = 1) {
      this.currentPage = currentPage;
      this.getAdminUsersList();
    },

    async deleteAdmin(id, email) {
      const payload = { id: id, email: email }
      sweetAlert.confirm('Are you sure?',
        `This Action will delete admin user: ${email}`,
        'Yes',
        'No',
        null,
        async () => {
          await this.deleteAdminUser(payload)
          this.currentPage = 1
          await this.getAdminUsersList()
        });

    },

    async updateAdmin(id, email) {
      const { value: formValues } = await Swal.fire({
        title: 'Update Admin User Password',
        html:
          '<input id="swal-input1" class="swal2-input" type="password" placeholder="Enter Password">' +
          '<input id="swal-input2" class="swal2-input" type="password" placeholder="Enter Confirm Password">' +
          '<span id="error-msg" class="swal2-validation-message" style="display: none;"> </span>'
        ,
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          let data = {
            password: document.getElementById('swal-input1').value,
            confirmPassword: document.getElementById('swal-input2').value
          }
          let input = document.getElementById('error-msg')
          input.style.display = 'flex'
          if (!data.password || !data.confirmPassword) {
            input.innerHTML = "Enter Passwords"
            return false
          } else if (data.password != data.confirmPassword) {
            input.innerHTML = "Please Match the passwords"
            return false
          } else {
            input.classList.remove("swal2-validation-message");
            return data
          }
        },
      })
      if (formValues) {
        await this.udpateAdminUserPassword({ ...formValues, id, email })
      }

    }
  },

};
</script>
