<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}


.section-main {
  padding: 52px 20px 70px 20px;
}

.section-main.blue-bg {
  background-color: #000;
}


@font-face {
  font-family: "Thunder";
  src: url("../../../public/themeV2/custom-fonts/Thunder-ExtraBoldLC.ttf");
}

@font-face {
  font-family: "ThunderExtraBold";
  src: url("../../../public/themeV2/custom-fonts/Thunder-ExtraBoldLC.ttf");
}

.section-main h1 {
  font-family: "Thunder" !important;
  font-size: 80px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 35px 0;
  text-transform: uppercase;
}

.section-main select {
  background: #ffffff;
  border: 1.4px solid transparent;
  width: 50%;
  padding: 14px 20px;
  margin: 8px 5px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
  float: right;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.16px;
  color: #000;
}

.section-main select.sel-cutom {
  background: #ffffff;
  border: 1.4px solid transparent;
  width: 50%;
  padding: 16px 20px;
  margin: 8px 5px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #00b4e3;
  color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
  float: left;
  font-family: Poppins;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.section-main select.sel-cutom strong {
  font-weight: 900;
}

.section-main .div-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .div-flex .col-left {
  width: 50%;
  height: auto;
  margin: auto;
  z-index: 1;
}

.section-main .div-flex .col-right {
  width: 50%;
  height: auto;
  margin: auto;
}


.black-bar {
  min-height: 90px;

  margin-bottom: 0px;
}

.black-bar .div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.black-bar input.search-input {

  padding: 15px;
  width: 300px;

  color: #59636b;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}


.black-bar .btn-men-women button {
  background: #fcb913;
  color: #000000;
}

.black-bar .btn-men-women button.gender-btn-active {
  background: #fcb913;
  color: #000000;
}

.customDrodown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customDrodown select {
  font-family: "Inter" !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  width: 204px;

  appearance: none;
  padding: 15px;
  outline: 0;

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 14px;
}

.black-bar .customDrodown .dropDownIcon {

  position: static;
  border-left: 1px solid #000;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  margin: 0 0 0 -35px;
}

.black-bar .btn-men-women button {
  font-family: "Inter" !important;
  line-height: 19px;
  text-align: center;
  background: #e1e1e1;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  padding: 10px;
  width: 183.19px;
  height: 57px;
  border: none;
  text-transform: uppercase !important;
}

.black-bar .btn-men-women button:hover {
  background: #ffb91d;
  color: #000000;

}


.black-bar label input {
  display: none !important;
}

.black-bar label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000000;
}

.black-bar span {
  display: block;
  width: 25px;
  height: 25px;
  background: url("../../../public/assets/unofficalBadge.svg");
  background-repeat: no-repeat;
  top: 1px;
  position: relative;
  margin: 4px auto;
  background-size: contain;
}

.black-bar input:checked+span {
  width: 25px;
  height: 25px;
  background: url("../../../public/assets/officalBadge.svg");
  background-repeat: no-repeat;
  top: 1px;
  position: relative;
  margin: 4px auto;
  background-size: contain;
}

.black-bar .search .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.black-bar .search .row .col {
  margin: 0 1rem;
}

.search-wrpr {
  position: relative;
}

.search-wrpr img {
  position: absolute;
  top: 16px;
  right: 15px;
}


@media screen and (max-width: 1920px) {
  .small-bld-blk:before {
    left: 28%;
  }

  .small-bld-blk:after {
    right: 28%;
  }
}

@media screen and (max-width: 1366px) {
  .small-bld-blk:before {
    left: 20%;
  }

  .small-bld-blk:after {
    right: 20%;
  }

  .section-wrap {
    padding: 12px 12px;
  }

  .event-more {
    background: url('../../../public/themeV2/assets/images/hero-slider-v1.jpg');
    min-height: 38vh;
  }
}

@media screen and (max-width: 1200px) {
  .small-bld-blk:before {
    left: 30%;
  }

  .small-bld-blk:after {
    right: 30%;
  }

  .small-bld-blk {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg {
    font-size: 28px;
    line-height: 33px;
  }

  .heading-med {
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1024px) {

  .customDrodown,
  .customDrodown select {
    width: 100% !important;
  }

  .black-wrpr .div-flex .d-flex .btn-men-women.mr-4 {
    display: flex;
  }

  .black-bar .div-flex .search .col {
    margin: 10px 0;
  }

  .cards-sections .card {
    width: 100%;
  }

  .recentPostsLeftSide {
    padding-top: 12px !important;
    max-width: 100%;
  }

  .recentPostsRightSide {
    max-width: 100%;
    padding: 0px;
    margin: 8px 0;
  }

  .customTableStiped {
    position: relative;
    margin: 0 auto;
  }
}

@media screen and (min-width: 747px) {
  .cards-sections .card-container {
    display: flex;
    flex-wrap: wrap;
  }



  .cards-sections-daily-event .card-container {
    display: flex;
    flex-wrap: wrap;
  }


}

@media screen and (max-width: 1024px) {
  input.search-input {
    width: 100% !important;
  }

  .div-flex.filters {
    display: block !important;
  }

  .search-wrpr img {
    right: 15px;
  }
}

@media screen and (max-width: 991px) {
  .tab-chart button {
    width: 100%;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-blk:before {
    display: none;
  }

  .small-bld-blk::after {
    display: none;
  }

  .btn-regi {
    float: none;
    padding: 28px 25px;
    margin: 0 auto;
    border: none;
    width: 100%;
  }

  .card-box-info ul li {
    width: 100%;
    padding: 20px;
    margin: 5px auto;
  }

  .btn-wrpr button {
    font-size: 14px;
    padding: 18px 26px;
    margin: 4px auto;
    width: 83%;
  }

  .small-bld-text {
    font-size: 14px;
    line-height: 16px;
  }

  .heading-lrg-txt {
    font-size: 32px;
    line-height: 36px;
  }

  .event-wrapper button {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .cards-sections-daily-event .card {
    margin: 8px auto;
    padding: 6px;
    width: 95%;
  }

  .breadcrumb {
    padding: 5px;
    height: 85px;
    width: 100%;
    position: absolute;
    bottom: 2%;
  }

  .tab-regicontent p {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-left {
    width: 100%;
  }

  .tab-regicontent .div-flex .col-right {
    width: 100%;
  }

  .multi-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-flex.btn-back-next .col-right {
    text-align: center;
  }

  .div-flex.btn-back-next .col-left .btn-back {
    width: 90%;
    margin: 6px 0;
  }

  .div-flex.btn-back-next .col-right .btn-next {
    width: 90%;
    margin: 6px 0;
  }

  .section-chart select {
    width: 62%;
  }

  .btn-wrpr {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
  }

  .tab-regi-wrpr h1 {
    margin: 20px 10px 30px 10px;
  }

  .tab-regi button {
    width: 100%;
  }

  .btn-upload {
    display: block;
    text-align: center;
  }

  .btn-upload button {
    margin: 0 auto;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }
}

@media (max-width: 1199.98px) {
  .search-wrpr img {
    right: 45px;
  }



  .routes img.intro-arrow-desktop-black {
    float: none;
    margin: 0 auto;
  }

  .div-flex .col-left {
    width: 100%;
  }

  .div-flex .col-right {
    width: 100%;
  }

  .section-wrap {
    padding: 120px 20px;
  }


  .owl-custom table thead tr th {
    font-size: 0.7rem;
  }

  .owl-custom table tbody tr td {
    font-size: 0.7rem;
  }

  .col-right h2 {
    text-align: inherit;
  }


  .main-card .heading-small {
    justify-content: center;
  }

  .card-btn.txt-rgt {
    text-align: center;
  }

  .stage-icon-text {
    margin: 12px;
  }

  .black-bar .div-flex {
    height: auto;
    padding: 12px;
  }

  .black-bar .search .row {
    flex-direction: column;
  }

  .card-box-info-overview ul li {
    width: 95%;
  }

  .live-section {
    height: auto;
  }

  .tab-live-wrpr h1 {
    padding: 12px;
  }

  .tab-live-wrpr {
    margin: 12px;
  }

  .live-tbl .table-outer {
    overflow: scroll;
  }

  .section-main select {
    width: 100%;
    margin: 5px auto;
  }

  .section-main select.sel-cutom {
    width: 100%;
    margin: 5px auto;
  }

  .list-indivi {
    width: 96%;
    margin: 12px;
  }

  .list-indivi ul {
    flex-direction: column;
  }

  .list-indivi ul li {
    padding: 11px;
    display: inline-block;
    width: 100%;
  }

  .sprint-btn a {
    margin: 2px auto;
  }

  .rank-tbl .table-outer {
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .stage-wrpr {
    height: 100% !important;
  }

  .stage-icon-text {
    position: relative !important;
  }

  .stage-wrpr .div-flex {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #b1b1b1;
    padding: 10px;
  }

  .sectionHeadingMain {
    font-size: 32px !important;
    line-height: 35px !important;
    margin-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    height: 140px;
    margin-right: 18px;
    width: 100% !important;
    margin: 0 auto;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;
    width: 100%;
    margin: 0px;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .section-card.grey {
    background: #f2f2f2;
    margin: 12px 0;
    padding: 12px 0;
  }

  .section-card {
    background: #f2f2f2;
    margin: 12px 0;
    padding: 12px 0;
  }



  .section-main h1 {

    font-size: 50px;

  }

  .black-bar .btn-men-women button {
    width: 128.19px;
  }

  .black-bar .customDrodown .dropDownIcon {
    display: none;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }

  .div-flex.filters {
    display: block !important;
  }

  input.search-input {
    width: 100% !important;
  }
}

@media screen and (max-width: 575px) {
  .sectionHeadingMain {
    font-size: 32px !important;
    line-height: 35px !important;
    margin-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .stage-icon-text.cal-bg-sky {
    margin-top: 10px;
  }

  .img-race {
    height: 140px;
    margin-right: 18px;
    width: 100% !important;
    margin: 0 auto;
  }

  .stage-detail {
    width: 100%;
  }

  .stage-icon-text.cal-bg-sky {
    position: relative;
    width: 100%;
    margin: 0px;
  }

  .stage-icon-image {
    margin-top: 0px;
  }

  .section-card.grey {
    background: #f2f2f2;
    margin: 12px 0;
    padding: 12px 0;
  }

  .section-card {
    background: #f2f2f2;
    margin: 12px 0;
    padding: 12px 0;
  }





  .black-bar .btn-men-women button {
    width: 128.19px;
  }

  .black-bar .customDrodown .dropDownIcon {
    display: none;
  }

  .event-wrapper button {
    padding: 12px 26px;
  }

  .event-banner {
    min-height: 100%;
  }

  .register-banner {
    min-height: 100%;
  }

  .event-more {
    min-height: 100%;
    background-position: 9% 37% !important;
  }

  .section-wrap {
    padding: 247px 20px !important;
  }

  .card-box-info ul li {
    width: 90%;
  }

  .elevation {
    padding: 12px;
  }

  .Segments {
    padding: 12px;
  }

  .heading-m-1 {
    margin: 15px 7px;
  }

  .text-info p {
    padding: 70px 18px;
  }

  .tab-chartcontent {
    padding: 55px 15px;
  }

  .chart-bar-wrpr .bar-position-1 h1,
  .chart-bar-wrpr .bar-position-2 h1,
  .chart-bar-wrpr .bar-position-3 h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .chart-bar-wrpr .bar-position-1 h1 span,
  .chart-bar-wrpr .bar-position-2 h1 span,
  .chart-bar-wrpr .bar-position-3 h1 span {
    position: relative;
    font-size: 20px;
    line-height: 22px;
    top: 0px;
  }

  .list-prize ul li span.num {
    font-size: 26px;
    line-height: 28px;
  }

  .list-prize ul li span.money {
    font-size: 26px;
    line-height: 28px;
  }

  p.prize {
    font-size: 17px;
    line-height: 20px;
    color: #7051c9;
    position: absolute;
    top: -29px;
  }

  .txt-wrapper {
    text-align: center;
    margin: 3rem 0 0 0;
  }

  .small-bld-text {
    font-size: 24px;
    line-height: 38px;
  }

  .cards-sections .card {
    margin: 12px;
    padding: 6px;
    width: 94%;
  }

  .loadmore {
    margin: 20px auto;
    padding: 22px 65px;
  }

  .tab-live button {
    width: 100%;
  }

  .section-main .div-flex .col-left {
    width: 100%;
  }

  .section-main .div-flex .col-right {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .search-area .d-flex {
    display: block !important;
  }

  .btn-men-women.mr-4 button {
    width: 100% !important;
  }

  .btn-men-women {
    width: 100% !important;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div
    class="page-template page-template-page-how-it-works page-template-page-how-it-works-php page page-id-22 page-how-it-works home"
    data-aos-easing="ease-in"
    data-aos-duration="600"
    data-aos-delay="0"
  >
    <div class="dashed-line dashed-line-1"></div>
    <div class="dashed-line dashed-line-2"></div>
    <div class="dashed-line dashed-line-3"></div>
    <div class="dashed-line dashed-line-4"></div>
    <div class="dashed-line dashed-line-5"></div>

    <div class="homepage-wrap clearfix">
    

      <page-loader v-if="loader" style="margin-top: 50px" />

      
      <div class="section-main blue-bg pattern-bg">
        <div class="container">
          <h1>MyWhoosh Event Results</h1>
        </div>
      </div>
       
      <div class="black-bar">
        <div class="container">
          <div class="black-wrpr">
            <div class="div-flex filters">
              <div class="search-area">
                <div class="d-flex">
                  <div class="btn-men-women mr-4">
                    <button
                      :class="filters.gender == 1 ? 'gender-btn-active' : ''"
                      @click="setFilters({ gender: 1 })"
                    >
                      Men
                    </button>
                    <button
                      :class="filters.gender == 2 ? 'gender-btn-active' : ''"
                      @click="setFilters({ gender: 2 })"
                    >
                      Women
                    </button>
                  </div>
                  <div class="customDrodown">
                    <select
                      name=""
                      id=""
                      @change="filterListByCategory($event.target.value)"
                    >
                      <option value="all">All</option>
                      <option value="open">Open</option>
                      <option value="category-1">Cat 1</option>
                      <option value="category-2">Cat 2</option>
                      <option value="category-3">Cat 3</option>
                      <option value="category-4">Cat 4</option>
                    </select>
                    <!--                    <div class="dropDownIcon">-->
                    <!--<img src="../../../../public/assets/dropDownIcon.svg" />-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="search">
                <div class="row">
                  <!--                  <div class="col">-->
                  <!--                    <label>-->
                  <!--                      <input-->
                  <!--                        type="radio"-->
                  <!--                        class="radio-item"-->
                  <!--                        value=""-->
                  <!--                        name="item"-->
                  <!--                        id="radio1"-->
                  <!--                        checked="checked"-->
                  <!--                        disabled-->
                  <!--                      />-->
                  <!--                      <span></span>-->
                  <!--                      Official-->
                  <!--                    </label>-->
                  <!--                  </div>-->
                  <!--                  <div class="col">-->
                  <!--                    <label>-->
                  <!--                      <input-->
                  <!--                        type="radio"-->
                  <!--                        class="radio-item"-->
                  <!--                        value=""-->
                  <!--                        name="item"-->
                  <!--                        id="radio2"-->
                  <!--                        disabled-->
                  <!--                      />-->
                  <!--                      <span></span>-->
                  <!--                      Unofficial-->
                  <!--                    </label>-->
                  <!--                  </div>-->
                  <div class="col">
                    <div class="search-wrpr">
                      <input
                        class="search-input"
                        type="text"
                        placeholder="SEARCH.."
                        v-model="searchFieldText"
                        @keyup.enter="setFilters({ search: searchFieldText })"
                      />
                      <img
                        src="../../../public/themeV2/assets/images/search-dark-icon.png"
                        @click="setFilters({ search: searchFieldText })"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end black bar with search-->

      <h2
        class="text-center mt-4"
        v-if="!liveEventsData?.length &&
          !srcEventData?.length &&
          !seriesEventsData?.length &&
          !normalEventsData?.length &&
          !loader
          "
      >
        No results are found for your search criteria.
      </h2>

      <LiveEvents
        :eventsData="liveEventsData"
        :filters="filters"
        v-if="liveEventsData?.length"
        @listenerChild="listenerChild"
      />

      <!-- SERIES EVENTS LIST -->
      <SeriesEventsList
        v-if="seriesEventsData.length"
        :eventsData="seriesEventsData"
      />
      <!-- END OF SERIES EVENTS LIST -->

      <!-- Event Cards Start -->
      <EventCard
        v-if="srcEventData.length"
        :srcEvents="srcEventData"
        :isMobileDevice="isMobileDevice"
      />
      <!-- END of Event Cards Start -->

      <!-- NORMAL EVENTS LIST -->
      <AllEventsList
        v-if="normalEventsData.length"
        :eventsData="normalEventsData"
        :isNoMoreDataBtn="isNoMoreDataBtn"
        @listenerChild="listenerChild"
        @listenLoadMoreBtn="listenLoadMoreBtn"
      />

      <!-- END OF NORMAL EVENTS LIST -->
    </div>

    <div class="progress-bar-wrap">
      <div id="progress-bar" style="--scroll: 0%"></div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<style
  lang="scss"
  scoped
  src="../../theme/styles/pages/user/event-results/index.scss"
></style>

<script>
import { mapActions } from "vuex";
import EventCard from "../../theme/components/cards/EventCard.vue";
import AllEventsList from "../../theme/components/events-list/AllEvents.vue";
import LiveEvents from "../../theme/components/events-list/LiveEvents.vue";
import SeriesEventsList from "../../theme/components/events-list/SeriesEvents.vue";

export default {
  name: "EventList",

  components: {
    EventCard,
    SeriesEventsList,
    AllEventsList,
    LiveEvents,
  },
  data() {
    return {
      srcEventData: [],
      seriesEventsData: [],
      isMobileDevice: false,
      normalEventsData: [],
      liveEventsData: [],
      searchFieldText: "",
      selectedCategory: "all",
      filters: {
        status: "all",
        isFeatureEvents: null,
        eventRoute: null,
        category: "all",
        page: 1,
      },
      isNoMoreDataBtn: false,
      loader: true,
    };
  },

  async mounted() {
    window.scrollTo(0, 0)
    this.isMobileDevice = window.innerWidth <= 991;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    await this.getLiveEventsList();
    await this.getSrcEvents();
    await this.getAllEventsList();
  },

  methods: {
    ...mapActions("event", [
      "getSrcEventsList",
      "getAllEvents",
      "getLiveEvents",
    ]),

    onResize() {
      this.isMobileDevice = window.innerWidth <= 991;
    },

    //api's call
    async getSrcEvents() {
      const response = await this.getSrcEventsList({ filters: this.filters });
      this.srcEventData = response?.srcEvents || [];
      this.seriesEventsData = response?.activeSeriesEvent || [];
      this.loader = false;
    },

    async getAllEventsList() {
      this.loader = true;
      const response = await this.getAllEvents({
        isLive: 0,
        filters: this.filters,
      });

      this.normalEventsData = response?.data || [];
      this.isNoMoreDataBtn =
        response?.pageLimit * response?.currentPage < response?.total
          ? false
          : true;

      this.noResultsDataMessage = !response?.data?.length
        ? "Sorry, No Data found with your selected filters"
        : "";
      this.loader = false;
    },

    async getLiveEventsList() {
      const response = await this.getLiveEvents({ filters: this.filters });
      this.liveEventsData = response?.data;
    },

    async setFilters(targetObject) {
      this.loader = true;
      this.filters.page = 1;
      this.filters = { ...this.filters, ...targetObject };
      await this.getLiveEventsList();
      await this.getSrcEvents();
      await this.getAllEventsList();
    },

    listenerChild(childResponseObj) {
      this.loader = childResponseObj.childLoader;
    },

    async listenLoadMoreBtn() {
      this.loader = true;
      this.filters.page = this.filters.page + 1;
      const response = await this.getAllEvents({
        isLive: this.isLive,
        filters: this.filters,
      });
      this.isNoMoreDataBtn =
        response?.pageLimit * response?.currentPage < response?.total
          ? false
          : true;
      this.noResultsDataMessage = !response?.data?.length
        ? "Sorry, No Data found with your selected filters"
        : "";
      this.normalEventsData = [...this.normalEventsData, ...response.data];
      this.loader = false;
    },

    async filterListByCategory(selectedCategory) {
      this.loader = true;
      if (selectedCategory != this.filters.category) {
        this.filters.page = 1;
      }
      this.filters.category =
        selectedCategory == "all" ? null : selectedCategory;
      await this.getLiveEventsList();
      await this.getSrcEvents();
      await this.getAllEventsList();
      this.loader = false;
    },
  },
};
</script>
