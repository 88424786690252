<template>
  
  <page-loader v-if="!event" style="margin-top: 50px" />
  <EventResult v-if="!isUCIEvent && event" :eventData="event" :isUCIEvent="isUCIEvent" />  
  <UCIEventResult v-else-if="isUCIEvent && event" :eventData="event" :isUCIEvent="isUCIEvent"/>
 
   
</template>

<style scoped>
@import url("@/theme/style/carousel.scss");

#test {
  min-height: calc(100vh - 50px);
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import EventResult from "./EventResult.vue";
import UCIEventResult from "./UCIEventResult.vue";
import { EventFormatType } from "../../shared/events/enums.ts";

export default {
  name: "EventResultIndex",
  components: {
    EventResult,
    UCIEventResult,
  },
  data() {
    return {
      event: null
    }
  },
  computed: {
    ...mapGetters("event", ["gateList"]),
    isLive() {
      return this.$route.query.isLive == 1 || this.$route.query.isLive == "true" ? true : false;
    },
    isUCIEvent() {
      return (
        this.event?.EventFormatType === EventFormatType.POINTS_BASED &&
        this.event?.EventType === 8
      );
    },
  },

  async mounted() {
    window.scrollTo(0, 0)
    const { eventId } = this.$route.query;
    this.event = await this.getEvent(eventId)
    //get gates data for all days
    await this.getGateList({ eventId: eventId });
    //set data for live graph
    sessionStorage.setItem(
      "graphDays",
      JSON.stringify(this.gateList?.days?.find((x) => x.DayId === this.gateList.activeDayId) || []));
  },
  methods: {
    ...mapActions("event", ["getGateList", "getEvent"]),
  },
};
</script>
