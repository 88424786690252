<style scoped>
.tab {
  column-gap: 48px;
}

.stage-wrap {

  justify-content: flex-end;
  display: inline-flex;
}
</style>
<template>

  <page-heading v-if="isStages && isSeriesEvent">
    {{ event?.name }} | Stage {{ parseInt(currentStage) + 1 }} of {{ stages.length }}
  </page-heading>
  <page-heading v-else>{{ event?.name }}</page-heading>

  <p class="text-center datetime" v-if="isStages && isSeriesEvent">
    {{ getStageDate() }}
  </p>
  <p class="text-center datetime" v-else>
    {{ event?.datetime ? getDate(event?.datetime) : "" }}
  </p>

  <AdminNavigation :eventId="eventId" :event="event"
                   :filterResult="filterResult" :eventListPage="$route.params.eventListPage"
                   :playersCount="playersCount"
                   :isSeriesEvent="isSeriesEvent"
                   :isStages="isStages"
                   :currentDayId="currentDayId" />

  <div class="container overflow-hidden">
    <div class="rankingCard btn-wrap">
      <div class="white-top-bg">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 mb-10 tx-c">
            <div class="btn-group btn-css" role="group" aria-label="Basic example">
              <button type="button" v-for="(data, k) in eventCategories" :key="k" class="btn btn-outline-secondary"
                      @click="applyFilters({ categoryId: data.CategoryId })">
                <strong>{{ data.Label }}</strong>
              </button>

              <button
                  type="button" :class="{
                    'd-none': this.activeFilters &&
                      Object.keys(this.activeFilters).length === 0 &&
                      Object.getPrototypeOf(this.activeFilters) === Object.prototype
                  }"
                  class="btn btn-outline-secondary" @click="applyFilters({}, true)">
                RESET
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row align-items-center">

      <div class="col-md-12">
        <div class="white-top-bg pb-0">
          <div class="row">


            <div class="col-md-12">


              <div class="tab tab-left weight-tab">
                <ul class="list-inline" v-show="isSeriesEvent">
                  <li class="list-inline-item">
                    <a :class="!isStages && 'active-tab'" href="#"
                       @click.prevent="toggleStages(false)">
                      OVERALL
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a :class="isStages && 'active-tab'" href="#"
                       @click.prevent="toggleStages(true)">
                      STAGE
                    </a>
                  </li>
                </ul>

                <div class="stage-wrap" v-show="isSeriesEvent">
                  <button class="arrow-btn" @click="updateStage('prev')">
                    <img src='/assets/arrow-left.png'/>
                  </button>
                  <select @change="updateStage" v-model="currentStage">
                    <option :value="k" v-for="(stage, k) in stages" :key="k">{{ stage.DayName }}:
                      {{ stage.StartDate }}
                    </option>
                  </select>
                  <button class="arrow-btn" @click="updateStage('next')">
                    <img src='/assets/arrow-right.png'/>
                  </button>
                </div>


                <ul class="list-inline" v-show="isSeriesEvent">
                  <li class="list-inline-item" v-show="isStages">
                    <a href="#"
                       @click.prevent="reloadPlayersHandler()">
                      RELOAD STAGE
                    </a>
                  </li>
                  <li class="list-inline-item" v-show="!isStages">
                    <a href="#"
                       @click.prevent="reloadPlayersHandler(true)">
                      RELOAD OVERALL
                    </a>
                  </li>
                  <li class="list-inline-item" v-show="isStages">
                    <a href="#"
                       @click.prevent="publishEventResultHandler('un-official')"
                       v-if="event?.publish_stages?.includes(currentDayId)">
                      UN-PUBLISH STAGE
                    </a>

                    <a href="#"
                       @click.prevent="publishEventResultHandler('official')" v-else>
                      PUBLISH STAGE
                    </a>
                  </li>
                </ul>

              </div>
            </div>
          </div>


          <columnsShowHide :columns="columns" :selectedColumns="showColumns"
                           @updateShowColumns="pushShowColumns"/>

          <div class="row">
            <div class="col-md-12 mx-auto">
              <splide :options="options" :slides="items">
                <splide-slide v-for="item in items" :key="item.label"
                              :class="activeItem.id === item.id && activeItem.lapNo === item.lapNo ? 'isActive' : 'splide-item-click'"
                              @click="filterResult(item.type, item.id, item.lapNo)">
                  <i :class="item.icon"></i> {{ item.label }}

                  <i class="fa fa-download float-right" aria-hidden="true" v-if="form && form.length"
                     @click="downloadExcelFileHandler(item.type, item.id)"></i>

                </splide-slide>
              </splide>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" v-show="!showTeamsData">
    <div class="table-wrap">
      <div class="table-responsive individual-result result-edit-admin">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Actions</th>
            <th scope="col" v-for="column in columns" :key="column.name">
              <span v-html="column.label"></span>
            </th>
          </tr>
          </thead>
          <page-loader v-if="loading"/>
          <tbody>

          <tr v-if="!form.length">
            <td colspan="50" style="text-align: center">
              <strong>Sorry, there are no any players yet. please try reloading the players.</strong>
            </td>
          </tr>

          <template v-if="form.length">
            <tr v-for="(player, k) in form" :key="k"
                :class="{ 'removedPlayer': player.isRemoved === 'true', 'odd': k % 2 === 0, 'even': k % 2 !== 0 }">

              <td>
                <div class="btn-group" role="group" aria-label="Actions">
                  <button type="button" class="btn btn-secondary btn-sm"
                          @click="updatePlayerHandler(player)">Update
                  </button>
                  <button type="button" class="btn btn-warning btn-sm"
                          @click="downloadActivityFileHandler(player.userId)">
                    Download
                  </button>
                  <button type="button" class="btn btn-danger btn-sm"
                          @click="removePlayerHandler(player)"
                          v-if="!player.deleted_at">Remove
                  </button>
                  <button type="button" class="btn btn-success btn-sm"
                          @click="removePlayerHandler(player, 'restore')" v-else>Restore
                  </button>
                  
                  <button type="button" class="btn btn-info btn-sm"
                        @click="showPvpInfo(player)" 
                          v-if="(player.type === 'event' || player.type === 'day') && player.selectionStatus === 'ANL'">PVP
                  </button>
                </div>
              </td>

              <td v-for="column in columns" :key="column.name">

                <template v-if="column.type === 'non-editable'">
                  <template v-if="column.name === 'rank'">
                    <template v-if="!player.deleted_at">
                      <template
                          v-if="player.selectionStatus === 'HYPHEN' || player.selectionStatus === null">
                        {{ k + 1 }}
                      </template>
                      <template v-else>{{ player.selectionStatus }}</template>
                    </template>
                    <template v-else>REM</template>
                  </template>

                  <template v-else-if="column.name == 'categoryId'">
                    {{ mapCategory(player[column.name] || 0) }}
                  </template>

                  <template v-else>{{ player[column.name] }}</template>
                </template>

                <template v-if="column.name === 'jerseyId'">
                  <select id="jerseyId" name="jerseyId" class="form-control width-auto"
                          v-bind:value="form[k][column.name]"
                          v-on:input="form[k][column.name] = $event.target.value"
                          v-on:change="updatedJerseyImageIcon(player, $event.target.value)"
                  >
                    <option :value="jersey.Id" v-for="(jersey, i) in jerseys" :key="i">
                      {{ `${jersey.Name} - [ID# ${jersey.Id}]` }}
                    </option>
                  </select>
                </template>

                <template v-if="column.name === 'jerseyName'">
                  <span v-html="mapJerseyImage(player.jerseyImage)"></span>
                  <!-- {{ player.jerseyImage }} -->
                </template>

                <template v-if="column.type === 'text'">
                  <finished-time-format v-if="column.name === 'finishedTime'"
                                        v-bind:value="form[k][column.name]"
                                        v-on:input="form[k][column.name] = $event.target.value"
                                        :eventTime="event.datetime_epoc"/>
                  <template v-else-if="column.name === 'points' && activeItem.type === 'individual'">
                    {{ player[column.name] }}
                  </template>


                  <input class="form-control border" type="text" v-else
                         v-bind:value="form[k][column.name]"
                         v-on:input="form[k][column.name] = $event.target.value"/>
                </template>

                <template v-if="column.name === 'teamId'">
                  <select id="teamId" name="teamId" class="form-control width-auto"
                          v-bind:value="form[k][column.name]"
                          v-on:input="form[k][column.name] = $event.target.value">
                    <option value=''> Individual</option>
                    <option :value="team.TeamId" v-for="(team, i) in currentEventTeams" :key="i">
                      {{ team.Name }}
                    </option>
                  </select>
                </template>


                <template v-if="column.name === 'userCountryFlag'">
                  <select id="country" name="country" class="form-control width-auto"
                          v-bind:value="form[k][column.name]"
                          v-on:input="form[k][column.name] = $event.target.value">
                    <option :value="country.id" v-for="(country, i) in countries" :key="i">{{
                      country.name
                    }}
                    </option>
                  </select>
                </template>


                <template v-if="column.name === 'selectionStatus'">
                  <select id="selectionStatus" name="selectionStatus"
                          class="form-control width-auto" v-bind:value="form[k][column.name]"
                          v-on:input="form[k][column.name] = $event.target.value">
                    <option :value="status.name" v-for="(status, i) in selectionStatuses"
                            :key="i">
                      {{ status.label }}
                    </option>
                  </select>
                </template>

                <template v-if="column.type === 'checkbox'">
                  <input type="checkbox" v-bind:value="form[k][column.name]"
                         v-on:input="form[k][column.name] = $event.target.value"/>
                </template>
              </td>

            </tr>

            <template v-if="isEleminationRaceBoolean"> 
                <tr class="text-left" v-for="(row, index) in new Array(totalEventPlayers - (form.length || 0))" :key="index">
                  <td scope="col" width="20px" v-for="(item, indx) in new Array(columns.length + 1) " :key="indx">
                    <span :class="index === 0 && 'active'" v-if="indx == 0">{{ (form.length + index) + 1 + " (Not Crossed)" }}</span>
                    <p v-else>{{ '-' }}</p>
                  </td>
                </tr>
          </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <TeamResultEditPanel v-if="showTeamsData" :showColumns="showColumns" :eventId="event.serverEventId"
                       :datetime="event.datetime_epoc" :stage="currentDayId" :teamResult="teamResult"
                       :isSeriesEvent="isSeriesEvent"
                       :isStages="isStages"/>


  <VueFinalModal v-model="showModal" classes="modal-container" content-class="modal-content" :clickToClose="false">
    <button class="modal__close btn btn-warning btn-sm" @click="showModal = false">
      <mdi-close> X</mdi-close>
    </button>
    <span class="modal__title">User Ride File's</span>
    <div class="modal__content">
      <table id="files-table">

        <thead>
        <tr>
          <th scope="col">Type</th>
          <th scope="col">Uploaded At</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in userFitFiles" :key="item._id">
          <td data-label="Ride">{{ item.type }}</td>
          <td data-label="uploaded date">{{ new Date(item.created_at).toLocaleString() }}</td>
          <td data-label="Action">
            <a :value="item.url" class="btn btn-warning btn-sm download-action"
               @click="downloadFitFile(item._id)">
              Download
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </VueFinalModal>

  <VueFinalModal v-model="showPvpModal" classes="modal-container" content-class="modal-content" :clickToClose="false">
    <button class="modal__close btn btn-warning btn-sm" @click="showPvpModal = false">
      <mdi-close> X</mdi-close>
    </button>
    <span class="modal__title">Performance Verification Program</span>
    <div class="modal__content">
        - Categories
        <hr />
        <section class="col-lg-12 col-md-12">
            <div class="form-group">
                {{ this.pvpCategory }}
            </div>
        </section>
        <hr />
        <br>
        - Comments
        <hr />
        <section class="col-lg-12 col-md-12">
            <div class="form-group">
                {{ this.pvpComment }}
            </div>
        </section>
        <hr />
    </div>
  </VueFinalModal>
</template>


<style scoped>
#files-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  /* margin: 50px; */
  /* padding: 50; */
  width: 100%;
  table-layout: fixed;
}

#files-table #table-title {
  font-size: 1.5em;
  margin: .5em 0 .75em;
  text-align: center;
  padding: 20px;


}

#files-table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

#files-table th,
#files-table td {
  padding: .625em;
  text-align: center;
}

#files-table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  #files-table {
    border: 0;
  }

  #files-table #table-title {
    font-size: 1.3em;
    text-align: center;
    padding: 20px
  }

  #files-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #files-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  #files-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  #files-table td::before {

    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  #files-table td:last-child {
    border-bottom: 0;
  }
}
</style>


<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  /* max-height: 50%; */
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;

  font-weight: 700;
  text-align: center;
  text-decoration: underline;

  color: #fcb913;
  font-size: 30px;

  text-transform: uppercase;
  font-family: 'Barlow Condensed', sans-serif !important;

}

.modal__content {
  /* flex-grow: 1; */
  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>

<script>
import $ from 'jquery';
import 'vue3-carousel/dist/carousel.css';
import 'sweetalert2/src/sweetalert2.scss';
import config from '@/config/app.config.ts';
import { mapActions, mapGetters } from "vuex";
import { VueFinalModal } from 'vue-final-modal';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import countries from "@/shared/store/json/country.flags.json";
import "@/theme/style/splide-default.min.css";

import columnsShowHide from '@/shared/events/columnsShowHide.vue';
// import eventCategories from '@/theme/local-db/event-categories.json'
import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import FinishedTimeFormat from "@/shared/components/FinishedTimeFormat";
import TeamResultEditPanel from '@/shared/teams/TeamResultEditPanel.vue';
import { columns, selectionStatuses, teamColumns } from '@/shared/events/columns.dto.ts';
import {
  IsSeriesPointAndTimeBasedEvents,
  IsSinglePointAndTimeBasedEvents,
  IsSinglePointBasedEvents,
  jerseyImageUrl,
  mapJerseyImage,
  isUCIEvent,
  isChampionShipEvent,
  isEleminationRace,
  isHandiCapEvent,
} from "../../../../shared/events/event.helpers.ts";
import { formatTime, mapCategory, mapCountryFlag, mapJersey } from '@/shared/events/event.helpers.ts';
// import VueFinalModal from '../../../../shared/components/FitFilesModal.vue';
import Swal from 'sweetalert2'
import sweetAlert from "@/services/alert.ts";

export default {
  name: 'EventResult2',

  components: {
    FinishedTimeFormat,
    AdminNavigation,
    Splide,
    SplideSlide,
    columnsShowHide,
    TeamResultEditPanel,
    // VueFinalModal,
    VueFinalModal,
  },
  computed: {
    ...mapGetters('player', ['players']),
    ...mapGetters('event', ['gateList']),
    isOverallPointsGate() {
      return IsSinglePointBasedEvents(this.event) && this.overAllGates ? this.overAllGates[0] : null;
    },

    isEleminationRaceBoolean() {
      return isEleminationRace(this.event)
    }

    // getCategories() {
    //   return [
    //     ...new Map(
    //       this.resultBackup.map((item) => [item["categoryId"], item])
    //     ).values(),
    //   ].sort((x, y) => x.categoryId - y.categoryId);
    // },
  },
  data() {
    return {
      currentEventTeams: [],
      eventCategories: [],
      jerseyImageUrl: jerseyImageUrl,
      mapJerseyImage: mapJerseyImage,
      pvpCategory: "",
      pvpComment: "",
      showModal: false,
      showPvpModal: false,
      mapJersey: mapJersey,
      mapCategory: mapCategory,
      mapCountryFlag: mapCountryFlag,
      formatTime: formatTime,
      columns: columns,
      selectionStatuses: selectionStatuses,
      loading: false,
      refresh: 0,
      countries: countries,
      showColumns: [],
      eventId: this.$route.params.eventId,
      showTeamsData: false,
      form: [],
      activeItemDefault: { id: "individual", order: 0, type: "individual", lapNo: 1, gateType: 0 },
      activeItem: {},
      items: [],
      filterItems: [
        {
          id: "individual", order: 0, label: 'INDIVIDUAL', type: "individual", icon: 'fas fa-user',
          lapNo: 1,
          gateType: 0
        },
        // { id: "points", label: 'POINTS', type: "points", icon: 'fas fa-user' },
        // {id: "teamPoints", label: 'POINTS', type: "teamPoints", icon: 'fas fa-users'},
      ],
      teamsLeaderboards: [
        {
          id: "teams", order: 1, label: 'TEAMS', type: "teams", icon: 'fas fa-users',
          lapNo: 1,
          gateType: 0
        },
      ],
      ageLeaderboards: [
        {
          id: "master", order: 3, label: "Master Winner", type: "master", icon: "fas fa-trophy", lapNo: 1,
          gateType: 0
        },
        {
          id: "young", order: 4, label: "Youth Winner", type: "young", icon: "fas fa-user-circle", lapNo: 1,
          gateType: 0
        }
      ],
      teamResult: [],
      options: {
        rewind: false,
        gap: 0,
        perPage: 3,
        start: 0,
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2,
          },
          1000: {
            perPage: 1,
          }
        }
      },
      overAllGates: [],
      currentStageGates: [],
      currentStage: 0,
      currentDayId: "",
      stages: [],
      jerseys: [],
      isStages: false,
      isSeriesEvent: false,
      userFitFiles: [],
      playersCount: null,
      playerTeamName: [],
      teamIdSet: [],
      resultBackup: [],
      selectedColumnsCopy: columns,
      event: null,
      totalEventPlayers: 0
    };
  },
  async mounted() {
    this.event = await this.getEvent(this.eventId)
    this.filterItems[0].label = isUCIEvent(this.event) ? 'OVERALL' : this.filterItems[0].label
    this.items = this.filterItems;

    this.eventCategories = this.event?.categories || []

    // await this.getEvent(this.eventId);
    // is points based event
    if (!IsSinglePointBasedEvents(this.event)) {
      this.filterItems = [...this.filterItems, ...this.teamsLeaderboards];
    }
    await this.getGateList({ eventId: this.eventId });
    await this.addGates(this.gateList);
    await this.filterResult(this.isOverallPointsGate?.type || "individual", this.isOverallPointsGate?.id || "individual");
  },
  // watch: {
  //   players: function (val) {
  //     //   // this.form = val.players;
  //     //   this.jerseys = val.jerseys;
  //     this.showColumns = val?.columns ? Object.values(val.columns) : [];
  //   }
  // }, // push tickets in qa
  methods: {

    ...mapActions('event', ['getGateList', 'getEvent', 'publishEventResult', 'getEventResult']),
    ...mapActions('player', ['getPlayerTeamMetaData', 'getPlayersList', 'reloadPlayers', 'updatePlayer', 'removePlayer', 'fetchPlayerFitFiles', 'downloadPlayerFitFile']),


    updatedJerseyImageIcon(formRow, updatedJerseyValue) {  //live change icon upon jersey dropdown change
      this.form.map((item, i) => {
        if (item.id == formRow.id) {
          this.form[i].jerseyImage = this.jerseys.find(e => e.Id == updatedJerseyValue)?.Image
        }
      })
    },

    getDate(dateTime) {
      const dt = new Date(dateTime.replace(" ", "T"));
      return dt.toDateString();
    },

    getStageDate() {
      return new Date(
        this.stages[this.currentStage]?.StartTime * 1000
      ).toDateString();
    },

    async setActiveFilter() {
      this.options.start = this.activeItem?.order || 0;
    },

    async applyFilters(filters = {}, clear = false) {
      this.activeFilters = !clear ? { ...this.activeFilters, ...filters } : {};
      await this.filterResult(this.activeItem.type, this.activeItem.id, this.activeItem.lapNo);
    },


    gateMap(e, type, order) {
      const filterOrder = this.filterIdex(type === 'overallgate') + order;
      return { id: e.GateId, order: filterOrder, label: e.GateName, type: type, icon: "", gateType: e.GateType };
    },


    async setFilters() {
      await this.setCurrentStageGates();
      this.items = [
        ...this.filterItems,
        // ...(this.isSeriesEvent && this.isStages ? [] : this.overAllGates),
        ...(IsSeriesPointAndTimeBasedEvents(this.event) && this.isSeriesEvent && !this.isStages && isChampionShipEvent(this.event.serverEventId) ? this.ageLeaderboards : []),
        ...(this.isSeriesEvent && this.isStages ? (IsSeriesPointAndTimeBasedEvents(this.event) ? this.overAllGates : []) : (!this.isSeriesEvent ? [] : this.overAllGates)),
        ...((IsSinglePointAndTimeBasedEvents(this.event) || IsSinglePointBasedEvents(this.event)) && !this.isSeriesEvent ? this.overAllGates : []),
        ...(this.isStages ? this.currentStageGates : [])
      ];

      //put the overall winner leaderboard first
      if (IsSinglePointBasedEvents(this.event)) {
        this.items = this.items.sort((a, b) => (b.type === 'overallgate') - (a.type === 'overallgate'));
      }

      if (this.isEleminationRaceBoolean) {
        this.items = this.items.filter(item => item.id != "teams")
      }

      this.currentDayId = this.stages[this.currentStage]?.DayId || "";
    },

    async setCurrentStageGates() {
      const duplicateGates = []
      if (this.stages[this.currentStage].ListOfGates.length) {
        this.stages[this.currentStage].ListOfGates.map((gateItem,) => {
          gateItem = { ...gateItem, NoOfIterations: gateItem.NoOfIterations || 1 } //if any gate doesnt have NoOfIterations it will set it as 1
          for (let i = 1; i <= gateItem.NoOfIterations; i++) {
            duplicateGates.push({
              id: gateItem.GateId,
              order: gateItem.GateOrder,
              label: gateItem.GateName + (i != 1 ? " - Lap " + i : ""),
              type: 'gate',
              icon: "",
              lapNo: i,
              gateType: gateItem.GateType || 0,
            })
          }
        })
      }

      if (this.isEleminationRaceBoolean) { //computed property
        for (let i = 1; i <= this.stages[this.currentStage].NoOfLaps; i++) {
          this.filterItems.push({
            id: "daylap",
            order: 0,
            label: "Lap - " + i,
            type: "daylap",
            icon: "",
            lapNo: i,
          })
        }
      }

      // generate day laps
      // const dayLaps = []
      if (isUCIEvent(this.event) && !this.stages[this.currentStage].ListOfGates.length) {
        for (let i = 1; i <= this.stages[this.currentStage].NoOfLaps; i++) {
          duplicateGates.push({
            id: "daylap",
            order: 0,
            label: "Lap - " + i,
            type: "daylap",
            icon: "",
            lapNo: i,
          });
        }
      }

      this.currentStageGates = (duplicateGates.length ? duplicateGates : []).sort((a, b) => a.order - b.order);
    },

    filterIdex(og = false) {
      return og ? this.filterItems.length : this.filterItems.length + this.totalOverGates;
    },

    async updateStage(e) {
      let dataUpdate = false;
      if (e === 'prev' || e === 'next') {
        if (e === 'prev' && parseInt(this.currentStage) !== 0) {
          this.currentStage = parseInt(this.currentStage) - 1
          dataUpdate = true;
        }

        if (e === 'next' && parseInt(this.currentStage) < (this.stages.length - 1)) {
          this.currentStage = parseInt(this.currentStage) + 1
          dataUpdate = true;
        }
      } else if (e.target.value) {
        this.currentStage = e.target.value;
        dataUpdate = true;
      }

      if (dataUpdate === true) {
        this.isStages = true;

        await this.setFilters();
        await this.filterResult(this.isOverallPointsGate?.type || "individual", this.isOverallPointsGate?.id || "individual");


        this.refresh++;
      }
    },

    async toggleStages(isTrue) {
      this.isStages = isTrue;
      await this.setFilters();
      await this.filterResult(this.isOverallPointsGate?.type || "individual", this.isOverallPointsGate?.id || "individual");

    },

    async onFilterArrow(splide) {
      if (this.isArrowFilter && splide._o.perPage === 1) {
        const filter = this.items[splide._i] || {};
        if (filter.type) {
          await this.filterResult(filter.type, filter.id);
        }
      }
      this.isArrowFilter = true;
    },

    columnNotHidden(column) {
      return this.showColumns.includes(column);
    },

    pushShowColumns(event) {
      const column = event.target.value;
      const index = this.showColumns.findIndex(x => x === column);
      if (index !== -1) {
        this.updateColumnToDb(column, 'remove', () => {
          this.showColumns.splice(index, 1);
        })
        return;
      }
      this.updateColumnToDb(column, 'add', () => {
        this.showColumns.push(column);
      })
    },

    async updateColumnToDb(columnName, type = 'add', callback) {
      await this.$store.dispatch('event/updateShowColumns', {
        eventId: this.eventId,
        columnName: columnName,
        'type': type
      }).then(() => callback());
    },

    async addGates(gates) {
      this.stages = gates.days;
      this.isSeriesEvent = this.stages.length > 1;
      this.isStages = this.stages.length === 1;
      const _overallgates = gates.overallGates.filter(e => e.Overall === true);
      this.totalOverGates = _overallgates.length;
      this.overAllGates = _overallgates.sort((a, b) => a.GateOrder - b.GateOrder).map((e, orderIndex) => this.gateMap(e, 'overallgate', orderIndex));
      await this.setFilters();
      await this.setActiveFilter();
    },

    async filterResult(filterType, key, lapNumber) {
      $('.individual-result tbody td').addClass('blurry');
      this.loading = true;
      this.showTeamsData = false;
      this.activeItem = this.items.find(i => i.type === filterType && i.id === key && i.lapNo == lapNumber) || this.activeItemDefault;
      let result = [];
      if (filterType === "teams") {
        this.showTeamsData = true;
        this.columns = teamColumns;
        if (this.isStages) {
          await this.$store.dispatch('event/getTeamResults', {
            eventId: this.event.serverEventId,
            dayId: this.currentDayId,
            isLive: this.isLive,
            isStages: this.isStages ? true : false,
            isSeriesEvent: this.isSeriesEvent ? true : false,
            categoryId: this.activeFilters?.categoryId || null,
          }).then((list) => {
            this.teamResult = list.data;

          });
        } else {
          await this.$store.dispatch('event/getOverallTeamResults', {
            eventId: this.event.serverEventId,
            isLive: this.isLive,
            isStages: this.isStages ? true : false,
            isSeriesEvent: this.isSeriesEvent ? true : false,
            categoryId: this.activeFilters?.categoryId || null,
          }).then((list) => {
            this.teamResult = list.data;
          });
        }
      } else {
        this.columns = columns;
        const filters = {
          eventId: this.eventId,
          filterType,
          key,
          isStages: this.isStages ? 1 : 0,
          isSeriesEvent: this.isSeriesEvent ? 1 : 0,
          currentDayId: this.currentDayId,
          isLive: this.isLive == 1 ? 1 : 0,
          eventDatetimeEpoc: this.event?.datetime_epoc || null,
          lapNo: this.activeItem.lapNo || 1,
          categoryId: this.activeFilters?.categoryId || null,
          gateType: this.activeItem.gateType || 0,
          isAdminRequest: true,
          EventFormatType: this.event.EventFormatType,
          isUCI: isUCIEvent(this.event),
          isEleminationRace: this.isEleminationRaceBoolean || false
        };

        const resultData = await this.getEventResult(filters);
        result = resultData ? resultData?.data?.resultData : [];
        this.jerseys = resultData ? resultData?.data?.jerseys : []
        this.totalEventPlayers = resultData?.data?.totalPlayers || 0
        this.currentEventTeams = resultData?.data?.listOfTeams || []
      }

      if (result) {
        if ((this.isSeriesEvent && IsSeriesPointAndTimeBasedEvents(this.event) && this.activeItem.type === 'overallgate') ||
          (this.isSeriesEvent && isUCIEvent(this.event) && this.activeItem.type === "individual") ||
          (!this.isSeriesEvent && IsSinglePointAndTimeBasedEvents(this.event) && this.activeItem.type === 'overallgate') ||
          (!this.isSeriesEvent && IsSinglePointBasedEvents(this.event) && this.activeItem.type === 'overallgate')) {
          result = result.sort((a, b) => {
            if (a.points > b.points) {
              return -1;
            } else if (a.points < b.points) {
              return 1;
            }

            if (a.finishedTimeOverall < b.finishedTimeOverall) {
              return -1;
            } else if (a.finishedTimeOverall > b.finishedTimeOverall) {
              return 1;
            }
            return 0
          });
        } else {
          if (isHandiCapEvent(this.event)) { //sort based on end time in handicap events
            result = result.sort((a, b) => a.endAt - b.endAt)
          } else {
            result = result.sort((a, b) => a.finishedTime - b.finishedTime);
          }
        }

        const playerStatuses = ['DNF', 'DSQ', 'DNS', 'ANL', 'TIE', 'SFT'];
        const qItems = result.filter((x) => !playerStatuses.includes(x.selectionStatus) && x.deleted_at === null);
        const dqItems = result.filter((x) => playerStatuses.includes(x.selectionStatus) && x.deleted_at === null);
        const removedItems = result.filter((x) => x.deleted_at !== null);
        result = [...qItems, ...dqItems, ...removedItems];
      }

      this.setActiveFilter();
      setTimeout(function () {
        $('.individual-result tbody td').removeClass("blurry");
      }, 600);

      this.loading = false;

      this.form = result || [];
      this.playersCount = (result || []).length
      this.resultBackup = result || [];
    },

    async updatePlayerHandler(player) {
      this.loading = true;
      let formData = this.form.find((x) => x.userId === player.userId)
      formData['selectionStatus'] = formData['selectionStatus'] == null ? "HYPHEN" : formData['selectionStatus']
      // eslint-disable-next-line no-unused-vars
      const { jerseyImage, ...updatedFormData } = formData
      const res = await this.updatePlayer(updatedFormData);
      res?.error ? false : window.location.reload()
    },

    async removePlayerHandler(player, type = 'remove') {
      this.loading = true;
      // const formData = this.form.find((x) => x.userId === player.userId);
      let formData = { rowId: player.id, eventId: player.eventId, type: type }
      const { error } = await this.removePlayer(formData);
      error ? false : window.location.reload();
    },

    async downloadExcelFileHandler(filterType, key) {
      try {
        const filters = {
          eventId: this.eventId,
          filterType,
          key,
          isStages: this.isStages ? 1 : 0,
          isSeriesEvent: this.isSeriesEvent ? 1 : 0,
          currentDayId: this.currentDayId,
          isLive: this.isLive == 1 ? 1 : 0,
          eventDatetimeEpoc: this.event?.datetime_epoc || null,
          lapNo: this.activeItem.lapNo || 1,
          categoryId: this.activeFilters?.categoryId || null,
          gateType: this.activeItem.gateType || 0,
          isAdminRequest: true,
          EventFormatType: this.event.EventFormatType,
          isUCI: isUCIEvent(this.event),
        };
        const queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
        const url = `${config.API_URL}/player/download?${queryString}`;
        const res = await this.$store.dispatch('player/excelDownload', filters);
        (res.error) ? false : window.open(url)
      } catch (e) {
        global.app.$toast.error(e?.message ? e.message : "Error Occured")
      }
    },

    async downloadActivityFileHandler(playerID) {
      try {
        $('.individual-result tbody td').addClass('blurry');
        this.loading = true
        const result = await this.$store.dispatch('player/fetchPlayerFitFiles', { userId: playerID });
        this.userFitFiles = result?.data || [];
        this.userFitFiles.length ? this.showModal = true : this.showModal = false
        setTimeout(function () {
          $('.individual-result tbody td').removeClass("blurry");
        }, 600);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        global.app.$toast.error(e.message ? e.message : "Something went wrong please contact admin.");
      }
    },

    async downloadFitFile(fileId) {
      try {
        const res = await this.$store.dispatch('player/downloadPlayerFitFile', { fileId: fileId });
        if (typeof res == "string") {
          global.app.$toast.success("File Downloaded Successfully")
          window.location.href = res;
        }
        this.showModal = false
      } catch (e) {
        global.app.$toast.error(e.message ? e.message : "Something went wrong please contact admin.");
      }
    },

    async showPvpInfo(player) {

      const res = await this.$store.dispatch("event/getEventPvpData", {
        eventId: player.eventId, dayId: player.dayId, userId: player.userId
      });
      if (res) {
        this.showPvpModal = true;
        this.pvpCategory = res.data.failureCategory;
        this.pvpComment = res.data.reviewerComments;
      }

      //global.app.$toast.error("No PVP data found!.")
    },
    //reload current selected stage only
    async reloadPlayersHandler(isOverall = false) {
      Swal.fire({
        title: isOverall ? 'Enter password to perform this action' : `<span style="color: #F1AA00" > Selected Stage For Reload </span> <br> <span>${this.stages[this.currentStage]?.DayName + "-" + this.stages[this.currentStage]?.StartDate}  </span> </strong>`,
        // title: 'Confirm Reload Data For: ' + this.stages[this.currentStage]?.DayName,
        input: 'password',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        inputValidator: async (value) => {
          if (!value) {
            return 'Please enter password'
          }
          const res = await this.reloadPlayers({
            eventId: this.eventId,
            currentDayId: this.currentDayId,
            password: value,
            isSeriesEvent: this.isSeriesEvent,
            isStages: this.isStages,
          });
          if (res.error) {
            return res.message;
          } else if (!res.error) {
            await this.filterResult(this.isOverallPointsGate?.type || "individual", this.isOverallPointsGate?.id || "individual");

          }
        },
        preDeny: (response) => {
          if (response.error) {
            return false; // Prevent confirmed
          } else {
            return true;
          }
        },

      }).then((response) => {
        if (!response.isDenied && response.isConfirmed) {
          // this.$router.push();
          window.location.reload()
        }
      });
    },


    //publish current selected stage only
    publishEventResultHandler(status) {
      sweetAlert.confirm('Are you sure?',
        `You would like to mark this stage as ${status}`,
        'Yes',
        'No',
        null,
        async () => {
          const data = await this.publishEventResult({
            eventId: this.eventId,
            dayId: this.currentDayId,
            result_status: status
          });
          data?.error ? false : this.$router.go(this.$router.currentRoute)
        });
    },

  }
};
</script>