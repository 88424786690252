<template>
  <!-- OLD YELLOW CIRCLE LOADER GIF -->
  <!-- <div class="loadingio-spinner-wrap">
    <img src="./../assets/loader.gif" width="250px" alt="" />
  </div> -->

  <!-- New Mywhoosh Logo Loader GIF -->
  <div class="loadingio-spinner-wrap">
    <img src="./../assets/new-loader.gif" width="250px" alt="" />
  </div>

</template>


