// @ts-ignore
import AdminLayout from "@/theme/layouts/AdminLayout.vue";
import {
  Events,
  Login,
  EventResult2,
  AddNewPlayer,
  ListAllUsers,
  AddNewAdminUser,
  RolesPermissions,
  AddNewRole,
  PushNotificationsPage,
  pointsMainPage,
  jerseysListPage
  // @ts-ignore
} from "@/views/admin/views/index.ts";
// @ts-ignore
import Service from "@/services/http.ts";


const auth = async (to, from, next) => {
  await Service(true)
    .post("auth/admin/check-auth", { token: localStorage.getItem("resultAdminToken") })
    .then((r) => {
      if (r.code !== 401) {
        next();
        return
      }
    }).catch(e => {
      next({ name: "AdminLogin" });
      return
    });
};


const basePath = "/result-admin/";
const protectedRoutes = [
  {
    path: basePath,
    component: AdminLayout,
    meta: { requiresAuth: false },
    beforeEnter: auth,
    children: [

      {
        path: "events",
        name: "AdminEventResult",
        component: Events,
      },
      {
        path: "event-result/:eventId",
        name: "AdminResultEditPanelv2",
        component: EventResult2,
      },
      {
        path: "new-player",
        name: "AdminAddNewPlayer",
        component: AddNewPlayer,
      },
      {
        path: "admin-users",
        name: "ListAllAdminUsers",
        component: ListAllUsers,
      },

      {
        path: "add-new-admin-user",
        name: "AddNewAdminUser",
        component: AddNewAdminUser,
      },

      {
        path: "roles-permissions",
        name: "RolesAndPermissions",
        component: RolesPermissions,
      },

      {
        path: "add-new-role",
        name: "AddNewRole",
        component: AddNewRole,
      },
      {
        path: 'push-notifications',
        name: 'PushNotificationsPage',
        component: PushNotificationsPage
      },

      {
        path: 'event-points/:eventId',
        name: 'pointsMainPage',
        component: pointsMainPage
      },

      {
        path: "event-jerseys/:eventId",
        name: "jerseysListPage",
        component: jerseysListPage,
        props: true
      },
    ],
  },
];

const routes = [
  {
    path: basePath,
    component: AdminLayout,
    redirect: { name: "AdminLogin" },
    meta: { requiresAuth: false },

    children: [
      {
        path: "login",
        name: "AdminLogin",
        component: Login,
      },
    ],
  },
  ...protectedRoutes,
];

export default routes;
