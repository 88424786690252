<template>
    <router-view></router-view>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins&display=swap");

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,
.navbar-light .navbar-nav .nav-link, button, input, optgroup, select, textarea {
  font-family: 'Barlow Condensed', sans-serif !important;
}
h1.sectionHeadingMain{
   font-family: "ThunderExtraBold" !important;
}
body, body p, body div, p, .footer p {
  font-family: 'Poppins', sans-serif !important;
}
</style>

<script>
export default {
  name: 'AppLayout'
}
</script>
