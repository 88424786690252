<template>
    <page-heading>
      Event Reward Jersey's
    </page-heading>
    <div class="container btn-wrap">
      <div class="row">
        <div class="col-md-12 text-center">
           <h1>   {{ event?.name }}  </h1>
           <h3> {{ event?.datetime }}</h3>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="white-top-bg">
            <AdminNavigation v-if="loggedIn" />
          </div>
        </div>
      </div>
    </div>
 
   
    <div  class="container" id="accordionDiv">
     <div class="table-wrap">
      <div class="table-responsive individual-result result-edit-admin">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Actions</th>
            <th scope="col" v-for="column in columns" :key="column.name">
              <span v-html="column.label"></span>
            </th>
          </tr>
          </thead>

          <tbody>
            <page-loader v-if="loading"  style="margin-top: 20px"/>

          <tr v-if="!formData?.length">
            <td colspan="50" style="text-align: center">
              <strong> {{ loadingMsg }}</strong>
            </td>
          </tr>


           <template v-if="formData?.length">
            <tr v-for="(item, k) in  formData " :key="k">

              <td>
                <div class="btn-group" role="group" aria-label="Actions">
                  <button type="button" class="btn btn-secondary btn-sm"
                          @click="updateJerseyRecord(item)">Update
                  </button>
                  <button type="button" class="btn btn-danger btn-sm"
                          @click="removeJerseyRecord(item)"
                           >Remove
                  </button>
                </div>
              </td>

              <td v-for="column in  columns " :key="column.name">

                 <template v-if="column.name === 'JerseyId'">
                  <select id="JerseyId" name="JerseyId" class="form-control width-auto"
                          v-bind:value="item[column.name]"
                          v-on:input="item[column.name] = $event.target.value"
                          v-on:change="updatedJerseyImageIcon(item, $event.target.value)"
                          >
                    <option :value="jersey.Id" v-for="(jersey, i) in  allJerseys " :key="i" :selected="jersey.Id == item[column.name]">
                      {{ `${jersey.Name} - ${jersey.Id}` }}
                    </option>
                  </select>

                 </template>

                <template v-else-if="column.name === 'UserId'">
                  <select id="UserId" name="UserId" class="form-control width-auto"
                          v-bind:value="item[column.name]"
                          v-on:input="item[column.name] = $event.target.value">
                    <option :value="user.userUuid" v-for="( user, i ) in  eventUsers " :key="i" :selected="user.userUuid == item[column.name]">
                      {{ `${user.firstName} ${user.lastName}` }}
                    </option>
                  </select>
                </template>

                <template v-else-if="column.name == 'jerseyName'">
                  <span v-html="mapJerseyImage(item['JerseyImage'])"></span>
                </template>


                <template v-else-if="column.name == 'CategoryId'">
                     <template v-if="item[column.name]">
                        Cat - {{ item[column.name] }} 
                        </template> 
                        <template v-else> Open
                            </template>                
                </template>

                <template v-else>
                  {{ item[column.name] }}          
                </template>
            
              </td>

            </tr>
          </template>  
          </tbody>
        </table>
      </div>
     
  </div>
 
       
    
    </div>
    
    <!-- <div v-if="formData.length" class="pagination justify-content-center">
      <pagination v-model="currentPage" :records="totalRecords" :per-page="limit" @paginate="loadJerseysList" />
    </div> -->
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import sweetAlert from "@/services/alert.ts";
import 'sweetalert2/src/sweetalert2.scss'
import { eventJerseysColumns } from '@/shared/events/columns.dto.ts';
import { mapJersey, mapJerseyImage } from "../../../../shared/events/event.helpers.ts";


export default {
  name: 'jerseysListPage',
  props: ['eventName', 'eventDate'],
  components: { AdminNavigation },
  data() {
    return {

      loading: false,
      mapJersey: mapJersey,
      mapJerseyImage: mapJerseyImage,
      searchText: '',
      formData: [],
      totalPage: 1,
      currentPage: 1,
      limit: 10,
      totalRecords: 0,
      roles: ['whoosh-super-admin', 'whoosh-admin'],
      columns: eventJerseysColumns,
      allJerseys: [],
      eventUsers: [],
      selectedDay: null,
      eventId: this.$route.params.eventId,
      event: {},
      loadingMsg: 'Loading Data.....'

    }
  },

  computed: {
    //   ...mapGetters('adminUserLists', ['getAdminUsers']),
    ...mapGetters('auth', ['loggedIn', 'user', 'event']),
  },

  async mounted() {
    const res = await this.getEvent(this.eventId)
    this.event = res?.data
    await this.getRewardJerseysList()
  },

  methods: {
    ...mapActions("event", ["getRewardJerseys", "getEvent", "deleteRewardJersey", "updateRewardJersey"]),


    updatedJerseyImageIcon(formRow, updatedJerseyValue) {  //live change icon upon jersey dropdown change
      this.formData.map((item, i) => {
        if (item._id == formRow._id) {
          this.formData[i].JerseyImage = this.allJerseys.find(e => e.Id == updatedJerseyValue)?.Image
        }
      })
    },

    async getRewardJerseysList() {
      this.loading = true;
      const filters = {
        eventId: this.eventId,
        page: this.currentPage
      }
      const response = await this.getRewardJerseys(filters);
      this.formData = response?.data?.jerseyData || []
      this.eventUsers = response?.data?.eventUsers || []
      this.allJerseys = response?.data?.allJerseys || []

      this.loadingMsg = 'Sorry, No records are found for this event result'

      this.getTotalPage(response.data.total)
      this.loading = false;
    },


    getTotalPage(total) {
      this.totalRecords = total;
      let page = (total >= this.limit ? total / this.limit : total > 0 ? 1 : 0).toString();
      let i = parseInt(page) % 1;
      this.totalPage = parseInt(page + "") + (i > 0 ? 1 : 0);
    },


    loadJerseysList(currentPage = 1) {
      this.currentPage = currentPage;
      this.getRewardJerseysList();
    },

    async removeJerseyRecord(payload) {
      this.loading = true
      sweetAlert.confirm('Are you sure?',
        `This Action will not be undo`,
        'Yes',
        'No',
        null,
        async () => {
          await this.deleteRewardJersey(payload)
          await this.getRewardJerseysList()
        });

    },

    async updateJerseyRecord(payload) {
      this.loading = true
      await this.updateRewardJersey(payload)
      await this.getRewardJerseysList()

    }


  },

};
</script>
    