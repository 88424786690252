import Service from "@/services/http.ts";

export default {
  namespaced: true,
  state: {
    userLists: [],
  },
  getters: {
    adminUserLists: ({ adminUserLists }) => adminUserLists,
  },

  actions: {
    async getAdminUsers({ commit }, payload) {
      try {

        return await Service()
          .get(`/admin/get/all-admin-users`, { params: payload })
          .then((res) => {
            commit("SET_ADMIN_USERS", res.data.usersArr);
            return res;
          });
      } catch (e) {
        commit("SET_ADMIN_USERS", []);
      }
    },


    async deleteAdminUser({ commit }, payload) {
      try {
        return await Service(true).post('/admin/delete/admin-user', payload).then((res) => {
          commit('SET_ADMIN_USERS', []);
          return res;
        });
      } catch (e) {
        commit('SET_ADMIN_USERS', []);
      }
    },

    async addNewAdminUser({ commit }, payload) {
      try {
        return await Service(true).post('/admin/create/new-admin-user', payload).then((res) => {
          commit('ADD_NEW_ADMIN_USER', res.data);
          return res;
        });
      } catch (e) {
        return e
      }
    },


    async udpateAdminUserPassword({ commit }, payload) {
      try {
        return await Service(true)
          .post("/admin/update/admin-user-password", payload)
          .then((res) => {
            commit("UPDATE_ADMIN_USER", res.data);
            return res;
          });
      } catch (e) {
        return e
      }
    },


  },

  mutations: {
    SET_ADMIN_USERS: (state, payload) => (state.userLists = payload),
  },
};
