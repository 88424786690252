<template>

  <page-heading v-if="isStages && isSeriesEvent">
    {{ event.name || "Event Name" }} | Stage {{ parseInt(currentStage) + 1 }} of {{ stages?.length }}
  </page-heading>
  <page-heading v-else>{{ event.name || "Event Name" }}</page-heading>

  <AdminNavigation
      @listenerChild="listenerChild"
      :eventId="eventId" :event="event"
      :filterResult="filterResult"
      :eventListPage="$route.params.eventListPage"/>

  <div class="container overflow-hidden">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="white-top-bg pb-0">
          <div class="row">
            <div class="col-md-12">
              <div class="tab tab-left weight-tab">


                <div class="stage-wrap" v-show="isSeriesEvent">
                  <button class="arrow-btn" @click="updateStage('prev')">
                    <img src='/assets/arrow-left.png'/>
                  </button>
                  <select @change="updateStage" v-model="currentStage">
                    <option :value="k" v-for="(stage, k) in stages" :key="k">{{ stage.DayName }}:
                      {{ stage.StartDate }}
                    </option>
                  </select>
                  <button class="arrow-btn" @click="updateStage('next')">
                    <img src='/assets/arrow-right.png'/>
                  </button>
                </div>

              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-8 mx-auto" v-if="totalGateTabs">
              <splide :options="options" :slides="items">
                <splide-slide v-for="item in items" :key="item.label"
                              :class="activeItem.id === item.id && activeItem.lapNo === item.lapNo ? 'isActive' : 'splide-item-click'"
                              @click="filterResult(item.type, item.id, item.lapNo)">
                  <i :class="item.icon"></i> {{ item.label }}
                </splide-slide>
              </splide>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="table-wrap">
      <div class="table-responsive individual-result result-edit-admin">
        <table class="table">
          <thead>
          <tr>
            <th scope="col" v-for="column in columns" :key="column.name">
              <span v-html="column.name"></span>
            </th>
          </tr>
          </thead>
          <page-loader v-if="loading"/>
          <tbody>
          <tr v-if="!form?.length">
            <td colspan="50" style="text-align: center">
              <strong>Sorry, there is no points set for this stage</strong>
            </td>
          </tr>
          <template v-if="form?.length">
            <tr v-for="(item, k) in form" :key="k"
                :class="{ 'removedPlayer': item.id === 'true', 'odd': k % 2 === 0, 'even': k % 2 !== 0 }">
              <td>
                {{ item.rank }}
              </td>
              <td>{{ item.points }}</td>
              <td>{{ item.lapNo }}</td>
              <td>{{ item.eventId }}</td>
              <td>{{ item.dayId }}</td>
              <td>{{ item.gateId || '-' }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!-- Add New Points Modal -->
  <AddPointsModal
      @listenerChild="listenerChild"
      @selectedDayDropdownListener="selectedDayDropdownListener"
      :event="event"
      :addPointsModal="addPointsModal"
      :stages="stages"
      :currentStageGates="currentStageGates"
      :totalGateTabs="totalGateTabs"
      :isUCIEvent="isUCIEvent"
  />
  <!-- End of Add new Points Popup -->


  <!-- Remove Points Modal -->
  <RemovePointsModal
      @listenerChild="listenerChild"
      @selectedDayDropdownListener="selectedDayDropdownListener"
      :event="event"
      :removePointsModal="removePointsModal"
      :stages="stages"
      :currentStageGates="currentStageGates"
      :totalGateTabs="totalGateTabs"
      :isUCIEvent="isUCIEvent"
  />
  <!-- End of Remove Points Modal -->

</template>

<style scoped>
.individual-result table tbody {
  text-transform: none
}
</style>

<script>
import $ from 'jquery';
import * as Yup from "yup";
import 'vue3-carousel/dist/carousel.css';
import 'sweetalert2/src/sweetalert2.scss'
import { mapActions, mapGetters } from "vuex";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@/theme/style/splide-default.min.css"; import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import { selectionStatuses, } from '@/shared/events/columns.dto.ts';
import AddPointsModal from '../../components/modals/AddPointsModal.vue'
import RemovePointsModal from '../../components/modals/RemovePointsModal.vue';
import { IsSeriesPointAndTimeBasedEvents, } from "@/shared/events/event.helpers.ts";
import { EventFormatType } from '@/shared/events/enums.ts';

export default {
  name: 'pointsMainPage',

  components: {
    AdminNavigation,
    Splide,
    SplideSlide,
    AddPointsModal,
    RemovePointsModal
  },
  computed: {
    ...mapGetters('event', ['gateList', 'event']),
  },
  data() {
    return {
      totalGateTabs: 0,
      addPointsModal: false,
      removePointsModal: false,
      columns: [
        {
          id: 1,
          name: 'Rank',
          type: 'text'
        },

        {
          id: 6,
          name: 'Points',
          type: 'text',

        },
        {
          id: 5,
          name: 'Lap No',
          type: 'text',

        },

        {
          id: 2,
          name: 'EventId',
          type: 'text',

        },
        {
          id: 3,
          name: 'Day Id',
          type: 'text',

        },

        {
          id: 4,
          name: 'Segment Id',
          type: 'text',

        }
      ],

      selectionStatuses: selectionStatuses,
      loading: false,
      refresh: 0,

      eventId: this.$route.params.eventId,
      showTeamsData: false,
      showTeamPointsForm: false,
      form: [],
      activeItem: { id: "1", index: 0, type: "gate", lapNo: 1 },
      items: [],
      filterItems: [
        // { id: "individual", order: 0, label: 'INDIVIDUAL', type: "individual", icon: 'fas fa-user' },
        // { id: "teams", order: 1, label: 'TEAMS', type: "teams", icon: 'fas fa-users' },
        // { id: "points", label: 'POINTS', type: "points", icon: 'fas fa-user' },
        // {id: "teamPoints", label: 'POINTS', type: "teamPoints", icon: 'fas fa-users'},
      ],


      options: {
        rewind: false,
        gap: 0,
        perPage: 3,
        start: 0,
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2,
          },
          1000: {
            perPage: 1,
          }
        }
      },
      overAllGates: [],
      currentStageGates: [],
      currentStage: 0,
      currentDayId: "",
      stages: [],

      isStages: true,
      isSeriesEvent: false,
      isUCIEvent: false

    };
  },

  setup() {
    const schema = Yup.object().shape({
      samePointsAcross: Yup.string().required("please choose option"),
      pointsArr: Yup.string().required("please enter points"),
    });

    return {
      schema,
    };
  },

  async mounted() {
    // this.items = this.filterItems;
    await this.getEvent(this.eventId);
    await this.getGateList({ eventId: this.eventId });
    await this.addGates(this.gateList);
    await this.filterResult("individual", "individual", 1);
    this.isUCIEvent = (this.event.EventFormatType == 'POINTS_BASED' && this.event.EventType === 8) ? true : false
  },

  methods: {
    ...mapActions('event', ['getEventPoints', 'getGateList', 'getEvent', 'publishEventResult']),
    async setActiveFilter() {
      this.options.start = this.activeItem?.order || 0;
    },

    listenerChild(childResponseObj) {
      childResponseObj.type == 'add-points' ? this.addPointsModal = childResponseObj.popup : this.removePointsModal = childResponseObj.popup
    },

    async selectedDayDropdownListener(value) {
      await this.setCurrentStageGates(value)
    },

    gateMap(e, type, order) {
      const filterOrder = this.filterIdex(type === 'overallgate') + order;
      return { id: e.GateId, order: filterOrder, label: e.GateName, type: type, icon: "" };
    },

    async setFilters() {
      await this.setCurrentStageGates(this.currentStage);
      this.items = [
        // ...this.filterItems,
        ...(this.isSeriesEvent && this.isStages ? (IsSeriesPointAndTimeBasedEvents(this.event) ? this.overAllGates : []) : (!this.isSeriesEvent ? [] : this.overAllGates)),
        ...(this.isStages ? this.currentStageGates : [])

      ];
      this.currentDayId = this.stages[this.currentStage]?.DayId || "";

      if (!this.currentStageGates?.length) {
        this.form = []
      }
    },

    async setCurrentStageGates(value) {
      this.currentStage = value
      const duplicateGates = []
      if (this.stages[this.currentStage]?.ListOfGates?.length) {
        if (this.event.EventFormatType == EventFormatType.POINTS_BASED && !this.isUCIEvent) {
          duplicateGates.push({
            id: "individual",
            order: 1,
            label: 'Individual',
            type: 'individual',
            icon: "",
            lapNo: 1
          })
        }
        this.stages[this.currentStage].ListOfGates.map((gate) => {
          const gates = { ...gate, NoOfIterations: gate.NoOfIterations || 1 } //if any gate doesnt have totalRepitition it will set it as 1
          for (let i = 1; i <= gates.NoOfIterations; i++) {
            duplicateGates.push({
              id: gate.GateId,
              order: gate.GateOrder,
              label: gate.GateName + (i != 1 ? " - Lap " + i : ""),
              type: 'gate',
              icon: "",
              lapNo: i
            })
          }
        })
      }



      if (this.isUCIEvent) {
        for (let i = 1; i <= this.stages[this.currentStage].NoOfLaps; i++) {
          duplicateGates.push({
            id: 'individual',
            order: 0,
            label: this.stages[this.currentStage].NoOfLaps > 1 ? ("Lap - " + i) : "Individual",
            type: 'individual',
            icon: "",
            lapNo: i
          })
        }
      }
      this.totalGateTabs = duplicateGates.length
      this.currentStageGates = (duplicateGates.length ? duplicateGates : []).sort((a, b) => a.order - b.order);
    },

    filterIdex(og = false) {
      return og ? this.filterItems?.length : this.filterItems?.length + this.totalOverGates;
    },



    async addGates(gates) {
      this.stages = gates.days;
      this.isSeriesEvent = this.stages?.length > 1;
      // this.isStages = this.stages.length === 1;
      const _overallgates = gates.overallGates.filter(e => e.Overall === true);
      this.totalOverGates = _overallgates?.length;
      this.overAllGates = _overallgates.sort((a, b) => a.GateOrder - b.GateOrder).map((e, orderIndex) => this.gateMap(e, 'overallgate', orderIndex));
      await this.setFilters();
      await this.setActiveFilter();
    },

    async filterResult(filterType, key, lapNumber) {

      $('.individual-result tbody td').addClass('blurry');
      this.loading = true;

      this.activeItem = this.items.find(i => i.type === filterType && i.id === key && i.lapNo == lapNumber) || this.activeItem;
      let result = [];

      const queryPayload = {
        eventId: this.eventId,
        dayId: this.currentDayId,
        gateId: key,
        type: this.activeItem.type,
        lapNo: lapNumber || 1,
        isUCIEvent: this.isUCIEvent
      };

      const getResponse = await this.getEventPoints(queryPayload);
      result = getResponse ? getResponse.data : []

      this.form = result;
      await this.setActiveFilter();
      setTimeout(function () {
        $('.individual-result tbody td').removeClass("blurry");
      }, 600);

      this.loading = false;
    },

    async updateStage(e) {
      let dataUpdate = false;

      if (e === "prev" || e === "next") {
        if (e === "prev" && parseInt(this.currentStage) !== 0) {
          this.currentStage = parseInt(this.currentStage) - 1;
          dataUpdate = true;
        }
        if (e === "next" && parseInt(this.currentStage) < this.stages?.length - 1) {
          this.currentStage = parseInt(this.currentStage) + 1;
          dataUpdate = true;
        }
      } else if (e.target.value) {
        this.currentStage = e.target.value;
        dataUpdate = true;
      }

      if (dataUpdate === true) {
        this.isStages = true;
        await this.setFilters();
        await this.filterResult("individual", "individual", 1);
        // await this.filterResult(this.isOverallPointsGate?.type || "individual", this.isOverallPointsGate?.id || "individual");
        this.refresh++;
      }
      this.reloadTable++;
    },

  }
};
</script>